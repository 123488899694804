import React from "react";
import { Link } from 'react-router-dom';
import { Button, Space, Input, List, Typography, Menu, Dropdown, Modal, Tabs, message } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AddCooperation from '../Components/AddCooperation/index'
import { prolist, addPro, upPro, delPro, findProbynamelist } from '@/request/deliverapi'
import { getCusts } from '@/request/customerapi'
import moment from 'moment';

const { Search } = Input
const { Title, Text } = Typography
const { confirm } = Modal
const { TabPane } = Tabs

/**
 * 列表组件
 */
class ListComp extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {}
  }
  render() {
    // 列表操作项配置
    const menu = (item: object) => {
      return (
        <Menu onClick={this.props.menuOptionClick.bind(this, item)}>
          <Menu.Item key="1" style={{ color: '#0099ff' }}>
            编辑
          </Menu.Item>
          <Menu.Item key="0" style={{ color: '#ff0000' }}>
            删除
          </Menu.Item>
        </Menu>
      )
    }
    // 列表加载更多配置
    const loadMore = this.props.listData.length === 0 ? null :
      (
        <div
          style={{
            textAlign: 'center',
            padding: '12px 0',
            boxSizing: 'content-box',
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={this.props.onLoadMore}>加载更多</Button>
        </div>
      )
    return (
      // 列表
      <>
        <List
          dataSource={this.props.listData}
          loading={this.props.initLoading}
          loadMore={loadMore}
          renderItem={(item: any) => {
            return (
              <List.Item>
                <List.Item.Meta
                  style={{ marginRight: 100 }}
                  title={<Text strong style={{ width: 'calc(100% - 24px)'}} ellipsis={true}>{item.projectname}</Text>}
                  description={<Text style={{ width: 'calc(100% - 24px)' }} ellipsis={true} type='secondary'>{item.synopsis}</Text>}
                />
                <List.Item.Meta
                  title='客户公司'
                  description={
                    <Text strong>{item.kehucompany}</Text>
                  }
                />
                <List.Item.Meta
                  title='项目启动日期'
                  description={
                    item.starttime ? 
                    <Text strong>{item.starttime}</Text>
                    : <Text type='secondary'>暂无</Text>
                  }
                />
                <List.Item.Meta
                  title='项目状态'
                  description={
                    <Space>
                      <span style={{ backgroundColor: item.projectstate === '正在研发' ? '#fa6400' : item.projectstate === '待交付' ? '#f7b500' : '#52c41a', position: 'relative', top: '-2px', width: '7px', height: '7px', borderRadius: '50%', display: 'inline-block' }} />
                      <Text type='secondary'>{item.projectstate}</Text>
                    </Space>
                  }
                />
                <List.Item.Meta
                  style={{ flex: .5 }}
                  description={
                    <Space size={20}>
                      <Link to={{ pathname: '/deliver/deliverDetails', state: { item } }}>详情</Link>
                      <Dropdown trigger={['click']} overlay={menu.bind(this, item)} placement='bottomCenter'>
                        <span style={{ color: '#1890ff', cursor: 'pointer' }}><EllipsisOutlined /></span>
                      </Dropdown>
                    </Space>
                  }
                />
              </List.Item>
            )
          }}
        />
      </>
    )
  }
}

/**
 * 主组件
 */
class DeliveList extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      addVisible: false,
      listData: [],
      plateIndex: 0,
      plate: '教育&校园',
      createtime: '',
      initcontract: '',
      initialValues: {},
      type: '',  // add 新增弹窗 edit 编辑弹窗
      projectid: '',
      projectstate: '正在研发',
      activeKey: '0',
      initLoading: true,
      loadMorePoint: 3,
      custsList: [],  // 客户列表
      pageSize: 9,
      currentPage: 1
    }
  }
  addFormRef: any = React.createRef()

  async componentDidMount() {
    // 初始化列表
    this.getProlist(0, 6)
    // // 获取客户列表
    // this.fnGetCusts('160675131818659840', this.state.pageSize, this.state.currentPage)
  }

  // 请求列表数据方法
  getProlist = (skip: number, limit: number) => {
    prolist({
      skip,
      limit,
      projectstate: this.state.projectstate
    }).then((res: any) => {
      console.log(res)
      this.setState({
        listData: res.data ? this.state.listData.concat(res.data) : [],
        total: res.total,
        initLoading: false
      })
    })
  }

  // 列表加载更多按钮
  onLoadMore = () => {
    if (this.state.total - this.state.loadMorePoint <= 3) {
      message.warning('没有更多了~')
    } else {
      this.setState({
        // 每次加3
        loadMorePoint: this.state.loadMorePoint + 3
      }, () => {
        // 每次加载3条数据
        if (this.state.searchValue) {
          // 查询
          this.fnFindProbynamelist(0, 6, '163159795485528064', this.state.searchValue)
        } else {
          // 展示所有
          this.getProlist(this.state.loadMorePoint, 3)
        }
      })
    }
  }

  // 列表标签页切换
  onTabsChange = (value: string) => {
    this.setState({
      activeKey: value,
      initLoading: true,
      searchValue: ''
    })
    switch (value) {
      case '0':
        this.setState({ projectstate: '正在研发', listData: [], loadMorePoint: 3 }, () => {
          this.getProlist(0, 6)
        })
        break
      case '1':
        this.setState({ projectstate: '待交付', listData: [], loadMorePoint: 3 }, () => {
          this.getProlist(0, 6)
        })
        break
      case '2':
        this.setState({ projectstate: '已交付', listData: [], loadMorePoint: 3 }, () => {
          this.getProlist(0, 6)
        })
        break;
    }
  }

  // 列表项的操作 => 删除和编辑
  menuOptionClick = async (item: any, key: any) => {
    let that = this
    // 获取项目id
    this.setState({
      projectid: item.projectid
    })
    if (key.key === "0") {
      // 删除
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: '是否要删除此项?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        centered: true,
        onOk() {
          confirm({
            icon: <ExclamationCircleOutlined />,
            content: '删除后不可恢复,是否继续?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            centered: true,
            onOk() {
              that.setState({
                initLoading: true,
                listData: [],
                loadMorePoint: 3
              }, () => {
                // 调用删除接口
                delPro({ projectid: item.projectid }).then(res => {
                  message.success('删除成功!');
                  that.getProlist(0, 6)
                })
              })
            }
          });
        }
      });
    } else if (key.key === '1') {
      // 编辑
      // 获取板块值并设置
      switch (item.projectfield) {
        case '教育&校园':
          this.setState({ plateIndex: 0 })
          break
        case '交通&物流':
          this.setState({ plateIndex: 1 })
          break
        case '政务&企业':
          this.setState({ plateIndex: 2 })
          break
        case '农医&其他':
          this.setState({ plateIndex: 3 })
          break
      }
      // 获取客户列表
      await this.fnGetCusts('160675131818659840', this.state.pageSize, this.state.currentPage)
      this.setState({
        plate: item.projectfield,
        createtime: item.createtime,
        initcontract: item.initcontract,
        type: 'edit',
        // 设置输入框内容
        initialValues: {
          name: item.projectname,
          manager: item.manager,
          startTime: moment(item.createtime, 'YYYY-MM-DD'),
          initial: moment(item.initcontract, 'YYYY-MM-DD'),
          company: item.kehucompany,
          no: item.projectnumber,
          brief: item.synopsis,
          remarks: item.projectremarks
        },
        addVisible: true
      })
    }
  }

  // 控制 新增或编辑 弹窗的确认按钮
  handleOnOk = () => {
    let that = this
    this.addFormRef.current.validateFields()
      // 通过验证
      .then((v: any) => {
        console.log(v)
        // 请求的数据
        const data: any = {
          projectfield: this.state.plate,
          projectname: v.name,
          manager: v.manager,
          createtime: this.state.createtime,
          initcontract: this.state.initcontract,
          kehucompany: v.company,
          kehuid: 'defgdfgsdf',  // 客户id 默认值
          projectnumber: v.no,
          projectremarks: v.remarks,
          synopsis: v.brief
        }
        // 新增确认
        if (this.state.type === 'add') {
          this.setState({
            projectstate: '正在研发',
            initLoading: true
          })
          // 新增数据
          addPro(data).then((res: any) => {
            // if (res.code === 200) {
            this.setState({
              listData: [],
              loadMorePoint: 3
            }, () => {
              // 刷新列表
              that.getProlist(0, 6)
              // 提示
              message.success('创建成功!')
              // 关闭弹窗
              that.setState({
                addVisible: false,
                activeKey: '0'
              })
            })
            // }
          })
        }
        // 编辑确认
        else if (this.state.type === 'edit') {
          this.setState({
            initLoading: true
          })
          data.projectid = this.state.projectid
          // 请求编辑接口
          upPro(data).then((res: any) => {
            // if (res.code === 200) {
            that.setState({
              listData: [],
              loadMorePoint: 3
            }, () => {
              // 刷新列表
              that.getProlist(0, 6)
              // 提示
              message.success('编辑成功!');
              // 关闭弹窗
              that.setState({
                addVisible: false
              })
            })
            // }
          })
        }
      })
  }
  // 控制 新增或编辑 弹窗的取消按钮
  handleOnCancel = () => {
    this.setState({
      addVisible: false,
      currentPage: 1
    })
  }

  // 搜索按钮点击
  onSearch = (value: any) => {
    this.setState({
      searchValue: value,
      loadMorePoint: 3,
      listData: []  // 查询前赋空
    })
    // 模糊查询
    if (value) {
      // 调用查询接口
      this.fnFindProbynamelist(0, 6, '163159795485528064', value)
    } else {
      // 展示所有
      this.getProlist(0, 6)
    }
  }

  // 列表模糊查询方法
  fnFindProbynamelist = (skip: number, limit: number, userid: string, projectname: string) => {
    findProbynamelist({
      skip,
      limit,
      userid,
      projectname
    }).then((res: any) => {
      this.setState({
        listData: res.projectcontract ? this.state.listData.concat(res.projectcontract) : [],
        total: res.total
      })
    })
  }

  // 新增合作按钮点击
  addClick = () => {

    this.setState({
      plateIndex: 0,
      type: 'add',
      plate: '教育&校园',
      createtime: '',
      initcontract: '',
      currentPage: 1
    }, async () => {
      // 获取客户列表
      await this.fnGetCusts('160675131818659840', this.state.pageSize, this.state.currentPage)
      this.setState({
        initialValues: { company: this.state.custsList[0].customerName },
        addVisible: true,
      })
    })
  }

  // 获取客户列表的方法
  fnGetCusts = (userId: string, pageSize: number, pageNum: number) => {
    return getCusts({ userId, pageSize, pageNum }).then((res: any) => {
      console.log(res.data)
      this.setState({
        custsList: this.state.currentPage === 1 ? res.data.data : this.state.custsList.concat(res.data.data),
        custsTotal: res.data.total
      })
    })
  }

  // 弹窗里面 选择板块的回调
  onPlateItemChange = (index: number) => {
    this.setState({ plateIndex: index })
    switch (index) {
      case 0:
        this.setState({ plate: '教育&校园' })
        break
      case 1:
        this.setState({ plate: '交通&物流' })
        break
      case 2:
        this.setState({ plate: '政务&企业' })
        break
      case 3:
        this.setState({ plate: '农医&其他' })
        break
    }
  }
  // 弹窗里选择创建时间的回调
  onChangeStartTime = (e: any, v: any) => {
    this.setState({ createtime: v })
  }
  // 弹窗里选择初始合同时间的回调
  onChangeInitialTime = (e: any, v: any) => {
    this.setState({ initcontract: v })
  }
  // 弹窗里选择客户公司的回调
  onChangeCompany = (e: any) => {
    // this.setState({
    //   kehucompany: e
    // })
  }
  // 客户列表滚动监听
  onSelectPopupScroll = (e: any) => {
    const { target } = e
    // 滚动到了底部加载数据
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const totalPage = Math.ceil(this.state.custsTotal / this.state.pageSize)
      this.setState({
        currentPage: this.state.currentPage + 1
      }, () => {
        console.log(this.state.currentPage)
        if (this.state.currentPage <= totalPage) {
          // 请求数据
          this.fnGetCusts('160675131818659840', this.state.pageSize, this.state.currentPage)
        }
      })
    }
  }

  render() {
    // Tabs按钮组
    const tabsBtn = (
      <Space size='large'>
        <Search placeholder="搜索项目" onSearch={this.onSearch} style={{ width: 192 }} />
        <Button type="primary" onClick={this.addClick}>新增合作</Button>
      </Space>
    )
    return (
      <>
        <Space direction='vertical' size={15} style={{ width: '100%' }}>
          <Space direction='vertical' size={15} style={{ paddingLeft: 7 }}>
            <Title level={5} style={{ marginBottom: 0, fontSize: 20 }}>项目管理</Title>
            <div style={{ fontSize: 14 }}>这里展示合作的所有项目</div>
          </Space>
          {/* 列表 */}
          <div style={{ backgroundColor: '#ffffff', padding: '0 25px 25px', borderRadius: 2 }}>
            <Tabs activeKey={this.state.activeKey} tabBarExtraContent={tabsBtn} onChange={this.onTabsChange}>
              <TabPane tab="正在研发" key='0'>
                <ListComp listData={this.state.listData} initLoading={this.state.initLoading} menuOptionClick={this.menuOptionClick} onLoadMore={this.onLoadMore} />
              </TabPane>
              <TabPane tab="待交付" key='1'>
                <ListComp listData={this.state.listData} initLoading={this.state.initLoading} menuOptionClick={this.menuOptionClick} onLoadMore={this.onLoadMore} />
              </TabPane>
              <TabPane tab="已交付" key='2'>
                <ListComp listData={this.state.listData} initLoading={this.state.initLoading} menuOptionClick={this.menuOptionClick} onLoadMore={this.onLoadMore} />
              </TabPane>
            </Tabs>
          </div>
          {/* 新增合作模态框 */}
          <AddCooperation
            custsList={this.state.custsList} // 客户列表
            onSelectPopupScroll={this.onSelectPopupScroll}  // 客户列表滚动监听
            type={this.state.type}
            initialValues={this.state.initialValues}
            onPlateItemChange={this.onPlateItemChange}
            plateIndex={this.state.plateIndex}
            onChangeStartTime={this.onChangeStartTime}
            onChangeInitialTime={this.onChangeInitialTime}
            onChangeCompany={this.onChangeCompany}
            addFormRef={this.addFormRef}
            visible={this.state.addVisible}
            onOk={this.handleOnOk}
            onCancel={this.handleOnCancel} />
        </Space>
      </>
    )
  }
}

export default DeliveList