import { getAccessToken } from "@/utils/utils";
import axios from "axios";

/**
 * 费用管理模块接口
 */
//  10.0.0.30:31007  10.10.10.10:30010/pay/
const service = axios.create({
  baseURL:'http://10.10.10.200:30010/pay/',
})

// 根据费用名模糊搜索（费用账单或发票中心列表）默认搜索全部
export const getPayList = data => service.post('/getpaylist',data);
// 新增账单
export const addBill = data => service.post('/addpay',data);
// 删除账单
export const delBill = data => service.post('/delpay',data);
// 更新账单
export const updatePay = data => service.post('/updatepay',data);
// 批量确认信息
export const confirmPay = data => service.post('/right',data);
// 批量打印发票信息
export const batchPrint = data => service.post('/batchprint',data);
// 费用统计接口
export const getstatistics = data => service.post('/getstatistics',data);

//<<<<<<< HEAD
// export const getProjectList = data => axios.post(
//   'http://10.0.0.15:32222/project/list',
//   data,
//   {headers:
//     {'Token':getAccessToken()}
//   });
//=======
export const getProjectList = data => axios.post(
  'http://10.10.10.10:30010/pay/project/list',
  data,
  {headers:
    {'Token':getAccessToken()}
  });
//>>>>>>> 29d73e17a97f24270141739b23a6bab295281738