import { getAccessToken } from "@/utils/utils";
import axios from "axios";

/**
 * 客户管理模块接口
 */
const service = axios.create({
  baseURL:'http://10.10.10.200:30010',
  headers:{
    'Token':getAccessToken()
  }
})

// 请求拦截器
service.interceptors.request.use(config => {
  //设置请求头
  config.headers = {
    'Token': getAccessToken()
  }
  return config
}, error => {
  return error;
})

//客户管理-客户列表
export const getCusts = data => service.post('/customer/list',data)
// export const getCusts = data => axios.post('http://10.0.0.17:32222/customer/list',data)

//客户管理-新增
export const addCust = data => service.post('/customer/add',data)
//客户管理-删除
export const delCust = (customerId,userId) => service.delete(`/customer/delete/${customerId}/${userId}`)
//客户管理-修改客户信息
export const updCust = data => service.put('/customer/update',data)
//客户管理-客户详情信息
export const getCustDet = (customerId,userId) => service.get(`/customer/get/${customerId}/${userId}`)
//客户管理-查询客户信息
export const searchCustList = data => service.post('/customer/search',data)
//查询客户列表[客户ID,客户名]
export const custList = data => service.post('/customer/list',data);
//查询客户项目
export const custProjects = data => service.post('/project/name/list',data);



//客户拜访记录列表
export const getCustVisits = data => service.post('/customer/visit/list',data)
//客户拜访记录-新增
export const addCustVisit = data => service.post('/customer/visit/add',data)
//客户拜访记录-修改
export const updCustVisit = data => service.put('/customer/visit/update',data)
//客户拜访记录-删除
export const delCustVisit = (visitId,userId) => service.delete(`/customer/visit/delete/${visitId}/${userId}`)
//客户拜访记录-详情
export const getCustVisitDet = (visitId,userId) => service.get(`/customer/visit/get/${visitId}/${userId}`)
//客户拜访记录-搜索
export const searchCustVisitList = data => service.post('/customer/visit/search',data)