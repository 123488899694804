import React, { Component } from 'react'
import { Tabs, Input, Button, Space,Typography, Table, Modal, ConfigProvider, Pagination, FormInstance, message } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'; //国际化 中文
import './index.css';
import AddModal from './components/AddModal';
import ModModal from './components/ModModal';
import DetModal from './components/DetModal';
import { addCust, custProjects, delCust, getCustDet, getCusts, searchCustList, updCust } from '@/request/customerapi';
import { getRandom } from '@/utils/utils';
const { Search } = Input;
const { Title} = Typography;
const { TabPane} = Tabs;
const userId = '160675131818659840';
// const customerId = "16283245012449928";
interface customer{
  customerId: string,
  customerName: string,
  customerShortName: string,
  customerProvince: string,
  customerCity: string,
  customerPostcode: string,
  companyAddress: string,
  companyPhone: string,
  companyWebsite: string,
  companyFax: string,
  companyIndustry: string,
  companyCredit: string,
  companyEmail: string,
  companyDescribe: string,
  companyDemand: string
}

interface isState {
  data:any,
  total:number,
  searchContent:string,
  currentPage:number,
  pageSize:number,
  isCanPrintManage:boolean,
  printManages:any,
  addCustomerModalVisiable: boolean,
  delManageModalVisiable: boolean,
  modCustomerModalVisiable: boolean,
  detCustomerModalVisiable: boolean,
  custProjects:any,
  currentCustomer:customer,
}

export default class Manage extends Component<any,isState> {
  //#region 
  constructor(props:any){
    super(props);
    this.state = {
      data: [],
      total:0,
      searchContent:'',
      currentPage:1,
      pageSize:10,
      isCanPrintManage:false,
      printManages:[],
      addCustomerModalVisiable: false,
      delManageModalVisiable: false,
      modCustomerModalVisiable: false,
      detCustomerModalVisiable: false,
      custProjects:[],
      currentCustomer:{customerId: 'string',
        customerName: 'string',
        customerShortName: 'string',
        customerProvince: 'string',
        customerCity: 'string',
        customerPostcode: 'string',
        companyAddress: 'string',
        companyPhone: 'string',
        companyWebsite: 'string',
        companyFax: 'string',
        companyIndustry: 'string',
        companyCredit: 'string',
        companyEmail: 'string',
        companyDescribe: 'string',
        companyDemand: 'string'}
    }
  }
  tabHandle = (key:any)=> {
    switch (key){
      case 'manage':{
        this.props.history.push('/customer/manage');
        break;
      }
      case 'visit':{
        this.props.history.push('/customer/visit');
        break;
      }
      default:
        break;
    }
  }
  // 获取客户信息列表
  getManageDatas=(currentPage:any,pageSize:any,searchContent:any)=>{
    let data = {
      userId,
      pageSize,
      pageNum:currentPage
    }
    if(searchContent!==null && searchContent!==''){
    }
    getCusts(data).then(
      response=>{
        if(response.data.code==='200'){
          this.setState({data:response.data.data,total:response.data.total});
        }else{
          message.error('请求错误');
        }
      }
    )
  }
  // 刷新数据
  refershDatas=()=>{
    const {currentPage,pageSize,searchContent} = this.state;
    this.getManageDatas(currentPage,pageSize,searchContent);
  }
  // 组件挂载完成后的动作
  componentDidMount(){
    const {currentPage,pageSize,searchContent} = this.state;
    this.getManageDatas(currentPage,pageSize,searchContent);
  }
  // 分页器行为
  pageChange = (page: any, pageSize: any) => {
    const {searchContent} = this.state;
    this.setState({currentPage:page,pageSize});
    this.getManageDatas(page,pageSize,searchContent);
  } 
  // 搜索客户信息
  onSearch = (value:any) => {
    const {pageSize} = this.state;
    if(value!==null&&value!==''){
      const data = {
        "str": value,
        pageSize,
        "pageNum": 1,
        userId,
      }
      searchCustList(data).then(
        response=>{
          if(response.data.code==='200'){
            this.setState({data:response.data.data,searchContent:value,currentPage:1});
          }else{
            message.error(response.data.message);
          }
        }
      )
    }else{
      this.getManageDatas(1,pageSize,value);
    }
   
  } 
  // 弹窗展示
  showModal = (option: string,currentCustomer:any=null) => {
    switch (option) {
      case 'addcustomer': {
        const x = getRandom();
        this.addCustomerFormRef.current?.setFieldsValue({customerId:x})
        this.setState({ addCustomerModalVisiable: true });
        break;
      }
      case 'delmanage':{
        
        this.setState({currentCustomer,delManageModalVisiable: true});
        break;
      }
      case 'modmanage':{
        getCustDet(currentCustomer.customerId,userId).then(
          (response)=>{
            if(response.data.code ==='200'){
              this.setState({currentCustomer:response.data.data,modCustomerModalVisiable: true});
            }else{
              message.error('请求失败！')
            }
          }
        )
        break;
      }
      case 'detcustomer':{
        const data = {
          customerId:currentCustomer.customerId
        }
        custProjects(data).then(
          response=>{
            this.setState({custProjects:response.data.data})
          }
        )
        getCustDet(currentCustomer.customerId,userId).then(
          (response)=>{
            if(response.data.code ==='200'){
              this.setState({currentCustomer:response.data.data,detCustomerModalVisiable: true});
            }else{
              message.error('请求失败！')
            }
          }
        )
        
        break;
      }
      default:
        break;
    }
  }
  // 添加客户引用
  addCustomerFormRef = React.createRef<FormInstance>();
  // 添加客户form验证后的提交动作
  addCustomerFormFinish = (values: any)=>{
    let data = {
      "userId":'160675131818659840',
      "customerId": values.customerId,
      "customerName": values.customerName,
      "customerShortName":  values.customerShortName,
      "customerProvince":  values.customerProC[0],
      "customerCity": values.customerProC[1],
      "customerPostcode":  values.customerPostcode,
      "companyAddress":  values.companyAddress,
      "companyPhone":  values.companyPhone,
      "companyWebsite":  values.companyWebsite,
      "companyFax": values.companyFax,
      "companyIndustry":  values.companyIndustry,
      "companyCredit":  values.companyCredit,
      "companyEmail":  values.companyEmail,
      "companyDescribe":  values.companyDescribe,
      "companyDemand":  values.companyDemand
    }
    addCust(data).then(
      response=>{
        if(response.data.code==='200'){
          this.setState({ addCustomerModalVisiable: false });
          this.addCustomerFormRef.current?.resetFields();
          this.refershDatas();
          message.success(response.data.message);
        }else{
          message.error(response.data.message)
        }
      }
    )
    
  }
  // 添加客户窗口控制
  handleAddModal = (option: string) => {
    if (option === 'dosave') {
      this.addCustomerFormRef.current?.submit();
    } else {
      this.setState({ addCustomerModalVisiable: false })
      this.addCustomerFormRef.current?.resetFields();
    }
  }
  // 删除客户弹窗控制
  handleDelModal = (option: string) =>{
    if (option === 'dodel') {
      const {currentCustomer} = this.state;
      delCust(currentCustomer.customerId,userId).then(
        response=>{
          if(response.data.code==='200'){
            message.success(response.data.message);
            this.setState({ delManageModalVisiable: false })
            this.refershDatas();
          }else{
            message.error(response.data.message);
          }
        }
      )
    } else {
      this.setState({ delManageModalVisiable: false })
    }
  }
  // 编辑客户form表单引用
  modCustomerFormRef = React.createRef<FormInstance>();
  // 编辑客户信息验证后提交动作
  modCustomerFormFinish = (values: any)=>{
    let data = {
      "userId":userId,
      "customerId": values.customerId,
      "customerName": values.customerName,
      "customerShortName":  values.customerShortName,
      "customerProvince":  values.customerProC[0],
      "customerCity": values.customerProC[1],
      "customerPostcode":  values.customerPostcode,
      "companyAddress":  values.companyAddress,
      "companyPhone":  values.companyPhone,
      "companyWebsite":  values.companyWebsite,
      "companyFax": values.companyFax,
      "companyIndustry":  values.companyIndustry,
      "companyCredit":  values.companyCredit,
      "companyEmail":  values.companyEmail,
      "companyDescribe":  values.companyDescribe,
      "companyDemand":  values.companyDemand
    }
    updCust(data).then(
      response=>{
        if(response.data.code==='200'){
          this.setState({ modCustomerModalVisiable: false });
          message.success(response.data.message);
          this.refershDatas();
        }else{
          message.error(response.data.message)
        }
      }
    )
  }
  // 编辑客户弹窗控制
  handleModModal = (option: string) => {
    if (option === 'dosave') {
      this.modCustomerFormRef.current?.submit();
    } else {
      this.modCustomerFormRef.current?.resetFields();
      this.setState({ modCustomerModalVisiable: false })
    }
  }
  //客户详情详情窗口控制
  handleDetModal = ()=>{
    this.setState({detCustomerModalVisiable:false})
  }
  //#endregion
  render(){
    const { data,total, addCustomerModalVisiable ,delManageModalVisiable,modCustomerModalVisiable,detCustomerModalVisiable,
       currentPage,currentCustomer} = this.state;
    console.log(this.state.custProjects )
    const columns = [
      { title: <span style={{fontWeight:700}}>客户名称</span>, dataIndex: 'customerName', align: 'center' as 'center',  },
      { title: <span style={{fontWeight:700}}>客户电话</span>, dataIndex: 'companyPhone', align: 'center' as 'center',  },
      { title: <span style={{fontWeight:700}}>所在省市</span>, align: 'center' as 'center', render:(record:any)=>{
          return(record.customerProvince+'/'+record.customerCity)
      } },
      { title: <span style={{fontWeight:700}}>信用等级</span>, dataIndex: 'companyCredit', align: 'center' as 'center' , },
      {
        title:<span style={{fontWeight:700}}>操作</span>,align: 'center' as 'center', render: (text:any,record:any) => {
          return (<div style={{display:'flex',justifyContent:"center",}}>
            <Button type="link" onClick={()=>this.showModal('detcustomer',record)}>详情</Button>
            <Button type="link" onClick={()=>this.showModal('modmanage',record)} >编辑</Button>
            <Button type="link" danger onClick={()=>this.showModal('delmanage',record)} >删除</Button>
            </div>)
        }
      },
    ]
    const activeKey = this.props.history.location.pathname.split('/')[2];
    return (
      <div className="manage">
        <Space direction='vertical' size={15}>
          <Space direction='vertical' size={15} style={{paddingLeft:7}}>
            <Title level={5} style={{marginBottom:0,fontSize:20}} >客户管理</Title>
            <div style={{fontSize:14}}>这里展示所有的客户信息</div>
          </Space>
        </Space>
        <div style={{minWidth:910}}>
          <div className="manage-main">
            <div className="manage-main-top">
              <div className="left" style={{width:'100%'}}>
                <Tabs defaultActiveKey={activeKey} onChange={this.tabHandle} tabBarExtraContent={
                <div >
                  <span className="search">
                    <Search placeholder="搜索客户名称" allowClear onSearch={this.onSearch} style={{ width: 192, height: 32,}} />
                  </span>
                  <span>
                    <Button type="primary" onClick={() => this.showModal('addcustomer')} style={{ width: 88, height: 32, marginLeft: 24, borderRadius: 2 }} >新增客户</Button>
                  </span>
                </div>}>
                  <TabPane tab={<span style={{fontWeight:700}}>客户管理</span> } key="manage"   >
                  </TabPane>
                  <TabPane tab={<span style={{fontWeight:700}}>客户拜访</span> } key="visit" style={{fontWeight:700}}>
                  </TabPane>
                </Tabs>
              </div>
              
            </div>

            <div className="manage-main-content">

              <Table
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                rowKey={record=>record.customerId}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </div>

            <div className="manage-main-pagination">
              <ConfigProvider locale={zhCN}>
                <Pagination pageSizeOptions={['10', '20', '50']} showQuickJumper defaultCurrent={1} current={currentPage} total={total} onChange={this.pageChange} /></ConfigProvider>
            </div>
          </div>
          
          <div className="manage-modal">
            <AddModal
              addCustomerModalVisiable={addCustomerModalVisiable}
              addCustomerModalHandle={this.handleAddModal}
              addCustomerFormRef={this.addCustomerFormRef}
              addCustomerFormFinish={this.addCustomerFormFinish}
            ></AddModal>
            <Modal title="删除费用账单" visible={delManageModalVisiable} centered onOk={() => this.handleDelModal('dodel')} onCancel={() => this.handleDelModal('cancel')}>
                确定要删除名为 {currentCustomer.customerName} 的客户吗？
            </Modal>

            {modCustomerModalVisiable&&<ModModal
              currentCustomer = {currentCustomer}
              modCustomerModalVisiable={modCustomerModalVisiable}
              modCustomerModalHandle={this.handleModModal}
              modCustomerFormRef={this.modCustomerFormRef}
              modCustomerFormFinish={this.modCustomerFormFinish}
            />}
            <DetModal
              currentCustomer = {currentCustomer}
              custProjects = {this.state.custProjects}
              detCustomerModalVisiable={detCustomerModalVisiable}
              detCustomerModalHandle={this.handleDetModal}
            />
          </div>
        </div>

      </div>
    )
  }
}
