import React, { Component } from 'react'
import { Route} from 'react-router-dom';
import Manage from './Manage';
import Visit from './Visit';
export default class Customer extends Component {
  
  render() {
    return (
      <div>
        <Route  path="/customer/manage" component={Manage}></Route>
        <Route  path="/customer/visit" component={Visit}></Route>
      </div>
    )
  }
}
