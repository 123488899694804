import React, { Component } from 'react'
import { Modal, Form, Row, Col,Input,Cascader,Select } from 'antd'
import options from '../citydata';
const {TextArea} = Input;
const {Option} = Select;

export default class ModModal extends Component<any> {
  vailCustomerName=(rule:any,value:any,callback:any)=>{
    if(value===''){
      return Promise.resolve()
    }else if(value.trim()===''){
      return Promise.reject('格式不正确')
    }
    return Promise.resolve();
  }
  componentDidMount(){

  }
  render() {
    const {
      currentCustomer,
      modCustomerModalVisiable,
      modCustomerModalHandle,
      modCustomerFormRef,
      modCustomerFormFinish } = this.props
    const cityOptions=options
    return (
      <div>
        <Modal
          visible={modCustomerModalVisiable}
          title="编辑客户信息"
          width={850}
          onOk={() => modCustomerModalHandle('dosave')}
          onCancel={() => modCustomerModalHandle('cancle')}>
          <Form onFinish={modCustomerFormFinish} ref={modCustomerFormRef} >
            <Row   gutter={[1,10]}>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>客户编码</Col>
              <Col span={9} key={1}>
                <Form.Item initialValue={currentCustomer.customerId} name='customerId' >
                  <Input readOnly></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}><span style={{color:'red',fontWeight:'bolder',position:'relative',top:3}}>* </span>客户名称</Col>
              <Col span={9} key={2}>
                <Form.Item  name='customerName' initialValue={currentCustomer.customerName}  rules={[{required:true,message:'请输入客户名称'},{validator:this.vailCustomerName}]} >
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>客户简称</Col>
              <Col span={9} key={3}>
                <Form.Item  name='customerShortName' initialValue={currentCustomer.customerShortName}  >
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}><span style={{color:'red',fontWeight:'bolder',position:'relative',top:3}}>* </span>所在省市</Col>
              <Col span={9} key={4}>
                <Form.Item  name='customerProC' initialValue={[currentCustomer.customerProvince,currentCustomer.customerCity]} rules={[{required:true,message:'请选择所在省市'}]} >
                <Cascader
                  options={cityOptions}
                  // onChange={onChange}
                />
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>邮编</Col>
              <Col span={9} key={5}>
                <Form.Item  name='customerPostcode' initialValue={currentCustomer.customerPostcode}>
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>公司地址</Col>
              <Col span={9} key={6}>
                <Form.Item  name='companyAddress' initialValue={currentCustomer.companyAddress} >
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}><span style={{color:'red',fontWeight:'bolder',position:'relative',top:3}}>* </span>公司电话:</Col>
              <Col span={9} key={7}>
                <Form.Item  name='companyPhone' initialValue={currentCustomer.companyPhone} rules={[{required:true,message:'请输入公司电话'},
                {pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的手机号' }]} >
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>公司官网:</Col>
              <Col span={9} key={8}>
                <Form.Item  name='companyWebsite' initialValue={currentCustomer.companyWebsite} >
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>公司传真:</Col>
              <Col span={9} key={9}>
                <Form.Item  name='companyFax' initialValue={currentCustomer.companyFax}>
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>所属行业:</Col>
              <Col span={9} key={10}>
                <Form.Item  name='companyIndustry' initialValue={currentCustomer.companyIndustry}>
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}><span style={{color:'red',fontWeight:'bolder',position:'relative',top:3}}>* </span>信用等级:</Col>
              <Col span={9} key={11}>
                <Form.Item  name='companyCredit' initialValue={currentCustomer.companyCredit} rules={[{required:true,message:'请选择信用等级'}]} >
                <Select placeholder="请选择">
                  <Option value="S">S</Option>
                  <Option value="A">A</Option>
                  <Option value="B">B</Option>
                  <Option value="C">C</Option>
                </Select>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}><span style={{color:'red',fontWeight:'bolder',position:'relative',top:3}}>* </span>公司邮箱:</Col>
              <Col span={9} key={12}>
                <Form.Item  name='companyEmail' initialValue={currentCustomer.companyEmail} rules={[{required:true,message:'请输入公司邮箱'},
                {pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message: '邮箱格式不正确', }]} >
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>客户描述:</Col>
              <Col span={21} key={13}>
                <Form.Item  name='companyDescribe' initialValue={currentCustomer.companyDescribe}>
                  <TextArea rows={3}></TextArea>
                </Form.Item>
              </Col>
              <Col span={3} style={{textAlign:'center',paddingTop:'5px'}}>客户需求:</Col>
              <Col span={21} key={14}>
                <Form.Item  name='companyDemand' initialValue={currentCustomer.companyDemand}>
                  <TextArea rows={3}></TextArea>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}
