import axios from 'axios';
import { message } from 'antd'
import { getAccessToken } from '@/utils/utils'

/**
 * 用户管理模块接口
 */
const service = axios.create({
  baseURL: 'http://10.10.10.200:30010'
})

// 请求拦截器
service.interceptors.request.use(config => { 
  // 每次发送请求之前本地存储中是否存在token，也可以通过Redux这里只演示通过本地拿到token
  // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
  // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
  // const token = window.localStorage.getItem('userToken') || window.sessionStorage.getItem('userToken');
  //在每次的请求中添加token
  // config.data = Object.assign({}, config.data, {
  //   token: token,
  // })
  //设置请求头
  config.headers = {
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    'Token': getAccessToken()
  }
  //序列化请求参数，不然post请求参数后台接收不正常
  // config.data = QS.stringify(config.data)
  return config
}, error => { 
    return error;
})

/**
 * 响应拦截器
 */
service.interceptors.response.use(response => {
  //根据返回不同的状态码做不同的事情
  // 这里一定要和后台开发人员协商好统一的错误状态码
  if (response.data.code) {
    switch (response.data.code) {
      case 200:
        return response.data;
      case '200':
        return response.data;
      default:
        console.log(response)
        message.error(response.data.message)
    }
  } else {
    console.log(response)
    return response;
  }
})

/* *************************************************************** */
// 获取用户列表
export const userList = data => service.post('/cuser/list', data)
// 删除用户
// /user/delete/{userTableId}
export const userDelete = data => service.delete('/cuser/delete/'+ data.userTableId, data)
// 添加用户
export const userAdd = data => service.post('/cuser/add', data)
// 修改用户信息
export const userUpdate = data => service.put('/cuser/update', data)
// 修改密码
export const passwordUpdate = data => service.put('/cuser/password/update', data)
// 查询用户列表
export const userSearch = data => service.post('/cuser/search', data)