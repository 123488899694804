import React, { Component } from 'react';
import { Input, Button, Modal,Menu, Form,List,Space,Typography, Select,Dropdown, DatePicker,ConfigProvider, message} from 'antd';
import {EllipsisOutlined} from '@ant-design/icons'
import zhCN from 'antd/es/locale/zh_CN'; //国际化 中文
import 'moment/locale/zh-cn'; //日期选择 框内中文
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import './index.css'
import { addDevRecord, delDevRecord, getDevRecordList, searchDevRecordList } from '@/request/devrecordapi';
import { getCusts } from '@/request/customerapi';
import { projectList } from '@/request/deliverapi';
const {Title} = Typography;
const userId = '160675131818659840';

const { Search } = Input;
const { Option } = Select;
interface devRecord{
  researchId: string,
  projectId: string,
  customerId: string,
  demandProgress: number,
  taskProgress: number,
  repairProgress: number,
  expectTime: string,
}
interface isState {
  addDevOrderModalVisible: boolean,
  delDevOrderModalVisible: boolean,
  currentPage: number,
  pageSize: number,
  devRecorders: any,
  total:number,
  searchKey: any,
  currentDevRecord:devRecord,
  initLoading:boolean,
  loading:boolean,
  projects:any,
  projectsPage:number,
  projectsPageSize:number,
  projectsTotal:number,
  custListPage:number,
  custListPagesize:number,
  custList:any,
  custListTotal:any,
}
export default class DevRecordList extends Component<any, isState> {
  constructor(props: any) {
    super(props);
    this.state = {
      addDevOrderModalVisible: false,
      delDevOrderModalVisible: false,
      currentPage:1,
      pageSize:6,
      initLoading:true, 
      loading:true,
      devRecorders:[],
      total:0,
      searchKey:'',
      projects:[],
      projectsPage:1,
      projectsPageSize:10,
      projectsTotal:0,
      custListPage:1,
      custListPagesize:10,
      custList:[],
      custListTotal:0,
      currentDevRecord:{
        "researchId": '',
        "projectId": '',
        "customerId": '',
        "demandProgress": 0,
        "taskProgress": 0,
        "repairProgress": 0,
        "expectTime": ''
      }
    }
  }
  // 获取研发记录列表
  getDevRecords(currentPage: number,pageSize: number,searchKey: any){
    const {devRecorders} = this.state;
    console.log(123)
    if(searchKey!==null&&searchKey!==''){
      const data = {
        "str": searchKey,
        "pageSize": pageSize,
        "pageNum": currentPage
      }
      searchDevRecordList(data).then(
        response=>{
          console.log(response)
          if(response.data.code==='200'){
            if(currentPage===1){
              this.setState({devRecorders:response.data.data,total:response.data.total,loading:false,})
            }else{
              this.setState({devRecorders:[...devRecorders,...response.data.data],total:response.data.total,loading:false,})
            }
          }else{
            message.error('请求错误')
          }
        }
      )
    }else{
      console.log(456)
      const data = {
        "pageSize": pageSize,
        "pageNum": currentPage,
      }
      getDevRecordList(data).then(
        response=>{
          console.log(response)
          if(response.data.code==='200'){
            if(currentPage===1){
              this.setState({devRecorders:response.data.data,total:response.data.total,loading:false,})
            }else{
              this.setState({devRecorders:[...devRecorders,...response.data.data],total:response.data.total,loading:false,})
            }
          }else{
            message.error('请求错误')
          }
        }
      ).catch((err: any) => {
        console.log(err)
      })
    }
  }
  // 刷新列表
  refreshData = ()=>{
    const {pageSize} = this.state;
    this.getDevRecords(1,pageSize,'');
    this.setState({currentPage:1});
  }
  // 组件初始化钩子
  componentDidMount(){
    console.log(123456789)
    const {currentPage,pageSize} = this.state;
    this.getDevRecords(currentPage,pageSize,null);
    getDevRecordList({
      "pageSize": 10,
      "pageNum": 1,
    }).then((res: any) => {
      console.log(res)
    }).catch((err: any) => {
      console.log(err)
    })
  }
  // 查找项目
  onSearch = (value: any) => {
    this.setState({currentPage:1,searchKey:value})
    this.getDevRecords(1,6,value);
  }
  //设置当前的记录
  setCurrentDevRecord = (currentDevRecord:any)=>{
    this.setState({currentDevRecord})
  }
  // 获取项目列表 
  getProjects= (currentPage:number,pageSize:number)=>{
    const data = {
      "pageNum":currentPage,
      "pageSize":pageSize,
    }
    projectList(data).then(
      (result:any)=>{
        if(result.code==='200'){
          this.setState(state=>({
            projects:[...state.projects,...result.data],
            projectsTotal:result.total
          }))
        }else{
          message.error('请求异常');
        }
      }
    )
  }
  // 分页加载项目列表
  loadingProjects = (e:any)=>{
    if(e.target.scrollTop + e.target.offsetHeight===e.target.scrollHeight){
      const{projects,projectsPage,projectsPageSize,projectsTotal} = this.state;
      if(projects.length<projectsTotal){
        setTimeout(()=>{
          this.setState({projectsPage:projectsPage+1});
          this.getProjects(projectsPage+1,projectsPageSize);
        },600)
      }
    }
  }
  // 获取客户列表
  getCustList = (currentPage:any,pageSize:any)=>{
    console.log(123)
    const {custList} = this.state;
    const data = {
      "userId":userId,
      "pageSize": pageSize,
      "pageNum": currentPage,
    }
    getCusts(data).then(
      response=>{
        if(response.data.code==='200'){
          this.setState({custList:[...custList,...response.data.data],custListTotal:response.data.total})
        }else{
          message.error('请求失败');
        }
      }
    )
  }
  // 分页加载客户列表
  loadingCustList = ()=>{
    const {custListPage,custListPagesize,custList,custListTotal} = this.state;
    if(custList.length<custListTotal){
      this.getCustList(custListPage+1,custListPagesize);
      this.setState({custListPage:custListPage+1});
    }
  }
  // 弹窗展示
  showModal = (modal: string) => {
    switch (modal) {
      case 'addorder': {
        const {projectsPage,projectsPageSize,custListPage,custListPagesize} = this.state;
        this.getProjects(projectsPage,projectsPageSize);
        this.getCustList(custListPage,custListPagesize);
        this.setState({ addDevOrderModalVisible: true });
        break;
      }
      case 'delorder':{
        this.setState({delDevOrderModalVisible: true})
        break;
      }
      default:
        break;
    }
  }
  // 添加研发记录弹窗控制
  addModalHandle = (option: number) => {
    if (option === 0) {
      this.addDevRecordFormRef.current?.resetFields();
      this.setState({ addDevOrderModalVisible: false,custList:[] })
    } else {
      this.addDevRecordFormRef.current?.submit();
    }
  }
  // 添加研发记录form表单引用
  addDevRecordFormRef = React.createRef<FormInstance>();
  onFinish = (values: any) => {
    const data = {
      "projectId": values.project.key,
      "projectName": values.project.label,
      "customerId": values.customer.key,
      "customerName": values.customer.label,
      "expectTime": values.expectTime.format('yyyy-MM-DD')
    }
    addDevRecord(data).then(
      response=>{
        if(response.data.code==='200'){
          this.refreshData()
          message.success('添加研发记录成功');
        }else{
          message.error('添加失败');
        }
      }
    )
    this.addDevRecordFormRef.current?.resetFields();
    this.setState({ addDevOrderModalVisible: false ,custList:[]});
    return true;
  };
  // 跳转详情页
  skipDetail = (currentDevRecord: devRecord)=>{
    localStorage.setItem('currentDevRecord',JSON.stringify(currentDevRecord));
    this.props.history.push('/devrecord/detail/')
  }
  // 删除弹窗控制
  delModalHandle = (option: number)=>{
    if(option===0){
      this.setState({delDevOrderModalVisible:false});
    }else{
      const {currentDevRecord} = this.state;
      delDevRecord(currentDevRecord.researchId).then(
        response=>{
          if(response.data.code==='200'){
            message.success('删除研发记录成功');
            this.refreshData();
          }else{
            message.error('删除研发记录失败')
          }
        }
      );
      this.setState({delDevOrderModalVisible:false});
    }
  }
  // 加载更多研发记录
  loadMore = ()=>{
    const {currentPage,pageSize,searchKey} =this.state;
    this.setState({currentPage:currentPage+1});
    this.getDevRecords(currentPage+1,pageSize,searchKey);
  }
  
  render() {
    const { addDevOrderModalVisible,delDevOrderModalVisible,projects,total,devRecorders,currentDevRecord,loading,custList} = this.state;
    const listOtherMenu=(
      <Menu style={{width:60,textAlign:'center'}}>
        <Menu.Item onClick={()=>this.showModal('delorder')} key="0" style={{ color: '#ff0000' }}>
          删除
        </Menu.Item>
      </Menu>
    )
    const loadMore =
      (devRecorders.length<total && !loading) ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={this.loadMore}>加载更多</Button>
        </div>
      ) : null;
    return (
      <div style={{minWidth:920}}>
        <Space direction='vertical' size={15}>
          <Space direction='vertical' size={15} style={{paddingLeft:7}}>
            <Title level={5} style={{marginBottom:0,fontSize:20}} >研发记录管理</Title>
            <div style={{fontSize:14}}>这里展示所有的研发记录</div>
          </Space>
        </Space>
        <div className="dev-record">
          <div className="top">
            <div className="left">
              <span className="title">全部项目</span>
              <span className="total">{total}</span>
            </div>
            <div className="right">
              <span className="search">
                <Search placeholder="搜索项目" allowClear onSearch={this.onSearch} style={{ width: 192,height:32,marginTop:16 }} />
              </span>
              <span>
                <Button type="primary" style={{width:120, height: 32,marginLeft:24,borderRadius:2}} onClick={() => this.showModal('addorder')}>新增研发记录</Button>
              </span>
            </div>
          </div>
          <div className="dev-record-content">
          <List
              style={{width:'100%',paddingBottom:10}}
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={devRecorders}
              loadMore={loadMore}
              loading={loading}
              renderItem={(item:any) => (
                <List.Item
                  actions={[
                  <span key="list-loadmore-edit" onClick={()=>this.skipDetail(item)} style={{color:'rgb(89, 168, 255)',cursor:'pointer'}}>详情</span>,
                  <Dropdown overlay={listOtherMenu}  placement="bottomRight" arrow>
                    <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={()=>this.setCurrentDevRecord(item)}><EllipsisOutlined /></span>
                  </Dropdown>]}
                >
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>项目</span>}
                      description={<span style={{color:'black'}}>{item.projectName}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}
                    />
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>客户公司</span>}
                      description={<span style={{color:'black'}}>{item.customerName}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textAlign:'center'}}
                    />
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>需求进度</span>}
                      description={<span style={{color:'black'}}>{item.demandProgress}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textAlign:'center'}}
                    />
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>任务进度</span>}
                      description={<span style={{color:'black'}}>{item.taskProgress}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textAlign:'center'}}
                    />
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>修复进度</span>}
                      description={<span style={{color:'black'}}>{item.repairProgress}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textAlign:'center'}}
                    />
                </List.Item>
              )}
            />
          </div>
          <div className="modal">
            <Modal
              visible={addDevOrderModalVisible}
              title="新增研发记录"
              onCancel={() => this.addModalHandle(0)}
              style={{ overflow: 'hidden', minWidth: '400px' }}
              okText='确认'
              cancelText='取消'
              onOk={()=>this.addModalHandle(1)}
            >
              <Form ref={this.addDevRecordFormRef} layout={'vertical'} name="addOrderForm" onFinish={this.onFinish}>
                <Form.Item name="project" label="项目" rules={[{ required: true ,message:'请选择项目！'}]}>
                  <Select labelInValue onPopupScroll={this.loadingProjects} style={{ width: 300 }} placeholder="请选择">
                    {projects.map((item:any)=>{
                        return <Option key={item.projectId} value={item.projectId}>{item.projectName}</Option>
                      })}
                  </Select>
                </Form.Item>
                <Form.Item name="customer" label="客户公司" rules={[{ required: true ,message:'请选择客户公司！'}]}>
                  <Select labelInValue onPopupScroll={this.loadingCustList} style={{ width: 300 }} placeholder="请选择">
                    {custList.map((item:any)=>{
                      return <Option key={item.customerId} value={item.customerId}>{item.customerName}</Option>
                    })}
                  </Select>
                </Form.Item>
                <ConfigProvider locale={zhCN}>
                <Form.Item name="expectTime" initialValue={moment(new Date()).endOf('day')}  label="预计完成时间" rules={[{ required: true,message:'请选择完成时间！' }]}>
                  <DatePicker style={{width:300}} />
                </Form.Item></ConfigProvider>
              </Form>
            </Modal>
            <Modal
              visible={delDevOrderModalVisible}
              title="系统提示"
              onOk={()=>this.delModalHandle(1)}
              onCancel={() => this.delModalHandle(0)}
              okText="确认" cancelText="取消"
            >你确定要删除{currentDevRecord.researchId}记录吗？</Modal>
          </div>
        </div>
      </div>
    )
  }
}
