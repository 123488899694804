import React, { Component, } from 'react'
import { Button, Form, Input, Descriptions, Table, DatePicker, Pagination, ConfigProvider, Modal, FormInstance, InputNumber, message } from 'antd';
import zhCN from 'antd/es/locale/zh_CN'; //国际化 中文
import './index.css';
import moment from 'moment';
import { addDetDevRecord, getDetDevRecordList } from '@/request/devrecordapi';
import { calcRemainTime } from '@/utils/utils';
import { getCusts } from '@/request/customerapi';


interface isState {
  addModalVisiable: boolean,
  currentDevRecord: any,
  currentPage:number,
  pageSize:number,
  data:any,
  total:number,
}
export default class DevRecordDetail extends Component<any, isState> {
  constructor(props: any) {
    super(props);
    this.state = {
      addModalVisiable: false,
      currentDevRecord:{},
      currentPage:1,
      pageSize:10,
      data:[],
      total:0
    }
  }
  getDetDevRecords = (currentPage:number,pageSize:number)=>{
    const currentDevRecord = localStorage.getItem('currentDevRecord')
    if(currentDevRecord!==null){
      const data = {
        "pageSize": pageSize,
        "pageNum": currentPage,
        "researchId": JSON.parse(currentDevRecord).researchId
      }
      getDetDevRecordList(data).then(
        response=>{
          if(response.data.code==='200'){
            this.setState({data:response.data.data,total:response.data.total});
          }else{
            message.error('请求错误')
          }
        }
      )
    }
   
  }
  refreshData=()=>{
    const {currentPage,pageSize} = this.state;
    this.getDetDevRecords(currentPage,pageSize);
  }
  componentDidMount() {
    const currentDevRecord = localStorage.getItem('currentDevRecord')
    if(currentDevRecord!==null){
      this.setState({currentDevRecord:JSON.parse(currentDevRecord)})
    }
    const {currentPage,pageSize} = this.state;
    this.getDetDevRecords(currentPage,pageSize);
  }
  showModal = (modalNo: number) => {
    switch (modalNo) {
      case 1: {
        this.setState({ addModalVisiable: true });
        getCusts()
        break;
      }
      default:
        break;
    }
  }

  onChange=(date: any, dateString: any)=>{
  }
  addModalHandle = (option: number) => {
    if (option === 0) {
      this.setState({ addModalVisiable: false, });
      this.addDetDevRecordRef.current?.resetFields();
    } else {
      this.addDetDevRecordRef.current?.submit();
    }
  }
  addDetDevRecordRef = React.createRef<FormInstance>();

  addDetDevRecordFinish = (values: any) => {
    const {currentDevRecord} = this.state;
    const data = {
      "researchId": currentDevRecord.researchId,
      "projectDemand": values.projectDemand,
      "completeDemand": values.completeDemand,
      "projectTask": values.projectTask,
      "completeTask": values.completeTask,
      "projectRepair": values.projectRepair,
      "completeRepair": values.completeRepair,
      "createTime": values.createTime.format('yyyy-MM-DD')
    }
    addDetDevRecord(data).then(
      response=>{
        if(response.data.code==='200'){
          this.refreshData();
          message.success('添加研发详情成功');
        }else{
          message.error('添加失败')
        }
      }
    )
    this.setState({ addModalVisiable: false, })
    this.addDetDevRecordRef.current?.resetFields();
  };


  pageChange = (pageNumber: any, pageSize: any) => {
    this.setState({currentPage:pageNumber,pageSize:pageSize});
    this.getDetDevRecords(pageNumber,pageSize);
  }
  render() {
    const {data,total,currentPage,pageSize} = this.state;
    const columns = [
      { title: '时间',align: 'center'as'center' ,dataIndex: 'createTime', },
      { title: '项目需求',align: 'center'as'center' , dataIndex: 'projectDemand',  },
      { title: '项目需求完成',align: 'center'as'center' , dataIndex: 'completeDemand',  },
      { title: '项目任务',align: 'center'as'center' , dataIndex: 'projectTask',  },
      { title: '项目任务完成',align: 'center'as'center' , dataIndex: 'completeTask',  },
      { title: '项目缺陷',align: 'center'as'center' , dataIndex: 'projectRepair',  },
      { title: '项目缺陷完成',align: 'center'as'center' , dataIndex: 'completeRepair',  },
    ];
    const { addModalVisiable,currentDevRecord } = this.state;
    return (

      <div className="detail">
        <div className="breadcrumb"><span style={{ color: 'gray' }}>项目交付</span> / 项目记录管理</div>
        <div className="page-title">{currentDevRecord.projectName}项目</div>
        <div className="content">
          <div className="top-desc">
            <Descriptions bordered style={{ width: '100%', overflow: 'hidden' }}>
              <Descriptions.Item key="1"  label="项目预计完成时间" span={3}>{currentDevRecord.expectTime}</Descriptions.Item>
              <Descriptions.Item key="2" label="项目需求进度" span={1}>{currentDevRecord.demandProgress}</Descriptions.Item>
              <Descriptions.Item key="3" label="项目任务进度" span={2}>{currentDevRecord.taskProgress}</Descriptions.Item>
              <Descriptions.Item key="4" label="项目缺陷完成进度" span={3}>{currentDevRecord.repairProgress}</Descriptions.Item>
              <Descriptions.Item key="5" label="剩余时间" span={3}>{calcRemainTime(currentDevRecord.expectTime)}</Descriptions.Item></Descriptions>
            <div className="dev-top-desc-btn">
              <Button type="primary" onClick={() => this.showModal(1)}>新增</Button>
            </div>
          </div>
          <div className="main">
            <Table rowKey={record=>record.researchDetailsId}  columns={columns} pagination={false} dataSource={data} 
              summary={pageDate => {
                let totalB = 0;
                let totalC = 0;
                let totalD = 0;
                let totalE = 0;
                let totalF = 0;
                let totalG = 0;
                pageDate.forEach((item) => {
                  totalB += parseInt(item.projectDemand);
                  totalC += parseInt(item.completeDemand);
                  totalD += parseInt(item.projectTask);
                  totalE += parseInt(item.completeTask);
                  totalF += parseInt(item.projectRepair);
                  totalG += parseInt(item.completeRepair);
                });
                return (
                  <Table.Summary.Row style={{textAlign:'center'}}>
                    <Table.Summary.Cell key="1" index={1}>合计</Table.Summary.Cell>
                    <Table.Summary.Cell key="2" index={2}>{totalB}</Table.Summary.Cell>
                    <Table.Summary.Cell key="3" index={3}>{totalC}</Table.Summary.Cell>
                    <Table.Summary.Cell key="4" index={4}>{totalD}</Table.Summary.Cell>
                    <Table.Summary.Cell key="5" index={5}>{totalE}</Table.Summary.Cell>
                    <Table.Summary.Cell key="6" index={6}>{totalF}</Table.Summary.Cell>
                    <Table.Summary.Cell key="7" index={7}>{totalG}</Table.Summary.Cell>
                  </Table.Summary.Row>
                )
              }} />
            <div className="main-pagination">
              <ConfigProvider locale={zhCN}>
                <Pagination defaultPageSize={pageSize} pageSizeOptions={['10', '20', '50']} showQuickJumper defaultCurrent={currentPage} total={total} onChange={this.pageChange} /></ConfigProvider>
            </div>

          </div>

        </div>
        <div className="modal">
          <Modal
            visible={addModalVisiable}
            title="新增研发记录"
            okText="确定"
            cancelText="取消"
            onOk={() => this.addModalHandle(1)}
            onCancel={() => this.addModalHandle(0)}
            width={720}
            style={{minWidth:720,whiteSpace:'nowrap',overflow:'hidden'}}
          >
            <Form
              ref={this.addDetDevRecordRef}
              name="basic"
              layout="vertical"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={this.addDetDevRecordFinish}
              style={{paddingLeft:10,paddingRight:10}}
            >
              <Form.Item
              >
                <Input.Group style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
                  <Form.Item
                    label="今日项目需求数"
                    name="projectDemand"
                    style={{ width: '25%',display:'inline-block' }}
                    rules={[{ required: true, message: '请输入今日需求数' }]}
                  >
                    <InputNumber min={0} style={{width:'100%'}} />
                  </Form.Item>
                  <Form.Item
                    label="今日项目任务数"
                    name="projectTask"
                    style={{ width: '25%',display:'inline-block' }}
                    rules={[{ required: true, message: '请输入今日项目任务数' }]}
                  >
                    <InputNumber min={0} style={{width:'100%'}} />
                  </Form.Item>
                  <Form.Item
                    label="今日项目缺陷数"
                    name="projectRepair"
                    style={{ width: '25%',display:'inline-block' }}
                    rules={[{ required: true, message: '请输入今日项目缺陷数' }]}
                  >
                    <InputNumber min={0} style={{width:'100%'}} />
                  </Form.Item>
                </Input.Group>
                <Input.Group style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
                  <Form.Item
                    label="今日项目需求完成数"
                    name="completeDemand"
                    style={{ width: '25%',display:'inline-block' }}
                    rules={[{ required: true, message: '请输入今日需求完成数' }]}
                  >
                    <InputNumber min={0} style={{width:'100%'}} />
                  </Form.Item>
                  <Form.Item
                    label="今日项目任务完成数"
                    name="completeTask"
                    style={{ width: '25%',display:'inline-block' }}
                    rules={[{ required: true, message: '请输入今日项目任务完成数' }]}
                  >
                    <InputNumber min={0} style={{width:'100%'}} />
                  </Form.Item>
                  <Form.Item
                    label="今日项目缺陷完成数"
                    name="completeRepair"
                    style={{ width: '25%',display:'inline-block' }}
                    rules={[{ required: true, message: '请输入今日项目缺陷完成数' }]}
                  >
                    <InputNumber min={0} style={{width:'100%'}} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <ConfigProvider locale={zhCN}>
              <Form.Item initialValue={moment(new Date()).endOf('day')} label="创建日期" name="createTime" style={{position:'relative',bottom:'30px'}}  rules={[{required:true,message:'请选择日期'}]}>
                <DatePicker onChange={this.onChange} style={{width:162}} />
              </Form.Item></ConfigProvider>
            </Form>

          </Modal>
        </div>
      </div>
    )
  }
}
