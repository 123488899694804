import React, { Component, } from 'react'
import { Button, Badge, Comment, List, message,Modal, Form, Upload,Avatar } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import E from 'wangeditor'
import './index.css';
import { checkedAndGetProjectUrl, } from '@/utils/minio';
import { detOrder, replyOrder, shutOrder } from '@/request/workorderapi';
import { downloadFiles, uploadFiles } from '@/utils/utils';
let editor: any = null;
interface isState {
  currentOrder: any,
  editorContent: string,
  fileList: any,
  closeOrderVisible:boolean,
}
export default class WorkOrderDetail extends Component<any, isState> {
  constructor(props: any) {
    super(props);
    // this.taEditorRef = React.createRef<any>();
    this.state = {
      currentOrder: {},
      editorContent: '',
      fileList: [],
      closeOrderVisible:false,
    }
    const orderid = localStorage.getItem('orderid');
    this.getDetOrder(orderid);
  }

  getDetOrder = (orderId: any) => {
    const data = { orderid: orderId }
    detOrder(data).then(
      response => {
        if (response.data.code === 200) {
          this.setState(state => (
            { currentOrder: response.data.order }));
        } else {
          message.error('请求失败');
        }
      }
    )
  }
  componentDidMount() {
    editor = new E(document.getElementById('text-area'));
    editor.config.height = 180;
    editor.config.showFullScreen = false;
    editor.config.showLinkImg = false
    editor.config.placeholder = '在此编辑回复内容'
    // 富文本框配置菜单
    editor.config.menus = [
      'image', // 插入图片
    ]
    //Base64展示图片 与 uploadImgServer不能同时使用
    // editor.config.uploadImgShowBase64 = true
    // editor.config.uploadImgServer = 'http://localhost:3000/api/reply'
    editor.config.customUploadImg = function (resultFiles: any, insertImgFn: any) {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      uploadFiles(resultFiles).then(
        (resolve:any)=>{
          const fileNames = resolve.fileNames;
          for (let i = 0; i < fileNames.length; i++) {
                checkedAndGetProjectUrl(fileNames[i], (presignedUrl: any) => {
                  if(presignedUrl!==null)
                    // 上传图片，返回结果，将图片插入到编辑器中
                    insertImgFn(presignedUrl);
                  else{
                    message.error('请求异常');
                    return;
                  }
                })
              }
        },reject=>{
          message.error('请求异常');
        }
      )
    }
    editor.create();
  }
  downloadMainFile = () => {
    const {currentOrder} = this.state;
    const {file} = currentOrder;
    downloadFiles(file);
    
  }
  closeOrder = (option:any) => {
    const {currentOrder} = this.state;
    switch(option){
      case 0:
        this.setState({closeOrderVisible:true});
        break;
      case 1:
        const data = {'orderid':currentOrder.orderid};
        shutOrder(data).then(
          response=>{
            if(response.data.code===200){
              message.success('关闭工单成功');
              this.props.history.push('/workorder')
              this.getDetOrder(currentOrder.orderid);
            }else{
              message.error('关闭工单失败')
            }
          }
        )
        this.setState({closeOrderVisible:false});
        break;
      case 2:
        this.setState({closeOrderVisible:false});
        break;
      default:
        break;
    }
  }
  replyFormRef = React.createRef<FormInstance>();
  replyHandle = () => {
    if (((editor.txt.text().trim() !== null) && (editor.txt.text().trim() !== '')) ||( editor.txt.html() !== '')) {
      const { fileList,currentOrder } = this.state;
      let files:any = [];
      const data = {
        "orderid": currentOrder.orderid,
        "recvid": "asgasg",
        "recvname": "三丰",
        "content": editor.txt.html(),
        "file": files,
      }
      if(fileList.length!==0){
        uploadFiles(fileList).then(
          (resolve:any)=>{
            files = resolve.fileNames
            data.file=files;
            replyOrder(data).then(
              response=>{
                if(response.data.code===200){
                  message.success('回复成功')
                  this.getDetOrder(currentOrder.orderid)
                }else{
                  message.error('请求异常,回复出错')
                }
              }
            )
            this.setState({ fileList: [] })
            editor.txt.clear()
          },reject=>{
            message.error('请求异常,回复失败');
          }
        );
      }else{
        replyOrder(data).then(
          response=>{
            if(response.data.code===200){
              message.success('回复成功')
              this.getDetOrder(currentOrder.orderid)
            }else{
              message.error('请求异常,回复出错')
            }
          }
        )
        editor.txt.clear()
      }
    } else {
      message.warning('请输入回复信息！');
    }
  }
  commentDownloadHandle = (file: any) => {
    if (file !== undefined && file.length!==0) {
      if(downloadFiles(file)==='success'){
        message.success('下载成功');
      }else{
        message.error('请求异常');
      }
    } else {
      message.warning('暂无附件')
    }
  }
  beforeUpload = (file: any) => {
    const fileType = ['application/zip','application/rar','image/jpeg','image/jpg','image/png','text/plain',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    if(file.size>(1024*1024*50)){
      message.warning(file.name+'文件过大,请重新选择');
    }else if(fileType.indexOf(file.type)>-1){
      this.setState(state => ({
        fileList: [file]
      }))
    }else{
      message.warning('文件类型不正确，请重新选择');
    }
    return false;
  }
  removeFile = (file: any) => {
    this.setState(state => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList
      }
    })
  }
  render() {
    const { fileList, currentOrder,closeOrderVisible } = this.state;
    return (
      <div className="detail">
        <div className="work-desc-page-title">{currentOrder.projectname}</div>
        <div className="card-desc">
          <span className="card-desc-item-title" >
            工单状态 &nbsp;<Badge status={currentOrder.status==='关闭'?'error':'success'} text={currentOrder.status} />
          </span>
          <div className="card-desc-content">
            <div className="card-desc-content-item">
              <span className="card-desc-item-title">工单编号 <span className="card-desc-item-content">&nbsp;{currentOrder.orderid}</span></span>
              <span className="card-desc-item-title">客户公司 <span className="card-desc-item-content">&nbsp;{currentOrder.clientname}</span></span>
              <span className="card-desc-item-title">创建时间 <span className="card-desc-item-content">&nbsp;{currentOrder.createtime}</span></span>
            </div>
            <div className="card-desc-options">
              <Button disabled={currentOrder.file===undefined?true:false} onClick={this.downloadMainFile}>下载</Button>
              <Button type="primary" danger onClick={()=>this.closeOrder(0)} style={{ marginLeft: 15 ,display:currentOrder.status==='关闭'?'none':'' }}>关闭工单</Button>
            </div>
          </div>
        </div>
        <div className="detial-main">
          <div className="main-theme">
            <p style={{ fontWeight: 900, fontSize: '18px' }}>沟通记录</p>
            <Comment
              avatar={
                <Avatar
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  alt="Han Solo"
                />
              }
              author={<p style={{fontSize:15,fontWeight:700}}>问题描述</p> }
              content={<p >这块是问题描述</p>}
            />
          </div>
          {
            currentOrder.resp===undefined ? null :
              <div className="main-comment">
                {currentOrder.resp.length> 0 ?
                  <List
                    className="comment-list"
                    itemLayout="vertical"
                    dataSource={currentOrder.resp}
                    renderItem={(item:any) => (
                      <li style={{ borderTop: '1px dashed rgb(194, 194, 194)' }}>
                        <Comment
                          avatar={
                            <Avatar
                              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                              alt="Han Solo"
                            />
                          }
                          author={item.recvname}
                          datetime={item.recvtime}
                          content={<p dangerouslySetInnerHTML={{__html:item.content}} style={{ paddingLeft: 9 }}></p>}
                          actions={[
                            <DownloadOutlined onClick={() => this.commentDownloadHandle(item.file)} title="下载附件" style={{ fontSize: 18,display:item.file===undefined?'none':''}} />,]}
                        />
                      </li>
                    )}
                  /> : <p style={{ lineHeight: '50px', textAlign: 'center' }}>暂无评论</p>
                }
              </div>
          }

          <div id="main-reply" style={{display:currentOrder.status==='关闭'?'none':''}}>
            <Form ref={this.replyFormRef}>
              <Form.Item name="replymsg">
                <div id="text-area"></div>
              </Form.Item>
            </Form>
            <div className="reply-options">
              <Upload beforeUpload={this.beforeUpload} onRemove={this.removeFile} fileList={fileList} multiple={false}>
                <Button icon={<UploadOutlined />}>附件</Button><br/>
                <span style={{color:'#A9A9A9',fontSize:13}}>支持.png、.jpg、.jpeg、.txt、.zip、.rar、.docx格式,只能上传单个文件,最大不超过50M</span></Upload>
              <Button type="primary" onClick={this.replyHandle} style={{}}>提交回复</Button>
            </div>
          </div>
        </div>
        <div>
          <Modal 
            visible={closeOrderVisible}
            onCancel={()=>this.closeOrder(2)}
            onOk={()=>this.closeOrder(1)}
            title='关闭工单'
            centered
          >
            <p>你确定要删除工单号-{currentOrder.orderid} 工单？</p>
          </Modal>
        </div>
      </div>
    )
  }
}
