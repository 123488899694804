import React, { Component } from 'react'
import { Modal, Button,Descriptions } from 'antd'

export default class DetModal extends Component<any> {

  render() {
    let {
      currentCustomer,
      custProjects,
      detCustomerModalVisiable,
      detCustomerModalHandle} = this.props;
    // const newCustomerNo = getRandom();
    custProjects = custProjects===undefined?[]:custProjects;
    return (
      <div>
        <Modal
          visible={detCustomerModalVisiable}
          title="客户详情"
          width={850}
          footer={
            <Button type='primary' style={{borderRadius:2}} onClick={detCustomerModalHandle}>关闭</Button>
          }
          onCancel={detCustomerModalHandle}
          >

            <Descriptions bordered>
                <Descriptions.Item label="客户编码" span={2}>{currentCustomer.customerId}</Descriptions.Item>
                <Descriptions.Item label="客户名称" span={1}>{currentCustomer.customerName}</Descriptions.Item>
                <Descriptions.Item label="客户简称" span={2}>{currentCustomer.customerShortName}</Descriptions.Item>
                <Descriptions.Item label="所在省市" span={1}>{currentCustomer.customerProvince}/{currentCustomer.customerCity}</Descriptions.Item>
                <Descriptions.Item label="邮编" span={2}>{currentCustomer.customerPostcode}</Descriptions.Item>
                <Descriptions.Item label="公司地址" span={1}>{currentCustomer.companyAddress}</Descriptions.Item>
                <Descriptions.Item label="公司电话" span={2}> {currentCustomer.companyPhone}</Descriptions.Item>
                <Descriptions.Item label="公司官网" span={1}>{currentCustomer.companyWebsite}</Descriptions.Item>
                <Descriptions.Item label="公司传真" span={2}>{currentCustomer.companyFax}</Descriptions.Item>
                <Descriptions.Item  label="所属行业" span={1}>{currentCustomer.companyIndustry}</Descriptions.Item>
                <Descriptions.Item  label="信用等级" span={2}>{currentCustomer.companyCredit}</Descriptions.Item>
                <Descriptions.Item  label="公司邮箱" span={1}>{currentCustomer.companyEmail}</Descriptions.Item>
                <Descriptions.Item  label="客户项目" span={3}>{custProjects.map((item:any,index:any)=>{
                  return index===custProjects.length-1?<span key={index}>{item}</span>:<span key={index}>{item},</span>
                })}</Descriptions.Item>
                <Descriptions.Item  label="客户描述" span={3}>{currentCustomer.companyDescribe}</Descriptions.Item>
                <Descriptions.Item  label="客户需求" span={3}>{currentCustomer.companyDemand}</Descriptions.Item>
            </Descriptions>
        </Modal>
      </div>
    )
  }
}
