import React from "react";
import { Empty, Image, DatePicker, Radio, Button, Steps, Row, Col, Card, List, Statistic, Progress, Timeline, Space, Result, Typography, Modal, Form, Input, Upload, message, Checkbox } from 'antd'
import { FolderOutlined, MinusCircleOutlined, DownloadOutlined, CopyOutlined, DownOutlined, UpOutlined, LoadingOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './StepContent.less'
import UploadDocModal from './Components/UploadDocModal'
import { getZllist, addZl, delZl, addDoc, getDoclist, upWeihu, getWeihu, upJieduan, pro, upChanpin, getChanpin, delDoc } from "@/request/deliverapi";
import { getResearch, researchDetailsAll } from '@/request/rdRecordsapi'
import { checkedAndUpload, checkedAndGetProjectUrl } from "@/utils/minio";
import copy from 'copy-to-clipboard';
const { confirm } = Modal

const { Step } = Steps
const { Title, Text } = Typography
const { RangePicker } = DatePicker

/**
 * 
 * 1. 商务与产品阶段内容组件
 * 
 */
class StepOne extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      isModalVisible: false,
      isUpload: false,
      file: null,
      etag: '',
      zlList: [],
      loadMorePoint: 0,
      noMore: false
    }
  }
  formRef: any = React.createRef()
  // 生命周期 已挂载DOM
  componentDidMount() {
    this.fnGetZllist(0, 3)
  }

  download = (fileName: string) => {
    console.log(fileName)
    // 获取url
    checkedAndGetProjectUrl(fileName, (url: string) => {
      window.open(url, '_blank')
    })
  }

  // 请求资料列表的方法
  fnGetZllist = (skip: number, limit: number) => {
    return getZllist({ skip, limit, projectid: this.props.projectid }).then((res: any) => {
      console.log(res)
      // if (this.state.loadMorePoint === 0) {  // 不为0表示加载数据

      // } else {

      // }
      this.setState({
        zlList: res.datas ? this.state.zlList.concat(res.datas) : [],
        total: res.total
      }, () => {
        this.setState({
          noMore: res.total > 3 ? false : true
        })
      })
    })
  }

  // 列表加载更多按钮
  loadMoreBtnClick = () => {
    if (this.state.total - this.state.loadMorePoint <= 3) {
      this.setState({
        noMore: true
      })
      message.warning('没有更多了~')
    } else {
      this.setState({
        // 每次加3
        loadMorePoint: this.state.loadMorePoint + 3
      }, () => {
        // 每次加载3条数据
        this.fnGetZllist(this.state.loadMorePoint, 3)
      })
    }
  }

  // 模态框确认
  handleOk = () => {
    const { fileList } = this.state
    let that = this
    // 验证表单值
    this.formRef.current.validateFields()
      // 通过验证
      .then((v: any) => {
        // 判断是否上传了文件
        if (this.state.fileList.length !== 0) {
          // 上传到minio
          checkedAndUpload('file', fileList[0], (etag: any) => {
            console.log(etag)
            this.setState({
              etag
            }, () => {
              console.log(this.state.etag)
              // 请求接口
              addZl({
                projectid: this.props.projectid,
                username: 'deyue',
                userid: 'asdfjassdasd',
                fname: v.name,
                ftype: v.type,
                furl: this.state.etag
              }).then(res => {
                that.setState({
                  zlList: [],
                  loadMorePoint: 0
                }, () => {
                  // 提示
                  message.success('上传成功!')
                  // 刷新资料共享列表
                  that.fnGetZllist(0, 3)
                  that.setState({
                    isModalVisible: false
                  })
                })
              })
            })
          })
        } else {
          message.error('请上传文件!')
        }
      })
  }
  // 模态框取消
  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      fileList: []
    })
  }
  // 上传按钮点击
  onUploadClick = () => {
    // 显示弹框
    this.setState({
      isModalVisible: true,
      isUpload: false,
      fileList: []
    })
  }
  // 删除资料按钮
  delBtn = (bwid: string) => {
    let that = this
    confirm({
      title: '是否删除?',
      okText: '确认',
      cancelText: '取消',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delZl({ bwid }).then(res => {
          that.setState({
            zlList: [],
            loadMorePoint: 0
          }, async () => {
            // 提示
            message.success('删除成功!')
            // 刷新资料共享列表
            await that.fnGetZllist(0, 3)
          })
        })
      }
    })
  }

  // customRequest = (info: any) => {
  //   // 如果有文件 上传成功
  //   if (info.file) {
  //     // 上传到minio
  //     checkedAndUpload('file', info, (etag: any, a: any) => {
  //       info.onSuccess()
  //       message.success(`${info.file.name}文件上传成功`)
  //       this.setState({
  //         isUpload: true,
  //         fileName: info.file.name,
  //         etag
  //       })
  //     });
  //   } else {
  //     // 上传失败
  //     info.onError()
  //     message.error(`${info.file.name}文件上传失败`);
  //   }
  // }
  // 上传备忘材料前回调
  beforeUpload = (file: any) => {
    console.log(file)
    this.setState({
      fileList: [file]
    }, () => {
      console.log(this.state.fileList)
    })
    return false
  }
  // 删除上传的备忘材料
  removeFile = (file: any) => {
    this.setState((state: any) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList
      }
    }, () => {
      console.log(this.state.fileList)
    })
  }

  render() {
    const loadMore = this.state.total <= 3 || this.state.zlList.length === 0 || this.state.noMore ? null :
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={this.loadMoreBtnClick}>加载更多</Button>
      </div>
    const { nextBtn1 } = this.props
    return (
      <>
        <Card
          type="inner"
          title="资料共享"
          extra={<Button onClick={this.onUploadClick} size='small'>上传</Button>}
        >
          <List
            loading={this.props.initLoading}
            loadMore={loadMore}
            dataSource={this.state.zlList}
            renderItem={(item: any) => (
              <List.Item
                actions={
                  [<Button onClick={() => this.download(item.furl)} style={{ backgroundColor: '#1890ff', color: '#fff' }} size='small'>下载文件</Button>, <Button onClick={() => this.delBtn(item.bwid)} size='small' style={{ border: '1px solid #ff0000', color: '#ff0000', boxSizing: 'border-box' }}>删除文件</Button>]
                }
              >
                <List.Item.Meta
                  style={{ minWidth: 600 }}
                  title={item.fname}
                  description={
                    <Space size={60}>
                      <Text type='secondary'>提交人：{item.username}</Text>
                      <Text type='secondary'>提交时间：{item.ctime}</Text>
                    </Space>
                  }
                />
                <List.Item.Meta
                  description={
                    <Space>
                      <FolderOutlined style={{ marginRight: '6px' }} />
                      <Text type='secondary'>{item.ftype}</Text>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
        <br />
        <Row justify='center'>
          <Button type="primary" onClick={nextBtn1}>下一步</Button>
        </Row>
        {/* 上传资料模态框 */}
        <UploadDocModal
          beforeUpload={this.beforeUpload}
          removeFile={this.removeFile}
          fileList={this.state.fileList}
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          formRef={this.formRef}
          isUpload={this.state.isUpload}
          fileName={this.state.fileName} />
      </>
    )
  }
}

/**
 * 
 * 2. 研发阶段组件
 * 
 */
class StepTwo extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      isOpenTimeline: false,
      timelineList: [],
      recordList: [],
      recordInfo: {}
    }
  }
  componentDidMount() {
    // 获取项目进度详情
    getResearch({ projectid: this.props.projectid }).then(res => {
      console.log('获取项目进度详情------>', res)
      this.setState({
        recordInfo: res.data
      })
    })
    // 获取研发记录列表
    researchDetailsAll({ researchId: this.props.projectid }).then(res => {
      console.log(res)
      if (res.data.length !== 0) {
        this.setState({
          recordList: res.data,
          timelineList: [res.data[0]]
        })
      }
    })
  }
  openTimeline = () => {
    this.setState({
      isOpenTimeline: !this.state.isOpenTimeline,
      timelineList: !this.state.isOpenTimeline ? this.state.recordList : [this.state.recordList[0]]
    })
  }

  // 获取预计完成需要天数函数
  getEstimateDay = (strTime: string) => {
    const nowDate = new Date().getTime()  // 现在时间
    const estimateStrTime = new Date(strTime).getTime()  // 预计时间
    if (estimateStrTime <= 0) {
      return 0
    } else {
      const time = Math.floor((estimateStrTime - nowDate) / (1000 * 60 * 60 * 24))
      if (time <= 0) {
        return 0
      } else {
        return time
      }
    }
  }

  render() {
    const { nextBtn2 } = this.props
    return (
      <>
        <Card type="inner" title="研发记录">
          <Row>
            <Timeline style={{ maxHeight: '200px', width: '90%', overflowY: 'auto', padding: '10px 0 0 0' }}>
              {
                this.state.timelineList === [] ? null :
                  this.state.timelineList.map((item: any, index: number) => {
                    return (
                      <Timeline.Item color={index === 0 ? 'blue' : 'gray'} key={index}> 完成需求：个，完成任务：个，修复缺陷：个</Timeline.Item>
                    )
                  })
              }
            </Timeline>
            {
              this.state.recordList.length === 1 || this.state.recordList.length === 0 ? null : (
                <div onClick={this.openTimeline} style={{ marginLeft: '15px', color: '#1890ff', cursor: 'pointer' }}>
                  {
                    !this.state.isOpenTimeline ? <><DownOutlined /> <span>展开</span></> : <><UpOutlined /> <span>收起</span></>
                  }
                </div>
              )
            }
          </Row>
        </Card>
        <Card style={{ marginTop: '16px' }} type="inner" title="进度看板">
          <Row gutter={24}>
            <Col span={6}>
              <ProjectProgress complete={this.state.recordInfo.completeDemand} progress={this.state.recordInfo.demandProgress} total={this.state.recordInfo.totalDemand} name="项目需求进度" color='blue' />
            </Col>
            <Col span={6}>
              <ProjectProgress complete={this.state.recordInfo.completeTask} progress={this.state.recordInfo.taskProgress} total={this.state.recordInfo.totalTask} name="项目任务进度" color='red' />
            </Col>
            <Col span={6}>
              <ProjectProgress complete={this.state.recordInfo.completeRepair} progress={this.state.recordInfo.repairProgress} total={this.state.recordInfo.totalRepair} name="缺陷修复进度" color />
            </Col>
            <Col span={6}>
              <h2>预计还需</h2>
              <p style={{ marginTop: '5px' }}><span style={{ fontSize: '22px', marginRight: '3px' }}>{this.getEstimateDay(this.state.recordInfo.expectTime)}</span>个工作日</p>
              <p style={{ fontSize: '8px', color: '#999999' }}>预计完成：{this.state.recordInfo.expectTime}</p>
              <p style={{ fontSize: '8px', color: '#999999' }}>*该时间基于工作量计算，仅供参考
                <br />
                具体时间已实际为准</p>
            </Col>
          </Row>
        </Card>
        <br />
        <Row justify="center">
          <Button onClick={nextBtn2} type="primary">下一步</Button>
        </Row>
      </>
    )
  }
}
// 项目进度
function ProjectProgress(props: any) {
  return (
    <>
      <h2>{props.name}</h2>
      <Statistic
        value={props.progress}
        precision={2}
        suffix="%"
      />
      <p style={{ fontSize: '8px', color: '#999999' }}>需求总数：{props.total}</p>
      <div>
        <p style={{ fontSize: '8px', float: 'right', marginRight: '19px' }}>已完成：{props.complete}</p>
        <Progress percent={props.progress} showInfo={false} strokeColor={props.color === 'blue' ? '#5b8ff9' : props.color === 'red' ? '#ff728a' : '#ff944d'} />
      </div>
    </>
  )
}
// 3. 待交付阶段
function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
/**
 * 
 * 3. 待交付阶段组件
 * 
 */
class StepThree extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      jiaofuInfo: {},
      addressList: [],
      isOpen: false,
      loading: false,
      isModalVisible: false,
      isUpload: false,
      fileName: '',
      etag: '',
      docList: [],
      deliverEditvisible: false,  // 控制步骤3 编辑弹窗显示
      androidChecked: false,
      iosChecked: false,
      wxChecked: false,
      imageUrl: '',
      jiaofuEtag: '',
      loadMorePoint: 0,
      noLoadmore: false,
      initialValues: {}
    }
  }
  formRef: any = React.createRef()
  deliverFormRef: any = React.createRef()

  async componentDidMount() {
    // 初始化交付文档列表
    this.fnGetDoclist(0, 3)
    // 查询交付产品
    await this.fnGetChanpin()
  }

  // 设置setState异步,以使用async/await,同步获取值
  setStateAsync = (state: object) => {
    return new Promise((resolve: any) => {
      this.setState(state, resolve)
    });
  }

  // 查询交付产品的方法
  fnGetChanpin = () => {
    getChanpin({ projectid: this.props.projectid }).then((res: any) => {
      console.log(res)
      this.setState({
        jiaofuInfo: res.djf,
        addressList: res.djf.webpage ? res.djf.webpage.slice(0, 3) : [],
        addressListTotal: res.djf.webpage ? res.djf.webpage.length : 0
      })
      if (res.djf.wx) {
        checkedAndGetProjectUrl(res.djf.wx, (url: string) => {
          console.log(url)
          this.setState({
            wxUrl: url
          })
          // window.open(url, '_blank')
        })
      }
      
    })
  }

  download = (fileName: string) => {
    console.log(fileName)
    // 获取url
    checkedAndGetProjectUrl(fileName, (url: string) => {
      window.open(url, '_blank')
    })
  }

  beforeUploadwx = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('您只能上传JPG/PNG文件！');
      this.setState({
        wxFilelist: []
      })
      return true
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.setState({
        wxFilelist: []
      })
      message.error('图像必须小于2MB！');
      return true
    }
    console.log(file)
    this.setState({
      wxFilelist: [file]
    })
    return false
  }
  removeFilewx = (file: any) => {
    this.setState((state: any) => {
      const index = state.wxFilelist.indexOf(file);
      const newFileList = state.wxFilelist.slice();
      newFileList.splice(index, 1);
      return {
        wxFilelist: newFileList
      }
    }, () => {
      console.log(this.state.wxFilelist)
    })
  }

  // 请求交付文档列表方法
  fnGetDoclist = (skip: number, limit: number) => {
    getDoclist({ skip, limit, projectid: this.props.projectid }).then((res: any) => {
      this.setState({
        docList: res.djfdocList ? this.state.docList.concat(res.djfdocList) : [],
        total: res.total
      }, () => {
        this.setState({
          noLoadmore: res.total > 3 ? false : true
        })
      })
    })
  }
  // 页面地址展开收起
  open = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      addressList: !this.state.isOpen ? this.state.jiaofuInfo.webpage : this.state.jiaofuInfo.webpage.slice(0, 3)
    })
  }
  // 上传点击
  uploadClick = () => {
    this.setState({
      isModalVisible: true,
      isUpload: false,
      fileName: '',
      etag: '',
      fileList: []
    })
  }
  // Modal确认
  handleOk = () => {
    const { fileList } = this.state
    // 验证表单值
    this.formRef.current.validateFields()
      // 通过验证
      .then((v: any) => {
        // 判断是否上传了文件
        if (this.state.fileList.length !== 0) {
          // 上传到minio
          checkedAndUpload('file', fileList[0], (etag: any) => {
            console.log(etag)
            this.setState({
              etag
            }, () => {
              console.log(this.state.etag)
              // 添加接口
              addDoc({
                projectid: this.props.projectid,
                fname: v.name,
                furl: this.state.etag
              }).then(res => {
                message.success('上传成功!')
                this.setState({
                  docList: [],
                  loadMorePoint: 0,
                  fileList: []
                }, () => {
                  // 刷新交付文档列表
                  this.fnGetDoclist(0, 3)
                })
                this.setState({
                  isModalVisible: false
                })
              })
            })
          })

        } else {
          message.error('请上传文件!')
        }
      })
  }
  // Modal取消
  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      fileList: []
    })
  }
  // 自定义上传回调
  customRequest = (info: any) => {
    // 如果有文件 上传成功
    if (info.file) {
      // 上传到minio
      checkedAndUpload('file', info, (etag: any) => {
        info.onSuccess()
        message.success(`${info.file.name}文件上传成功`)
        this.setState({
          isUpload: true,
          fileName: info.file.name,
          etag
        })
      });
    } else {
      // 上传失败
      info.onError()
      message.error(`${info.file.name}文件上传失败`);
    }
  }
  /* 步骤3交付产品 编辑按钮 点击 */
  onDeliverClick = () => {
    const { jiaofuInfo } = this.state
    let nUsers = []
    if (jiaofuInfo.webpage) {
      for (let i = 0; i < jiaofuInfo.webpage.length; i++) {
        let obj = {
          first: jiaofuInfo.webpage[i].webname,
          last: jiaofuInfo.webpage[i].weburl
        }
        nUsers.push(obj)
      }
    } else {
      nUsers = [1]
    }
    console.log(nUsers)
    this.setState({
      wxFilelist: [],
      deliverEditvisible: true,
      imageUrl: '',
      jiaofuEtag: '',
      initialValues: {
        alink: jiaofuInfo.android ? jiaofuInfo.android : '',
        ilink: jiaofuInfo.ios ? jiaofuInfo.ios : '',
        wxcode: jiaofuInfo.wx ? jiaofuInfo.wx : '',
        users: nUsers
      },
      androidChecked: jiaofuInfo.android ? true : false,
      iosChecked: jiaofuInfo.ios ? true : false,
      wxChecked: jiaofuInfo.wx ? true : false,
    }, () => {
      console.log(this.state.initialValues)
    })
  }
  /* 步骤3 编辑弹窗 取消按钮 */
  OnCancelDeliverEdit = () => {
    this.setState({
      deliverEditvisible: false,
      wxFilelist: []
    })
  }
  /* 步骤3 编辑弹窗 确认按钮 */
  onOkDeliverEdit = () => {
    const { wxFilelist } = this.state
    // 选择微信小程序 必须上传小程序二维码
    if (this.state.wxChecked && this.state.wxFilelist.length === 0) {
      message.error('请上传小程序二维码!')
      return
    }
    // users = true 说明 页面地址 没有输入
    let isWeb = this.deliverFormRef.current.getFieldValue().users[0]
    let users: any
    if (isWeb === 1) {
      users = true
    } else if (isWeb === undefined) {
      users = true
    } else if (!isWeb.first && !isWeb.last) {
      users = true
    } else {
      users = false
    }
    // 都没选择 关闭
    if (!this.state.androidChecked && !this.state.iosChecked && !this.state.wxChecked && users) {
      this.setState({
        deliverEditvisible: false
      })
    } else {
      // 没选 网址 不验证
      if (users) {
        this.deliverFormRef.current.resetFields(['users'])
      }
      // 选择某一个 验证
      this.deliverFormRef.current.validateFields().then((res: any) => {
        console.log(res)
        // 处理网站组
        let webpage: any = []
        if (!users) {
          res.users.map((item: any) => {
            return (
              webpage.push({
                webname: item.first,
                weburl: item.last
              })
            )
          })
        }
        // 上传二维码
        if (this.state.wxChecked && this.state.wxFilelist.length !== 0) {
          console.log('选择了上传二维码')
          // 上传到minio

          return checkedAndUpload('file', wxFilelist[0], (etag: any) => {
            this.setState({
              jiaofuEtag: etag
            }, () => {
              console.log(this.state.jiaofuEtag)
              // 请求数据
              const data = {
                projectid: this.props.projectid,
                android: this.state.androidChecked ? res.alink : '',
                ios: this.state.iosChecked ? res.ilink : '',
                wx: this.state.wxChecked ? this.state.jiaofuEtag : '',
                webpage
              }
              console.log(this.state.jiaofuEtag)
              upChanpin(data).then(res => {
                // 编辑成功刷新
                this.fnGetChanpin()
                // 提示
                message.success('编辑成功！')
                this.setState({
                  deliverEditvisible: false,
                  wxFilelist: []
                })
              })
            })
          })
        }

        console.log(this.state.jiaofuEtag)
        // 请求数据
        const data = {
          projectid: this.props.projectid,
          android: this.state.androidChecked ? res.alink : '',
          ios: this.state.iosChecked ? res.ilink : '',
          wx: this.state.wxChecked ? this.state.jiaofuEtag : '',
          webpage
        }
        console.log(this.state.jiaofuEtag)
        upChanpin(data).then(res => {
          // 编辑成功刷新
          this.fnGetChanpin()
          // 提示
          message.success('编辑成功！')
          this.setState({
            deliverEditvisible: false,
            wxFilelist: []
          })
        })

      })
    }
  }

  getWxUrl = (fileName: string) => {
    // 获取url
    checkedAndGetProjectUrl(fileName, (url: string) => {
      console.log(url)
      return url
      // window.open(url, '_blank')
    })
  }


  /* 步骤3 编辑弹窗 单选框改变 */
  // android
  onChangeAndroidCheckbox = (e: any) => {
    this.setState({
      androidChecked: e.target.checked
    })
  }
  // ios
  onChangeIosCheckbox = (e: any) => {
    this.setState({
      iosChecked: e.target.checked
    })
  }
  // weixin
  onChangeWxCheckbox = (e: any) => {
    this.setState({
      wxChecked: e.target.checked
    })
  }
  // 交付编辑 自定义上传回调
  productDeliveryUpload = (info: any) => {
    getBase64(info.file, (imageUrl: any) => {
      this.setState({
        imageUrl,
        loading: false
      })
    })
    // 如果有文件 上传成功
    if (info.file) {
      // 上传到minio
      checkedAndUpload('file', info, (etag: any) => {
        info.onSuccess()
        message.success(`${info.file.name}文件上传成功`)
        this.setState({
          jiaofuEtag: etag
        })
      });
    } else {
      // 上传失败
      info.onError()
      message.error(`${info.file.name}文件上传失败`);
    }
  }
  // 复制链接
  copyClick = (value: any) => {
    if (copy(value)) {
      message.success("复制成功")
    } else {
      message.error("复制失败")
    }
  }
  // 交付文档删除按钮
  removeBtn = (bwid: string) => {
    let that = this
    confirm({
      title: '是否删除？',
      okText: '确认',
      cancelText: '取消',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delDoc({
          bwid
        }).then((res: any) => {
          if (res.code === 200) {
            // 刷新列表
            that.setState({
              docList: [],
              loadMorePoint: 0
            }, () => {
              // 刷新交付文档列表
              that.fnGetDoclist(0, 3)
            })
          }
        })
      }
    })
  }
  // 加载更多
  loadMoreBtn = () => {
    if (this.state.total - this.state.loadMorePoint <= 3) {
      this.setState({
        noLoadmore: true
      })
      message.warning('没有更多了~')
    } else {
      this.setState({
        // 每次加3
        loadMorePoint: this.state.loadMorePoint + 3
      }, () => {
        // 每次加载3条数据
        this.fnGetDoclist(this.state.loadMorePoint, 3)
      })
    }
  }
  // 程序获取 链接点击
  AndroidLinkClick = () => {
    window.location.href = this.state.jiaofuInfo.android
  }
  IOSLinkClick = () => {
    window.location.href = this.state.jiaofuInfo.ios
  }
  // 上传备忘材料前回调
  beforeUpload3 = (file: any) => {
    console.log(file)
    this.setState({
      fileList: [file]
    }, () => {
      console.log(this.state.fileList)
    })
    return false
  }
  // 删除上传的备忘材料
  removeFile3 = (file: any) => {
    this.setState((state: any) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList
      }
    }, () => {
      console.log(this.state.fileList)
    })
  }

  render() {
    const { nextBtn3 } = this.props
    const { jiaofuInfo } = this.state
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8, fontSize: '12px' }}>上传小程序二维码</div>
      </div>
    )
    return (
      <div className="stepThree">
        {/* 交付产品 */}
        <Card type="inner" title="交付产品" extra={<Button size='small' onClick={this.onDeliverClick} style={{ backgroundColor: '#1890ff', color: '#fff' }}>编辑</Button>}>
          {
            !jiaofuInfo.android && !jiaofuInfo.ios && !jiaofuInfo.wx && (!jiaofuInfo.webpage || jiaofuInfo.webpage.length === 0)
              ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> :
              <List>
                {
                  !jiaofuInfo.android && !jiaofuInfo.ios && !jiaofuInfo.wx ?
                    null :
                    <List.Item>
                      <Row>
                        <div>程序获取：</div>
                        <Space direction='vertical' style={{ width: '200px', color: '#1890ff' }}>
                          {
                            !jiaofuInfo.android ? null :
                              <Row justify='space-between' align='middle'><span>Android</span><DownloadOutlined onClick={this.AndroidLinkClick} style={{ cursor: 'pointer' }} /></Row>
                          }
                          {
                            !jiaofuInfo.ios ? null :
                              <Row justify='space-between' align='middle'><span>IOS</span><DownloadOutlined onClick={this.IOSLinkClick} style={{ cursor: 'pointer' }} /></Row>
                          }
                          {
                            !jiaofuInfo.wx ? null :
                              <Row justify='space-between' align='middle'>
                                <span>微信小程序</span>
                                {
                                  <Image
                                    width={15}
                                    height={15}
                                    src={this.state.wxUrl}
                                  />
                                }
                              </Row>
                          }
                        </Space>
                      </Row>
                    </List.Item>
                }
                {
                  !jiaofuInfo.webpage || jiaofuInfo.webpage.length === 0 ?
                    null :
                    <List.Item>
                      <Row>
                        <div>页面地址：</div>
                        <Space direction='vertical' style={{ color: '#1890ff' }}>
                          <Space direction='vertical' style={{ minWidth: '200px' }}>
                            {
                              this.state.addressList.map((item: any, index: number) => {
                                return (
                                  <Row key={index} justify='space-between' align='middle'>
                                    <p style={{ marginRight: 30 }}><span style={{ color: '#262626' }}>{item.webname}：</span><span>{item.weburl}</span></p>
                                    <CopyOutlined onClick={() => this.copyClick(item.weburl)} style={{ cursor: 'pointer' }} />
                                  </Row>
                                )
                              })
                            }
                          </Space>
                          {
                            this.state.addressListTotal > 3 ?
                              <span style={{ cursor: 'pointer' }} onClick={this.open}>
                                {
                                  !this.state.isOpen ? <><DownOutlined /> <span>展开</span></> : <><UpOutlined /> <span>收起</span></>
                                }
                              </span> : null
                          }

                        </Space>
                      </Row>
                    </List.Item>
                }
              </List>
          }
        </Card>
        {/* 编辑弹出 */}
        <Modal
          title="交付产品"
          destroyOnClose={true}
          visible={this.state.deliverEditvisible}
          onCancel={this.OnCancelDeliverEdit}
          onOk={this.onOkDeliverEdit}
          okText="确认"
          cancelText="取消">
          <Form ref={this.deliverFormRef} initialValues={this.state.initialValues}>
            <Form.Item label="程序获取：">
              <Row>
                <Form.Item style={{ width: '30%' }} name='aname'>
                  <Checkbox checked={this.state.androidChecked} onChange={this.onChangeAndroidCheckbox} name="android">Android：</Checkbox>
                </Form.Item>
                <Form.Item style={{ width: '70%' }} name='alink' rules={[{ required: this.state.androidChecked, message: '请输入下载链接!' }]}>
                  <Input placeholder="请输入下载链接" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item style={{ width: '30%' }} name='iname'>
                  <Checkbox checked={this.state.iosChecked} onChange={this.onChangeIosCheckbox} name="ios">IOS：</Checkbox>
                </Form.Item>
                <Form.Item style={{ width: '70%' }} name='ilink' rules={[{ required: this.state.iosChecked, message: '请输入下载链接!' }]}>
                  <Input placeholder="请输入下载链接" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item style={{ width: '30%' }} name='wxname'>
                  <Checkbox checked={this.state.wxChecked} onChange={this.onChangeWxCheckbox} name="wx">微信小程序：</Checkbox>
                </Form.Item>
                <Form.Item style={{ width: '70%' }} name='wxcode'>
                  <Upload
                    onRemove={this.removeFilewx} fileList={this.state.wxFileList} multiple={false} maxCount={1}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    // showUploadList={false}
                    beforeUpload={this.beforeUploadwx}
                  // fileList={this.state.wxFileList}
                  // customRequest={this.productDeliveryUpload}
                  >
                    {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>
                </Form.Item>
              </Row>
            </Form.Item>
            <Form.Item label="页面地址">
              <Form.List name='users' initialValue={[1]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Row align='middle' justify='space-between'>
                        <Form.Item
                          style={{ width: '25%' }}
                          {...restField}
                          name={[name, 'first']}
                          fieldKey={[fieldKey, 'first']}
                          rules={[{ required: true, message: '请输入网站名!' }]}
                        >
                          <Input placeholder="网站名" />
                        </Form.Item>
                        <Form.Item
                          style={{ width: '62%' }}
                          {...restField}
                          name={[name, 'last']}
                          fieldKey={[fieldKey, 'last']}
                          rules={[{ required: true, message: '请输入网址!' }]}
                        >
                          <Input placeholder="网址" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Row>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        添加
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Form>
        </Modal>
        {/* 交付文档 */}
        <Card style={{ marginTop: '16px' }} type="inner" title="交付文档" extra={<Button size='small' onClick={this.uploadClick} style={{ backgroundColor: '#1890ff', color: '#fff' }}>上传</Button>}>
          <List
            loadMore={
              this.state.total <= 3 || this.state.docList.length === 0 || this.state.noLoadmore ? null :
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                  }}
                >
                  <Button onClick={this.loadMoreBtn}>加载更多</Button>
                </div>
            }
            dataSource={this.state.docList}
            renderItem={(item: any) => (
              <List.Item
                actions={
                  [
                    <Button size='small' onClick={() => this.download(item.furl)} style={{ backgroundColor: '#1890ff', color: '#fff' }}>下载文件</Button>,
                    <Button size='small' onClick={() => this.removeBtn(item.bwid)} style={{ border: '1px solid #ff0000', color: '#ff0000', boxSizing: 'border-box' }}>删除文件</Button>
                  ]
                }
              >
                <List.Item.Meta
                  style={{ minWidth: 600 }}
                  title={item.fname}
                  description={
                    <Space size={60}>
                      <Text type="secondary">提交人：{item.fname}</Text>
                      <Text type="secondary">提交时间：{item.ctime}</Text>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
        {/* 上传文档模态框 */}
        <UploadDocModal
          removeFile={this.removeFile3}
          beforeUpload={this.beforeUpload3}
          fileList={this.state.fileList}
          type={1}
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          // customRequest={this.customRequest}
          formRef={this.formRef}
          isUpload={this.state.isUpload}
          fileName={this.state.fileName} />
        <div style={{ textAlign: 'center', marginTop: '24px' }}>
          <Button type='primary' onClick={nextBtn3}>交付</Button>
        </div>
      </div>
    )
  }
}

/**
 * 
 * 4. 已交付&维护阶段组件
 * 
 */
class StepFour extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      maintainVisible: false,
      radioValue: 0,
      sTime: '',
      eTime: '',
      whstatus: '',
      weihuInfo: {}
    }
  }
  maintainRef: any = React.createRef()

  componentDidMount() {
    // 初始化维护信息
    this.fnGetWeihu()
  }

  // 请求维护信息方法
  fnGetWeihu = () => {
    getWeihu({ projectid: this.props.projectid }).then((res: any) => {
      this.setState({
        weihuInfo: res.djf
      })
    })
  }

  /* 步骤4 维护信息 按钮 */
  maintainBtnClick = () => {
    this.setState({
      maintainVisible: true,
      radioValue: 0,
      sTime: '',
      eTime: '',
      whstatus: '正常'
    })
  }
  /* 步骤4 弹窗 确认 */
  onOkMaintain = () => {
    this.maintainRef.current.validateFields().then((res: any) => {
      const data = {
        projectid: this.props.projectid,
        whid: 'fasdjofjadsiopf',
        whtype: res.type,
        whstart: this.state.sTime,
        whend: this.state.eTime,
        whstatus: this.state.whstatus
      }
      upWeihu(data).then((res: any) => {
        if (res.code === 200) {
          message.success('填写成功！')
          this.setState({
            maintainVisible: false
          })
          // 刷新维护信息
          this.fnGetWeihu()
        }
      })
    })
  }
  /* 步骤4 弹窗 取消 */
  onCancelMaintain = () => {
    this.setState({
      maintainVisible: false
    })
  }
  /* 步骤4 弹窗表单 Radio 选择回调 */
  onRadioChange = (e: any) => {
    this.setState({
      radioValue: e.target.value
    })
    if (e.target.value === 0) {
      this.setState({ whstatus: '正常' })
    } else if (e.target.value === 1) {
      this.setState({ whstatus: '失效' })
    }
  }
  // 选择日期范围改变
  onChangeRangePicker = (dateStrings: [string, string]) => {
    this.setState({
      sTime: dateStrings[0],
      eTime: dateStrings[1]
    })
  }

  render() {
    const { weihuInfo } = this.state
    return (
      <>
        <Result
          status="success"
          title="项目交付成功"
          subTitle="感谢您选择数信智能，我们还将为您提供优质的售后服务"
          extra={[
            <Button onClick={this.maintainBtnClick} type="primary" key="console">
              维护信息
            </Button>
          ]}
        />
        {/* 维护信息弹窗 */}
        <Modal title="项目维护信息" destroyOnClose={true} visible={this.state.maintainVisible} okText="确认" cancelText="取消" onOk={this.onOkMaintain} onCancel={this.onCancelMaintain}>
          <Form ref={this.maintainRef} layout="vertical">
            <Form.Item label="维护类型" name="type" required rules={[
              { required: true, message: '请输入维护类型' }
            ]}>
              <Input placeholder="请输入维护类型" />
            </Form.Item>
            <Form.Item label="维护日期" name="date" required rules={[
              { required: true, message: '请输入维护日期' }
            ]}>
              <RangePicker onChange={(dates, dateStrings) => this.onChangeRangePicker(dateStrings)} />
            </Form.Item>
            <Form.Item label="状态" required>
              <Radio.Group value={this.state.radioValue} onChange={this.onRadioChange}>
                <Radio value={0}>正常</Radio>
                <Radio value={1}>失效</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
        <div style={{ backgroundColor: '#f5f5f5', height: '100px', padding: '25px' }}>
          <Space direction='vertical'>
            <Title level={5}>项目维护信息</Title>
            <Space size={70}>
              <p>维护启动日期：<Text type="secondary">{weihuInfo.whstart}</Text></p>
              <p>有效维护期至：<Text type="secondary">{weihuInfo.whend}</Text></p>
              <p>维护类型：<Text type="secondary">{weihuInfo.whtype}</Text></p>
              <p>当前状态：
                {weihuInfo.whstatus ?
                  <Text type="secondary"><span style={{ position: 'relative', top: -2, width: 7, height: 7, backgroundColor: weihuInfo.whstatus === '正常' ? '#52c41a' : '#fa6400', display: 'inline-block', borderRadius: '50%', marginRight: '6px' }} />{weihuInfo.whstatus}
                  </Text>
                  : null}
              </p>
            </Space>
          </Space>
        </div>
      </>
    )
  }
}

/**
 * 
 * 中间层 switch 组件
 * 
 */
function SwitchStep(props: any) {
  switch (props.stepsCurrent) {
    case 0:
      return <StepOne projectid={props.projectid} initLoading={props.initLoading} list={props.list} nextBtn1={props.nextBtn1} />
    case 1:
      return <StepTwo nextBtn2={props.nextBtn2} projectid={props.projectid} />
    case 2:
      return <StepThree list={props.list}
        nextBtn3={props.nextBtn3}
        projectid={props.projectid} />;
    case 3:
      return <StepFour projectid={props.projectid} />
    default:
      return null;
  }
}

/**
 * 
 * 主组件
 * 
 */
class StepContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      stepsCurrent: 0,
      stepDisabled: false,
      initLoading: false,
      list: [1, 2, 3, 4],
      status1: '',
      status2: '',
      status3: '',
      status4: ''
    }
  }

  // 生命周期 发生在 render 函数之后 已经挂载 Dom
  componentDidMount() {
    this.updateJiduan()
  }

  // 根据阶段字段显示相应阶段
  updateJiduan = () => {
    let details: any = sessionStorage.getItem('data')  // 当state没有参数时，取sessionStorage中的参数
    let detailsJieduan: any = JSON.parse(details).jieduan
    switch (detailsJieduan) {
      case '研发阶段':
        this.setState({ stepsCurrent: 1, stepDisabled: false })
        break;
      case '待交付阶段':
        this.setState({ stepsCurrent: 2, stepDisabled: false })
        break;
      case '已交付&维护阶段':
        this.setState({ stepsCurrent: 3, stepDisabled: true })
        break;
      default:
        this.setState({
          stepsCurrent: 0,
          stepDisabled: false,
          status1: 'process',
          status2: 'wait',
          status3: 'wait',
          status4: 'wait'
        })
        break;
    }
  }

  onStepsChange = (stepsCurrent: number) => {
    this.setState({ stepsCurrent })
  }

  // 获取详情信息并更新的方法
  fnGetPro = () => {
    return pro({ projectid: this.props.projectid }).then((res: any) => {
      console.log('获取的详情信息-------------------->', res)
      // 更新项目信息
      sessionStorage.setItem('data', JSON.stringify(res.project))
    })
  }

  // 阶段1 下一步
  nextBtn1 = () => {
    let that = this
    confirm({
      title: '是否进入下一阶段?',
      okText: '确认',
      cancelText: '取消',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        // 请求修改阶段接口
        upJieduan({ jieduan: '研发阶段', projectid: that.props.projectid }).then(async res => {
          that.setState({
            status1: 'finish',
            status2: 'process',
            status3: 'wait',
            status4: 'wait'
          })
          // 更新项目信息
          await that.fnGetPro()
          // 刷新显示的阶段内容
          that.updateJiduan()
        })
      }
    })
  }
  // 阶段2 下一步
  nextBtn2 = () => {
    let that = this
    confirm({
      title: '是否进入下一阶段?',
      okText: '确认',
      cancelText: '取消',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        // 请求修改阶段接口
        upJieduan({ jieduan: '待交付阶段', projectid: that.props.projectid }).then(async res => {
          that.setState({
            status1: 'finish',
            status2: 'finish',
            status3: 'process',
            status4: 'wait'
          })
          // 更新项目信息
          await that.fnGetPro()
          // 刷新显示的阶段内容
          that.updateJiduan()
        })
      }
    })
  }
  // 阶段3 下一步
  nextBtn3 = () => {
    let that = this
    confirm({
      title: '是否进入下一阶段?',
      okText: '确认',
      cancelText: '取消',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        // 请求修改阶段接口
        upJieduan({ jieduan: '已交付&维护阶段', projectid: that.props.projectid }).then(async res => {
          that.setState({
            status1: 'finish',
            status2: 'finish',
            status3: 'finish',
            status4: 'finish'
          })
          // 更新项目信息
          await that.fnGetPro()
          // 刷新显示的阶段内容
          that.updateJiduan()
        })
      }
    })
  }

  render() {
    return (
      <div style={{ borderRadius: '2px', backgroundColor: '#ffffff', marginTop: '30px' }}>
        {/* 步骤条 */}
        <Steps
          type="navigation"
          size="small"
          style={{ borderBottom: '1px solid #f4f4f4', paddingTop: '16px' }}
          current={this.state.stepsCurrent}
          onChange={this.onStepsChange}
        >
          <Step disabled={this.state.stepsCurrent === 0 || this.state.stepDisabled ? false : true} status={this.state.status1} title="商务与产品阶段" />
          <Step disabled={this.state.stepsCurrent === 1 || this.state.stepDisabled ? false : true} status={this.state.status2} title="研发阶段" />
          <Step disabled={this.state.stepsCurrent === 2 || this.state.stepDisabled ? false : true} status={this.state.status3} title="待交付阶段" />
          <Step disabled={this.state.stepsCurrent === 3 || this.state.stepDisabled ? false : true} status={this.state.status4} title="已交付&维护阶段" />
        </Steps>
        {/* 内容 */}
        <div style={{ padding: '27px' }}>
          <SwitchStep
            list={this.state.list}
            projectid={this.props.projectid}
            stepsCurrent={this.state.stepsCurrent}
            // 阶段1
            nextBtn1={this.nextBtn1}
            initLoading={this.state.initLoading}
            // 阶段2
            nextBtn2={this.nextBtn2}
            // 阶段3
            nextBtn3={this.nextBtn3}
          />
        </div>
      </div>
    )
  }
}

export default StepContent