import React, { Component } from 'react';
import {Modal,Form,Row,Col,Input,DatePicker} from 'antd';
import moment from 'moment';
const {TextArea} = Input;
export default class ModModal extends Component<any> {
  render() {
    const {
      modVisitRecorModalVisiable,
      handleModModal,
      currentVisitRecord,
      modVisitRecorFormRef,
      modVisitRecorFormFinish} = this.props
    return (
      <div>
        <Modal
          title='新增拜访记录'
          visible={modVisitRecorModalVisiable}
          onOk={()=>handleModModal('dosave')}
          onCancel={()=>handleModModal('cancle')}
        >
          <Form ref={modVisitRecorFormRef} onFinish={modVisitRecorFormFinish} layout="vertical">
            <Row gutter={50}>
              <Col span={12}>
                <Form.Item label="客户名称" name='customerName' initialValue={currentVisitRecord.customerName}  rules={[{required:true,message:'请输入客户名称'}]}>
                  <Input placeholder="请输入"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="联系日期" name='visitDate' initialValue={moment(new Date(currentVisitRecord.visitDate))} rules={[{required:true,message:'请选择联系日期'}]}>
                <DatePicker style={{width:'100%'}}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="客户联系人" name='contactPeople' initialValue={currentVisitRecord.contactPeople} rules={[{required:true,message:'请输入客户联系人'}]}>
                  <Input placeholder="请输入"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="联系方式" name='contactWay' initialValue={currentVisitRecord.contactWay} rules={[{required:true,message:'请输入联系方式'}]}>
                  <Input placeholder="请输入"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="客户经理" name='customerManager' initialValue={currentVisitRecord.customerManager} rules={[{required:true,message:'请输入客户经理'}]}>
                  <Input placeholder="请输入"></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="联系事由" name='contactReason' initialValue={currentVisitRecord.contactReason} rules={[{required:true,message:'请输入联系事由'}]}>
                  <TextArea rows={3} placeholder="请输入"></TextArea>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}
