import React from "react"
import { Space, Button, Row, Col, Input, Table, Modal, Form, message, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import './Users.less'
import VerifyCode from '../../components/VerifyCode'
import { userList, userDelete, userAdd, userUpdate, passwordUpdate, userSearch } from '@/request/usersapi'
const { Search, TextArea } = Input
const { Column } = Table
const { confirm } = Modal
const { Title, Text } = Typography

/* 新增 编辑 弹窗组件 */
function UserModal(props: any) {
  const confirmPasswordValidator = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback('请输入密码!')
    } else if (value !== props.formRef.current.getFieldValue('password')) {
      callback('两次密码不一致!')
    } else {
      callback()
    }
  }
  return (
    <Modal destroyOnClose={true} title={props.modalType === 'add' ? '新增' : '编辑'} visible={props.visible} onOk={props.onOk} onCancel={props.onCancel} okText="确认" cancelText="取消">
      <Form initialValues={props.initialValues} className="user-modal-form" layout='vertical' ref={props.formRef}>
        <Row justify='space-between'>
          <Col span={11}>
            <Form.Item name="name" label="用户姓名" rules={[{ required: true, message: '请输入用户姓名!' }]}>
              <Input placeholder="请输入用户姓名" />
            </Form.Item>
            <Form.Item name="id" label="用户ID" rules={[{ required: true, message: '请输入用户ID!' }]}>
              <Input placeholder="请输入用户ID" />
            </Form.Item>
            {
              props.modalType === 'add' ?
                <Form.Item name="password" label="密码" rules={[{ required: true, message: '请输入密码!' }]}>
                  <Input.Password placeholder="请输入密码" />
                </Form.Item> : <></>
            }
          </Col>
          <Col span={11}>
            <Form.Item name="phone" label="手机号" rules={[{ required: true, message: '请输入手机号!' }, { pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的手机号!' }]}>
              <Input placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item name="position" label="用户职位" rules={[{ required: true, message: '请输入用户职位!' }]}>
              <Input placeholder="请输入用户职位" />
            </Form.Item>
            {
              props.modalType === 'add' ?
                <Form.Item name="confirmPassword" label="确认密码" rules={[{ validator: confirmPasswordValidator, required: true }]}>
                  <Input.Password placeholder="请确认密码" />
                </Form.Item> : <></>
            }
          </Col>
        </Row>
        <Form.Item className="textArea" name="remarks" label="备注" rules={[{ required: true, message: '请填写备注!' }]}>
          <TextArea
            placeholder="请填写备注"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

/* 修改密码 弹窗组件 */
class EditPassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      verifyCode: ''
    }
  }

  componentDidMount() {
    this.generateVerifyCode()
  }

  generateVerifyCode = () => {
    const result = [];
    for (let i = 0; i < 4; i++) {
      const ranNum = Math.ceil(Math.random() * 25); // 生成一个0到25的数字
      // 大写字母'A'的ASCII是65,A~Z的ASCII码就是65 + 0~25;然后调用String.fromCharCode()传入ASCII值返回相应的字符并push进数组里
      result.push(String.fromCharCode(65 + ranNum));
    }
    this.setState({
      verifyCode: result.join('')
    })
  }

  render() {
    const { visibleEditPassword, onOkEditPassword, onCancelEditPassword, formRefEditPassword } = this.props
    const confirmPasswordValidator2 = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback('请输入密码!')
      } else if (value !== formRefEditPassword.current.getFieldValue('newPassword')) {
        callback('两次密码不一致!')
      } else {
        callback()
      }
    }
    // 验证码自定义验证规则
    const captcha = (rule: any, value: any, callback: any) => {
      if (!value) {
        callback('请输入验证码!')
      } else if (this.state.verifyCode !== value) {
        callback('验证码有误!')
      } else {
        callback()
      }
    }
    return (
      <Modal destroyOnClose={true} visible={visibleEditPassword} title="修改密码" okText="修改" cancelText="取消" onOk={onOkEditPassword} onCancel={onCancelEditPassword} >
        <Form ref={formRefEditPassword} layout='vertical' labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}>
          <Form.Item className="formItem" name="password" label="原密码" rules={[{ required: true, message: '请输入原密码!' }]}>
            <Input placeholder="请输入原密码" />
          </Form.Item>
          <Form.Item className="formItem" name="newPassword" label="新密码" rules={[{ required: true, message: '请输入新密码!' }]}>
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>
          <Form.Item className="formItem" name="confirmPassword" label="确认密码" rules={[{ required: true, validator: confirmPasswordValidator2 }]}>
            <Input.Password placeholder="请确认新密码" />
          </Form.Item>
          <Form.Item className="formCaptcha" name="captcha" label="验证码" rules={[
            { required: true, validator: captcha }]}>
            <Row gutter={5}>
              <Col span={12}>
                <Input
                  placeholder="请输入验证码"
                />
              </Col>
              <Col span={1}>
                <VerifyCode onClick={this.generateVerifyCode} identifyCode={this.state.verifyCode} />
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

interface IState {
  visible: boolean;
  data: object[];
  modalType: string;
  visibleEditPassword: boolean;
  initialValues: object;
  userTableId: string;
  total: number;
  isSearch: boolean;
  searchValue: string;
  current: number;
  tableLoading: boolean;
}

class Users extends React.Component<any, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      visible: false,
      visibleEditPassword: false,
      modalType: '',
      data: [],
      initialValues: {},
      userTableId: '',
      total: 0,
      isSearch: false,
      searchValue: '',
      current: 1,
      tableLoading: false
    }
  }

  componentDidMount() {
    console.log('render前')
    this.setState({
      tableLoading: true
    })
    this.getUserList(10, 1)
  }

  // 查询列表方法
  getUserList = (pageSize: number, pageNum: number) => {
    userList({ pageSize, pageNum }).then((res: any) => {
      console.log(res)
      if (res && res.code === '200') {
        this.setState({
          data: res.data,
          total: res.total,
          tableLoading: false
        })
      }
    })
  }
  // 查询用户方法
  getUser = (str: string, pageSize: number, pageNum: number) => {
    userSearch({ str, pageSize, pageNum }).then((res: any) => {
      if (res && res.code === '200') {
        this.setState({
          data: res.data,
          total: res.total,
          tableLoading: false
        })
      }
    })
  }

  formRef: any = React.createRef()
  formRefEditPassword: any = React.createRef()

  // 搜索
  onSearch = (value: string) => {
    this.setState({
      tableLoading: true
    })
    // 判断输入框是否有值 有 搜索列表 否 默认列表
    if (value) {
      this.setState({
        isSearch: true
      })
      this.getUser(value, 10, 1)
    } else {
      this.setState({
        isSearch: false
      })
      this.getUserList(10, 1)
    }
    this.setState({
      searchValue: value,
      current: 1,
    })
  }
  // 新增
  addBtn = () => {
    this.setState({
      visible: true,
      modalType: 'add',
      initialValues: {}
    })
  }
  // 编辑
  editBtn = (record: any) => {
    this.setState({
      visible: true,
      modalType: 'edit',
      initialValues: {
        name: record.userName,
        id: record.userId,
        phone: record.userPhone,
        position: record.userPosition,
        remarks: record.userNote
      },
      userTableId: record.userTableId
    })
  }
  // 修改密码
  editPasswordBtn = (userTableId: string) => {
    this.setState({
      visibleEditPassword: true,
      userTableId
    })
  }
  // 新增 编辑 弹窗确认
  onOk = () => {
    this.formRef.current.validateFields().then((res: any) => {
      this.setState({
        tableLoading: true,
        current: 1
      })
      if (this.state.modalType === 'add') {  // 新增
        // 请求需要的数据
        const data = {
          userId: res.id,
          userName: res.name,
          userPhone: res.phone,
          userPosition: res.position,
          userPassword: res.password,
          userNote: res.remarks
        }
        // 添加用户
        userAdd(data).then((res: any) => {
          if (res && res.code === '200') {
            // 刷新列表
            this.getUserList(10, 1)
            // 成功提示
            message.success('创建成功')
            // 关闭弹窗
            this.setState({
              visible: false
            })
          }
        })
      } else if (this.state.modalType === 'edit') {  // 编辑
        // 请求需要的数据
        const data = {
          userTableId: this.state.userTableId,
          userId: res.id,
          userName: res.name,
          userPhone: res.phone,
          userPosition: res.position,
          userNote: res.remarks
        }
        // 发送请求
        userUpdate(data).then((res: any) => {
          if (res && res.code === '200') {
            // 刷新列表
            this.getUserList(10, 1)
            // 成功提示
            message.success('修改成功')
            // 关闭弹窗
            this.setState({
              visible: false
            })
          }
        })
      }
    })
  }
  // 新增 编辑 弹窗取消
  onCancel = () => {
    this.setState({
      visible: false
    })
  }
  // 修改密码 弹窗确认
  onOkEditPassword = () => {
    // 验证表单
    this.formRefEditPassword.current.validateFields().then((res: any) => {
      this.setState({
        tableLoading: true,
        current: 1
      })
      // 请求修改密码接口
      passwordUpdate({
        userTableId: this.state.userTableId,
        userPassword: res.password,
        newPassword: res.newPassword
      }).then((res: any) => {
        if (res && res.code === '200') {
          // 成功提示
          message.success('修改成功')
          // 刷新列表
          this.getUserList(10, 1)
          // 关闭弹窗
          this.setState({
            visibleEditPassword: false
          })
        }
      })
    })
  }
  // 修改密码 弹窗取消
  onCancelEditPassword = () => {
    this.setState({
      visibleEditPassword: false
    })
  }
  // 删除
  delBtn = (userTableId: string) => {
    let that = this
    confirm({
      title: '确认要删除本条数据吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      centered: true,
      onOk() {
        that.setState({
          tableLoading: true
        })
        userDelete({ userTableId }).then((res: any) => {
          if (res && res.code === '200') {
            that.setState({
              current: 1
            })
            // 成功提示
            message.success('删除成功')
            // 刷新列表
            that.getUserList(10, 1)
          }
        })
      }
    })
  }
  // 分页回调
  onChangePagination = (page: number, pageSize: number) => {
    this.setState({
      current: page,
      tableLoading: true
    })
    if (this.state.isSearch) {  // 展示搜索列表
      this.getUser(this.state.searchValue, pageSize, page)
    } else {  // 展示默认列表
      this.getUserList(pageSize, page)
    }
  }

  render() {
    // 分页
    const pagination: object = {
      position: ['bottomCenter'],
      total: this.state.total,
      current: this.state.current,
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 50],
      onChange: this.onChangePagination
    }

    return (
      <>
        <Space direction='vertical' size={15} className="users-space">
          <Space direction='vertical' size={15} style={{ paddingLeft: 7 }}>
            <Title level={5} style={{ marginBottom: 0, fontSize: 20 }}>用户管理</Title>
            <div style={{ fontSize: 14 }}>这里展示所有的用户</div>
          </Space>
          {/* 内容 */}
          <div className="table-content">
            <Row justify='end'>
              <Space>
                <Search placeholder="请输入用户名" onSearch={this.onSearch} enterButton />
                <Button type='primary' onClick={this.addBtn}>新增用户</Button>
              </Space>
            </Row>
            <br />
            {/* 表格 */}
            <Table loading={this.state.tableLoading} dataSource={this.state.data} pagination={pagination} rowKey="userId">
              <Column align="center" title={<Text strong>用户姓名</Text>} dataIndex="userName" />
              <Column align="center" title={<Text strong>用户ID</Text>} dataIndex="userId" />
              <Column align="center" title={<Text strong>手机号</Text>} dataIndex="userPhone" />
              <Column align="center" title={<Text strong>用户职位</Text>} dataIndex="userPosition" />
              <Column align="center" title={<Text strong>备注</Text>} dataIndex="userNote" />
              <Column align="center" title={<Text strong>操作</Text>} render={(text: any, record: any, index: any) => {
                return (
                  <>
                    <Button type='link' onClick={() => this.editBtn(record)}>编辑</Button>
                    <Button type='link' onClick={() => this.editPasswordBtn(record.userTableId)}>修改密码</Button>
                    <Button type="link" danger onClick={() => this.delBtn(record.userTableId)}>删除</Button>
                  </>
                )
              }} />
            </Table>
            {/* 新增 编辑 弹窗 */}
            <UserModal initialValues={this.state.initialValues} modalType={this.state.modalType} visible={this.state.visible} onOk={this.onOk} onCancel={this.onCancel} formRef={this.formRef} />
            {/* 修改密码 弹窗 */}
            <EditPassword formRefEditPassword={this.formRefEditPassword} visibleEditPassword={this.state.visibleEditPassword} onOkEditPassword={this.onOkEditPassword} onCancelEditPassword={this.onCancelEditPassword} />
          </div>
        </Space>
      </>
    )
  }
}

export default Users