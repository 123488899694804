import { checkedAndGetProjectUrl, checkedAndUpload } from "./minio"

/**
 * 生成指定区间的随机整数
 * @param {Number} min 最小数
 * @param {Number} max 最大数
 * @return {Number}
 */
export const randomNumber = (min: number, max: number): number => Math.floor(min + Math.random() * (max - min + 1))

/*
 生成验证码
*/
export const createCaptcha = (canvas: any): string => {
	// i，o和1，0比较相似，不好区分，所以不出现这两个字母
	const chars = [
		1,
		2,
		3,
		4,
		5,
		6,
		7,
		8,
		9,
		'a',
		'b',
		'c',
		'd',
		'e',
		'f',
		'g',
		'h',
		'j',
		'k',
		'l',
		'm',
		'n',
		'p',
		'q',
		'r',
		's',
		't',
		'u',
		'v',
		'w',
		'x',
		'y',
		'z',
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'J',
		'K',
		'L',
		'M',
		'N',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z'
	]
	let captcha = ''
	const ctx = canvas.getContext('2d')
	ctx.clearRect(0, 0, 80, 39)
	for (let i = 0; i < 4; i++) {
		const char = chars[randomNumber(0, 56)]
		captcha += char
		// 设置字体随机大小
		ctx.font = randomNumber(20, 25) + 'px SimHei'
		// 文字颜色
		ctx.fillStyle = '#D3D7F7'
		ctx.textBaseline = 'middle'
		// 文字边缘阴影，制造模糊效果
		ctx.shadowOffsetX = randomNumber(-3, 3)
		ctx.shadowOffsetY = randomNumber(-3, 3)
		ctx.shadowBlur = randomNumber(-3, 3)
		ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
		const x = (80 / 5) * (i + 1)
		const y = 39 / 2
		const deg = randomNumber(-25, 25)
		// 设置旋转角度和坐标原点
		ctx.translate(x, y)
		ctx.rotate((deg * Math.PI) / 180)
		ctx.fillText(char, 0, 0)
		// 恢复旋转角度和坐标原点
		ctx.rotate((-deg * Math.PI) / 180)
		ctx.translate(-x, -y)
	}
	return captcha
}

// 获取当前时间 转yy-mm-dd
export const dateToYmd = () => {
	var now = new Date();
	var year = now.getFullYear(); //得到年份
	var month: any = now.getMonth();//得到月份
	var date: any = now.getDate();//得到日期
	month = month + 1;
	if (month < 10) month = "0" + month;
	if (date < 10) date = "0" + date;
	var time = "";
	time = year + "-" + month + "-" + date
	return time
}
//产生随机数
export function getRandom() {
  const dateNo = new Date().getTime();
  const randNo = Math.round(Math.random()*10000);
  return ''+dateNo+randNo;
}

//计算天数
export function calcRemainTime(expectionTime:any){
	let expTime = new Date(expectionTime).getTime();
	let nowTime = new Date().getTime();
	let remainTime = Math.floor((expTime-nowTime)/1000/3600/24)
	console.log(remainTime)
	if(remainTime<-1){
		return '已超时';
	}else if(remainTime===-1){
		return '今天截至';
	}else{
		return `${remainTime}天`;
	}
}

// 保存token
export const saveAccessToken = (token: string) => {
  localStorage.setItem('token', token)
};

// 获取token
export const getAccessToken = () => {
  return localStorage.getItem('token')
};

// 移除token
export const removeAccessToken = () => {
  localStorage.removeItem('token')
}


/**
 * 
 * @param file 需要重命名的文件
 * @returns 重新命名的文件
 */
//对使用uuid对文件重命名
export function getUUIDFile(file:any) {
	var d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
			d += performance.now(); // 高精度的时间计数器
	}
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c === 'x' ? r : (r & 0x3 || 0x8)).toString(16);
	});
	const newFileName = uuid+file.name
	return new File([file], newFileName, {type: file.type });
}

/**
 * 
 * @param fileList 需要上传的文件列表
 * @returns 返回处理结果 reject 失败返回处理结果error ,resolve 上传成功的返会处理结果success以及文件名列表。
 */
export const uploadFiles= (fileList:any)=>{
	return new Promise((resolve,reject)=>{
		let result = 'init';
		let fileNames:any = [];
		const info = {file:getUUIDFile(fileList[0])}
		checkedAndUpload('file',info,(flag:any)=>{
			if (flag==='error') {
				result = 'error';
				reject(result)
			}else{
				fileNames.push(info.file.name);
				result = 'success';
				resolve({result,fileNames});
			}
		})
		// for(let i = 0;i<fileList.length;i++){	
		// 	let xresult = 'result'
		// 	const info = {file:getUUIDFile(fileList[i])}
		// 	checkedAndUpload('file',info,(flag:any)=>{
		// 		if (flag==='error') {
		// 			xresult = 'error';
		// 			return;
		// 		}else{
		// 			fileNames.push(info.file.name);
		// 			if(i===fileList.length-1){
		// 				result = 'success';
		// 				resolve({result,fileNames});
		// 			}
		// 		}
		// 	})
		// }
	})
}
/**
 * 
 * @param files 需要下载的文件名列表
 * @returns 处理结果
 */
//文件下载 success 请求成功 error 请求链接错误,或者文件名不正确,文件不存在。
export function downloadFiles(files:any){
	let result = 'success';
	checkedAndGetProjectUrl(files[0],(fileUrl:any)=>{
		if(fileUrl!==null){
			window.location.href=fileUrl;
		}else{
			result = 'error'
		}
	})
	// for(let i = 0;i< files.length;i++){
	// 	checkedAndGetProjectUrl(files[i],(fileUrl:any)=>{
	// 		if(fileUrl!==null){
	// 			window.location.href=fileUrl;
	// 		}else{
	// 			result = 'error'
	// 		}
	// 	})
	// }
	return result;
}