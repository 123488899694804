import React, { Component } from 'react'
import { Input, Button, Modal, Form, Select, Upload,Dropdown,Typography ,Badge, Space,message,List, Menu} from 'antd';
import {EllipsisOutlined,UploadOutlined} from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form';
import './index.css'
import { addOrder, delOrder, getOrderList } from '@/request/workorderapi';
import { uploadFiles } from '@/utils/utils';
import { projectList } from '@/request/deliverapi';
import { custList } from '@/request/customerapi';
const { Title} = Typography;
const { Search, TextArea } = Input;
const { Option } = Select;
const userId = 'asgsdgsdgsd';
function getNewOrderNo() {
  const dateNo = new Date().getTime();
  const randNo = Math.round(Math.random()*10000);
  return ''+dateNo+randNo;
}
interface isState {
  addWorkorderModalVisible: boolean,
  delWorkorderModalVisible: boolean,
  newOrderNo: string,
  currentPage: number,
  pageSize: number,
  loading:boolean ,
  dataTotal: number,
  currentOrder:any
  data: any,
  total: number,
  searchKey: any,
  projects:any,
  projectsPage:number,
  projectsPageSize:number,
  projectsTotal:number,
  clients:any,
  clientsPage:number,
  clientsPageSize:number,
  clientsTotal:number,
  fileList:any
}
export default class WorkOrderList extends Component<any, isState> {
  constructor(props: any) {
    super(props);
    this.state = {
      addWorkorderModalVisible: false,
      delWorkorderModalVisible: false,
      newOrderNo:'',
      currentOrder:{},
      currentPage:1,
      pageSize:6,
      loading:false,
      data:[],
      total:0,
      dataTotal:50,
      searchKey:'',
      projects:[],
      projectsPage:1,
      projectsPageSize:10,
      projectsTotal:0,
      clients:[],
      clientsPage:1,
      clientsPageSize:10,
      clientsTotal:0,
      fileList:[]
    }
  }
  //#region 函数
  componentDidMount(){
    const {currentPage,pageSize} = this.state;
    this.getWordOrders(currentPage,pageSize,null);
  }
  getWordOrders=(currentPage: number,pageSize: number,searchKey: any)=>{
    let data = {
      "limit": pageSize,
      "page": currentPage,
      "userid": userId,
      "projectname":searchKey
    }
    getOrderList(data).then(
      response=>{
        if(response.data.code===200){
          if(currentPage===1){
            this.setState({data:response.data.orders,total:response.data.total,loading:false})
          }else{
            this.setState(state=>({data:[...state.data,...response.data.orders],total:response.data.total,loading:false}));
          }
        }else{
          message.error('请求错误');
        }
      }
    )
  }
  refreshData= ()=>{
    const {pageSize} = this.state;
    this.getWordOrders(1,pageSize,null);
  }
  onSearch = (value: any) => {
    this.setState({currentPage:1,searchKey:value})
    const {pageSize} = this.state;
    this.getWordOrders(1,pageSize,value);
  }
  showModal = (modal: string) => {
    switch (modal) {
      case 'addorder': {
        const {projectsPageSize,clientsPageSize} = this.state;
        this.getProjects(1,projectsPageSize);
        this.getClients(1,clientsPageSize);
        this.setState({ addWorkorderModalVisible: true,newOrderNo:getNewOrderNo() });
        break;
      }
      case 'delorder':{
        this.setState({delWorkorderModalVisible: true})
        break;
      }
      default:
        break;
    }
  }
  addModalHandle = (option: number) => {
    if (option === 0) {
      this.addOrderFormRef.current?.resetFields();
      this.setState({ addWorkorderModalVisible: false ,fileList:[]})
    } else {
      this.addOrderFormRef.current?.submit();
    }
  }
  skipDetail = (currentOrder: any)=>{
    localStorage.setItem('orderid',currentOrder.orderid);
    this.props.history.push('/workorder/detail');
  }
  delModalHandle = (option: number)=>{
    if(option===0){
      this.setState({delWorkorderModalVisible:false})
    }else{
      const {currentOrder} = this.state;
      const data = {
        orderid:currentOrder.orderid
      }
      delOrder(data).then(
        response=>{
          if(response.data.code===200){
            message.success('删除工单成功');
            this.refreshData();
          }else{
            message.error('删除工单失败');
          }
        }
      )
      this.setState({delWorkorderModalVisible:false})
    }
  }
  loadMore = ()=>{
    const {currentPage,pageSize,searchKey} =this.state;
    this.setState({currentPage:currentPage+1});
    this.getWordOrders(currentPage+1,pageSize,searchKey);
  }
  // 获取项目列表 
  getProjects= (currentPage:number,pageSize:number)=>{
    const data = {
      "pageNum":currentPage,
      "pageSize":pageSize,
    }
    projectList(data).then(
      (result:any)=>{
        if(result.code==='200'){
          this.setState(state=>({
            projects:[...state.projects,...result.data],
            projectsTotal:result.total
          }))
        }else{
          message.error('请求异常');
        }
      }
    )
  }
  // 分页加载项目列表
  loadingProjects = (e:any)=>{
    if(e.target.scrollTop + e.target.offsetHeight===e.target.scrollHeight){
      const{projects,projectsPage,projectsPageSize,projectsTotal} = this.state;
      if(projects.length<projectsTotal){
        setTimeout(()=>{
          this.setState({projectsPage:projectsPage+1});
          this.getProjects(projectsPage+1,projectsPageSize);
        },600)
      }
    }
  }
  // 获取客户列表 
  getClients= (currentPage:number,pageSize:number)=>{
    const data = {
      "userId":'160675131818659840',
      "pageNum":currentPage,
      "pageSize":pageSize,
    }
    custList(data).then(
      response=>{
        if(response.data.code==='200'){
          this.setState(state=>({
            clients:[...state.clients,...response.data.data],
            clientsTotal:response.data.total
          }))
        }else{
          message.error('请求异常');
        }
      }
    )
  }
  // 分页加载项目列表
  loadingClients = (e:any)=>{
    if(e.target.scrollTop + e.target.offsetHeight===e.target.scrollHeight){
      const{clients,clientsPage,clientsPageSize,clientsTotal} = this.state;
      if(clients.length<clientsTotal){
        setTimeout(()=>{
          this.setState({clientsPage:clientsPage+1});
          this.getClients(clientsPage+1,clientsPageSize);
        },600)
      }
    }
  }
  beforeUpload = (file:any)=>{
    const fileType = ['application/zip','application/rar','image/jpeg','image/jpg','image/png','text/plain',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    if(file.size>(1024*1024*50)){
      message.warning(file.name+'文件过大,请重新选择');
    }else if(fileType.indexOf(file.type)>-1){
      this.setState(state => ({
        fileList: [file]
      }))
    }else{
      message.warning('文件类型不正确，请重新选择');
    }
    return false;
  }
  removeFile = (file:any)=>{
    this.setState(state=>{
      const index = state.fileList.indexOf(file);
      let newFileList = state.fileList.slice();
      newFileList.splice(index,1);
      return {fileList:newFileList}
    })
  }
  setCurrentOrder=(currentOrder:any)=>{
    this.setState({currentOrder})
  }
  addOrderFormRef = React.createRef<FormInstance>();
  onFinish = (values: any) => {
    const {fileList} = this.state;
    let files:any = [];
    let data = {
      "userid": userId,
      "theme": values.theme,
      "orderid": values.orderid,
      "projectid": values.project.value,
      "projectname": values.project.label,
      "clientid": values.client.value,
      "clientname": values.client.label,
      "description": values.description,
      "file": files
    }
    if(fileList!==undefined&&fileList.length>0){
      uploadFiles(fileList).then(
        (resolve:any)=>{
          files=resolve.fileNames;
          data.file = files;
          addOrder(data).then(
            response=>{
              if(response.data.code===200){
                message.success('新增工单信息成功');
                this.refreshData();
              }else{
                message.error('新增工单失败')
              }
            }
          )
        },reject=>{
          message.error('请求异常');
        }
      )
      this.addOrderFormRef.current?.resetFields();
      this.setState({ addWorkorderModalVisible: false,fileList:[]});
    }else{
      addOrder(data).then(
        response=>{
          if(response.data.code===200){
            message.success('新增工单信息成功');
            this.refreshData();
            this.addOrderFormRef.current?.resetFields();
            this.setState({ addWorkorderModalVisible: false});
          }else{
            message.error('新增工单失败')
          }
        }
      )
    }
  };
  //#endregion
  render() {
    const { addWorkorderModalVisible,delWorkorderModalVisible ,newOrderNo,data,total,projects,clients,loading,fileList} = this.state;
    const listOtherMenu=(
      <Menu style={{width:60,textAlign:'center'}}>
        <Menu.Item onClick={()=>this.showModal('delorder')} key="0" style={{ color: '#ff0000' }}>
          删除
        </Menu.Item>
      </Menu>
    )
    const loadMore =
    (data.length<total && !loading) ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={this.loadMore}>加载更多</Button>
        </div>
      ) : null;
    return (
      <div>
        <Space direction='vertical' size={15}>
          <Space direction='vertical' size={15} style={{paddingLeft:7}}>
            <Title level={5} style={{marginBottom:0,fontSize:20}} >工单管理</Title>
            <div style={{fontSize:14}}>这里展示所有的工单</div>
          </Space>
        </Space>
        <div className="work-order">
          <div className="top">
            <div className="left">
              <span className="title">全部工单</span>
              <span className="total">{total}</span>
            </div>
            <div className="right">
              <span className="search">
                <Search placeholder="搜索工单" allowClear onSearch={this.onSearch} style={{ width: 192,height:32,marginTop:17 }} />
              </span>
              <span>
                <Button type="primary" style={{width:88, height: 32,marginLeft:24,borderRadius:2}} onClick={() => this.showModal('addorder')}>新增工单</Button>
              </span>
            </div>
          </div>
          <div className="workorder-content">
          <List
              style={{width:'100%',paddingBottom:10}}
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={data}
              loading={loading}
              loadMore={loadMore}
              renderItem={(item:any) => (
                <List.Item
                  actions={[
                  <span key="list-loadmore-edit" onClick={()=>this.skipDetail(item)} style={{color:'rgb(89, 168, 255)',cursor:'pointer'}}>详情</span>,
                  <Dropdown overlay={listOtherMenu} placement="bottomRight" arrow>
                    <span style={{ color: '#1890ff', cursor: 'pointer' }} onMouseOver={()=>this.setCurrentOrder(item)}><EllipsisOutlined /></span>
                  </Dropdown>]}>
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>工单编号</span>}
                      description={<span style={{color:'black'}}>{item.orderid}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',color:'red'}}
                    />
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>主题-{item.theme}</span>}
                      description={<span style={{color:'black'}}>{item.projectname}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}
                    />
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>客户公司</span>}
                      description={<span style={{color:'black'}}>{item.clientname}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}
                    />
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>创建日期</span>}
                      description={<span style={{color:'black'}}>{item.createtime}</span>}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}
                    />
                    <List.Item.Meta
                      title={<span style={{color:'rgb(140, 140, 140)'}}>工单状态</span>}
                      description={<Badge status={item.status==='关闭'?'error':'success'} text={item.status} />}
                      style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textAlign:"center"}}
                    />
                </List.Item>
              )}
            />

          </div>
          <div className="modal">
            {addWorkorderModalVisible&&<Modal
              visible={addWorkorderModalVisible}
              title="新增工单"
              onCancel={() => this.addModalHandle(0)}
              style={{ overflow: 'hidden', minWidth: '600px' }}
              onOk={()=>this.addModalHandle(1)}
              okText="确定" cancelText="取消"
            >
              <Form ref={this.addOrderFormRef} layout={'vertical'} name="addOrderForm" onFinish={this.onFinish}>
                <Form.Item name="theme" label="主题" rules={[{ required: true ,message:'请输入主题！'}]}>
                  <Input placeholder="请输入"/>
                </Form.Item>
                <Form.Item initialValue={newOrderNo} name="orderid" label="工单编号" rules={[{ required: true}]}>
                  <Input  readOnly={true}/>
                </Form.Item>
                <Form.Item name="project" label="项目" rules={[{ required: true ,message:'请选择项目！'}]}>
                  <Select style={{ width: 300 }} onPopupScroll={this.loadingProjects} labelInValue placeholder="请选择">
                  {
                    projects.map((item:any)=>{
                      return <Option key={item.projectId} value={item.projectId}>{item.projectName}</Option>
                    })
                  }
                  </Select>
                </Form.Item>
                <Form.Item name="client" label="客户公司" rules={[{ required: true ,message:'请选择客户公司！'}]}>
                  <Select style={{ width: 300,}} onPopupScroll={this.loadingClients} labelInValue placeholder="请选择">
                    {
                      clients.map((item:any)=>{
                        return <Option key={item.customerId} value={item.customerId}>{item.customerName}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item name="description" label="问题描述" rules={[{ required: false }]}>
                  <TextArea rows={3} />
                </Form.Item>
                <Form.Item name="file" label="上传材料" rules={[{ required: false }]}>
                  <Upload  beforeUpload={this.beforeUpload} onRemove={this.removeFile} fileList={fileList} multiple={false}>
                    <Button icon={<UploadOutlined/>} style={{width:'100px',borderRadius:'5px'}}>附件</Button><br/>
                    <span style={{color:'#A9A9A9',fontSize:13}}>支持.png、.jpg、.jpeg、.txt、.zip、.rar、.docx格式,只能上传单个文件,最大不超过50M</span>
                  </Upload>
                </Form.Item>
              </Form>
            </Modal>}
            <Modal
              visible={delWorkorderModalVisible}
              title="系统提示"
              onOk={()=>this.delModalHandle(1)}
              onCancel={() => this.delModalHandle(0)}
              okText="确认" cancelText="取消"
            >你确定要删除该工单吗？</Modal>
          </div>
        </div>
      </div>
    )
  }
}
