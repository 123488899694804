import { Form, Input, Button, message } from 'antd'
import { login } from '@/request/accountapi'
import { Redirect } from 'react-router-dom'
import './Login.less'
import { saveAccessToken, getAccessToken } from '@/utils/utils'

const Login = (props: any) => {
  // 提交表单
  const handleFinish = (event: any) => {
    // 登录接口
    login({
      username: event.account,
      password: event.password
    }).then((res: any) => {
      if (res) {
        // 存储token
        saveAccessToken(res.data.tokenKey)
        // 提示
        message.success('登录成功！')
        // 跳转首页
        props.history.push('/deliver')
      }
    })

  }
  if (getAccessToken() && getAccessToken() !== "admin") {
    return <Redirect to="/deliver" />
  }
  return (
    <div className="login">
      <div className="page-login">
        <div className="page-login__title">客户跟踪服务平台</div>
        <Form onFinish={handleFinish}>
          <Form.Item
            label={<div className="form-item__label">账号</div>}
            name="account"
            validateTrigger="onBlur"
            initialValue="德约"
            rules={[{ required: true, message: '账号不能为空' }]}
          >
            <Input placeholder="请输入账号" />
          </Form.Item>

          <Form.Item
            label={<div className="form-item__label">密码</div>}
            name="password"
            validateTrigger="onBlur"
            initialValue="123456llc"
            rules={[
              {
                required: true,
                message: '密码不能为空！'
              },
              {
                pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
                message: '密码为6~20位，且需要包含数字和字母！'
              }
            ]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>

          <Form.Item style={{ marginTop: 20 }}>
            <Button type="primary" htmlType="submit" block={true}>
              登录
            </Button>
          </Form.Item>

        </Form>
      </div>
    </div>
  )
}

export default Login