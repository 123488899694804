import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom';
import DeliveList from './DeliverList/DeliverList';
import DeliveDetails from './DeliverDetails/DeliverDetails';

class Deliver extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/deliver' component={DeliveList} />
          <Route path='/deliver/deliverDetails' component={DeliveDetails} />
        </Switch>
      </div>
    )
  }
}

export default Deliver