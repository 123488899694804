import axios from 'axios';
import {
  message
} from 'antd'
import {
  getAccessToken
} from '@/utils/utils'
// import { Redirect } from 'react-router-dom'


/**
 * 项目管理模块接口
 */
const service = axios.create({
  baseURL: 'http://10.10.10.200:30010/project'
})

// 请求拦截器
service.interceptors.request.use(config => {
  // 每次发送请求之前本地存储中是否存在token，也可以通过Redux这里只演示通过本地拿到token
  // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
  // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
  // const token = window.localStorage.getItem('userToken') || window.sessionStorage.getItem('userToken');
  //在每次的请求中添加token
  // config.data = Object.assign({}, config.data, {
  //   token: token,
  // })
  //设置请求头
  config.headers = {
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    'Token': getAccessToken()
  }
  //序列化请求参数，不然post请求参数后台接收不正常
  // config.data = QS.stringify(config.data)
  return config
}, error => {
  return error;
})

/**
 * 响应拦截器
 */
service.interceptors.response.use(response => {
  //根据返回不同的状态码做不同的事情
  // 这里一定要和后台开发人员协商好统一的错误状态码
  if (response.data.code) {
    switch (response.data.code) {
      case 200:
        return response.data;
      case '200':
        return response.data;
      // case 400:
      //   console.log(response)
      //   message.error(response.data.message)

      //   return <Redirect to='/login'></Redirect>
      default:
        console.log(response)
        if (response.data.message) {
          message.error(response.data.message)
        } else if (response.data.mess) {
          message.error(response.data.mess)
        } else {
          message.error('请求失败！')
        }
        // window.location.hash="/login"
    }
  } else {
    console.log(response)
    return response;
  }
})

/* *************************************************************** */
// 查询合作列表
export const prolist = data => service.post('/get/prolist', data)
// 编辑后使用刷新基本信息
export const pro = data => service.post('/get/pro', data)
// 新增合作
export const addPro = data => service.post('/add/pro', data)
// 编辑合作
export const upPro = data => service.post('/up/pro', data)
// 删除合作
export const delPro = data => service.post('/del/pro', data)
// 编辑项目基本情况
export const upJiben = data => service.post('/up/jiben', data)
// 项目备忘材料列表
export const getBwlist = data => service.post('/get/bwlist', data)
// 用户新建项目提交数据
export const creatproject = data => service.post('/create/creatproject', data)
// 资料共享列表
export const getZllist = data => service.post('/get/zllist', data)
// 资料共享上传
export const addZl = data => service.post('/add/zl', data)
// 删除资料
export const delZl = data => service.post('/del/zl', data)
// 添加备忘材料
export const addBw = data => service.post('/add/bw', data)
// 删除备忘材料
export const delBw = data => service.post('/del/bw', data)
// 添加交付文档
export const addDoc = data => service.post('/add/doc', data)
// 查询交付文档列表
export const getDoclist = data => service.post('/get/doclist', data)
// 删除交付文档
export const delDoc = data => service.post('/del/doc', data)
// 编辑维护信息
export const upWeihu = data => service.post('/up/weihu', data)
// 查询维护信息
export const getWeihu = data => service.post('/get/weihu', data)
// 修改项目阶段
export const upJieduan = data => service.post('/up/jieduan', data)
// 编辑交付产品
export const upChanpin = data => service.post('/up/chanpin', data)
// 查询交付产品
export const getChanpin = data => service.post('/get/chanpin', data)
// 合作列表模糊筛选
export const findProbynamelist = data => service.post('/find/probynamelist', data)
// 项目统计
export const projectStatistica = data => service.get('/statistical?year=' + data.year)
// 查询项目列表
export const projectList = data => service.post('/project/list', data)