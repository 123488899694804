import React, { Component } from 'react'
import {Input, Button, Select, Table,Space,Typography, Modal, ConfigProvider, Pagination, message } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'; //国际化 中文
import './index.css';
import { confirmPay, delBill, getPayList } from '@/request/costapi';
import { projectList } from '@/request/deliverapi';
const { Search } = Input;
const {Title} = Typography;
const { Option } = Select;
interface invoice{
  "payid": string,
  "payname": string,
  "clientid": string,
  "pay": string,
  "clientname": string,
  "description": string,
  "createtime": string,
  "status": string,
  "paystatus": string,
  "types": string
}
interface isState {
  data:any,
  total:number,
  selectProject:any,
  projects:any,
  projectsPage:number,
  projectsPageSize:number,
  projectsIsLoadig:boolean ,
  projectsTotal:number,
  searchContent:string,
  currentPage:number,
  pageSize:number,
  delInvoiceModalVisiable: boolean,
  confInvoiceModalVisiable: boolean,
  currentInvoice:invoice,
  selectPayIds:any,
}

export default class Invoice extends Component<any,isState> {
  //#region 
  constructor(props:any){
    super(props);
    this.state = {
      data: [],
      total: 0,
      selectProject:null,
      searchContent:'',
      currentPage:1,
      pageSize:10,
      delInvoiceModalVisiable: false,
      confInvoiceModalVisiable: false,
      selectPayIds:[],
      projects:[],
      projectsPage:1,
      projectsPageSize:10,
      projectsTotal:0,
      projectsIsLoadig:false,
      currentInvoice:{"payid": "",
      "payname": "",
      "clientid": "",
      "pay": "",
      "clientname": "",
      "description": "",
      "createtime": "",
      "status": "",
      "paystatus": "",
      "types": ""}
    }
  }
  // 获取发票信息
  getInvoiceDatas=(currentPage:any,pageSize:any,selectProject:any,searchContent:any)=>{
    let data = {
      "payname": searchContent,
      "types": '1',
      "limit": pageSize,
      "page": currentPage,
      "projectid": selectProject
    }
    getPayList(data).then(
      response=>{
        if(response.data.code===200){
          this.setState({data:response.data.list,total:response.data.total})
        }else{
          message.error(response.data.code===400?'系统错误':'已经报名');
        }
      }
    )
  }
  // 刷新信息
  refreshDatas = ()=>{
    const {currentPage,pageSize,selectProject,searchContent} = this.state;
    this.getInvoiceDatas(currentPage,pageSize,selectProject,searchContent);
  }
  // 组件挂载完成后的动作
  componentDidMount(){
    const {currentPage,projectsPage,projectsPageSize ,pageSize,selectProject,searchContent} = this.state;
    this.getInvoiceDatas(currentPage,pageSize,selectProject,searchContent);
    this.getProjects(projectsPage,projectsPageSize);
  }
  // 获取项目列表
  getProjects= (currentPage:number,pageSize:number)=>{
    const data = {
      "pageNum":currentPage,
      "pageSize":pageSize,
    }
    projectList(data).then(
      (result:any)=>{
        if(result.code==='200'){
          this.setState(state=>({
            projects:[...state.projects,...result.data],
            projectsTotal:result.total
          }))
        }else{
          message.error('请求异常');
        }
      }
    )
  }
  // 分页加载项目列表
  loadingProjects = (e:any)=>{
    if(e.target.scrollTop + e.target.offsetHeight===e.target.scrollHeight){
      const{projects,projectsPage,projectsPageSize,projectsTotal} = this.state;
      if(projects.length<projectsTotal){
        this.setState({projectsPage:projectsPage+1});
        this.getProjects(projectsPage+1,projectsPageSize);
      }
    }
  }
  //项目列表选择
  selectProjectChange = (selectProject:any)=>{
    this.setState({selectProject})
    const {pageSize,searchContent} = this.state;
    this.getInvoiceDatas(1,pageSize,selectProject,searchContent);
    this.setState({currentPage:1,selectProject})
  }
  // 表格选项改变行为
  rowSelectChange=(selectedRowKeys: React.Key[], selectedRows: any) => {
    this.setState({selectPayIds:selectedRowKeys});
  }
  // 分页器行为
  pageChange = (page: any, pageSize: any) => {
    const {selectProject,searchContent} = this.state;
    this.setState({currentPage:page,pageSize});
    this.getInvoiceDatas(page,pageSize,selectProject,searchContent);
  } 
  // 搜索发票信息
  onSearch = (value:any) => {
    const {pageSize,selectProject} = this.state;
    this.getInvoiceDatas(1,pageSize,selectProject,value);
    this.setState({searchContent:value,currentPage:1});
  } 
  // 弹窗展示
  showModal = (option: string,currentInvoice:any=null) => {
    switch (option) {
      case 'delinvoice':{
        this.setState({currentInvoice,delInvoiceModalVisiable: true});
        break;
      }
      case 'confinvoice':{
        const data = {
          'payids':[currentInvoice.payid]
        }
        confirmPay(data).then(
          response=>{
            if(response.data.code===200){
              message.success('确认完成');
              this.refreshDatas();
            }else{
              message.error('请求错误');
            }
          }
        )
        break;
      }
      default:
        break;
    }
  }
  // 删除账单弹窗控制
  handleDelModal = (option: string) =>{
    if (option === 'dodel') {
      const {currentInvoice} = this.state;
      const data ={
        "payids":[currentInvoice.payid]
      }
      delBill(data).then(
        response=>{
          if(response.data.code===200){
            message.success('删除费用账单成功');
            this.refreshDatas();
          }else{
            message.error('删除失败')
          }
        }
      )
      this.setState({ delInvoiceModalVisiable: false })
    } else {
      this.setState({ delInvoiceModalVisiable: false })
    }
  }
  
  //#endregion
  render(){
    const { data ,total,projects,projectsIsLoadig,delInvoiceModalVisiable,currentPage,currentInvoice} = this.state;
    const columns = [
      { title: <span style={{fontWeight:700}}>费用名</span>, dataIndex: 'payname', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>所属项目</span>, dataIndex: 'projectname', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>价格</span>, dataIndex: 'pay', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>是否打印</span>, dataIndex: 'paystatus', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>说明</span>, dataIndex: 'description', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>操作</span>,
        align: 'center' as 'center', render: (text:any,record:any) => {
          return (<div>
            <Button type="link" onClick={()=>this.showModal('confinvoice',record)}>确认</Button>
            <Button type="link" danger onClick={()=>this.showModal('delinvoice',record)}>删除</Button>
           </div>)
        }
      },
    ]
    return (
      <div className="invoice">
        <div style={{minWidth:910}}>
          <Space direction='vertical' size={15}>
            <Space direction='vertical' size={15} style={{paddingLeft:7}}>
              <Title level={5} style={{marginBottom:0,fontSize:20}} >发票中心</Title>
              <div style={{fontSize:14}}>这里展示所有的账单发票</div>
            </Space>
          </Space>
          <div className="invoice-select-project" style={{marginTop:15}}>
            <Select placeholder="请选择项目" allowClear loading={projectsIsLoadig} onPopupScroll={this.loadingProjects} listHeight={180} onChange={this.selectProjectChange} style={{ width: 150 }}>
             {
                projects.map((item:any)=>{
                  return <Option key={item.projectId} value={item.projectId}>{item.projectName}</Option>
                })
              }
              
            </Select></div>
          
          <div className="invoice-main">
            <div className="invoice-main-top">
              <div className="left">
                <span className="title">发票中心</span>
              </div>
              <div className="right">
                <span className="search">
                  <Search placeholder="搜索费用名" allowClear onSearch={this.onSearch} style={{ width: 192, height: 32, marginTop: 17 ,marginRight: 24}} />
                </span>
              </div>
            </div>

            <div className="invoice-main-content">

              <Table
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                rowKey={record=>record.payid}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </div>

            <div className="invoice-main-pagination">
              <ConfigProvider locale={zhCN}>
                <Pagination pageSizeOptions={['10', '20', '50']} showQuickJumper defaultCurrent={1} current={currentPage} total={total} onChange={this.pageChange} /></ConfigProvider>
            </div>
          </div>
          
          <div className="invoice-confal">
            
            
            <Modal title="删除发票" visible={delInvoiceModalVisiable} centered onOk={() => this.handleDelModal('dodel')} onCancel={() => this.handleDelModal('cancel')}>
                确定要删除名为 {currentInvoice.payname} 发票吗？
            </Modal>
            
          </div>
        </div>

      </div>
    )
  }
}
