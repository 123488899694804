import React, { Component } from 'react'
import { Layout, Menu, message, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RouteComponentProps, withRouter, Switch, Link, Route, Redirect } from 'react-router-dom'
import { logout } from '@/request/accountapi'
import { removeAccessToken, getAccessToken } from '@/utils/utils'
import AppHeader from './AppHeader'
import routes from '@/routes'
import confirm from 'antd/lib/modal/confirm';

interface Props_types extends RouteComponentProps {
}

const { SubMenu } = Menu;
const { Content, Sider } = Layout;
interface isState {
    currentRoutes: any;
    spinning: boolean;
}
class DefaultLayout extends Component<Props_types, isState> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentRoutes: this.props.history.location.pathname.split('/'),
            spinning: false
        }
    }
    componentDidMount() {
        window.addEventListener('hashchange', () => {
            this.setState({ currentRoutes: this.props.history.location.pathname.split('/') })
        })
    }
    componentDidUpdate() {
        window.removeEventListener('hashchange', () => {
            console.log('remove hashchange')
        });
    }
    componentWillUnmount() {
        window.removeEventListener('hashchange', () => {
            console.log('remove hashchange')
        });
    }
    // 退出登录
    logout = () => {
        let that = this
        confirm({
            title: '是否退出登录？',
            okText: '确认',
            cancelText: '取消',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                that.setState({
                    spinning: true
                })
                logout().then((res: any) => {
                    // 提示
                    message.success('退出成功！')
                    that.setState({
                        spinning: false
                    })
                    // 清楚本地token
                    removeAccessToken()
                    // 跳转到登录页
                    that.props.history.push('/login')
                })
            }
        })
    }
    render() {
        const { currentRoutes } = this.state;
        const currentMainPage = currentRoutes[1]
        let currentChildrenPage = currentRoutes[1]
        if (currentRoutes.length > 1) {
            currentChildrenPage = currentRoutes[2]
            switch (currentChildrenPage) {
                case 'bill':
                    break;
                case 'invoice':
                    break;
                case 'manage':
                    break;
                case 'visit':
                    break;
                default:
                    currentChildrenPage = currentRoutes[1];
                    break;
            }
        }
        if (!getAccessToken() || getAccessToken() === "admin") {
            return <Redirect to="/login" />
        }
        return (
            <Spin tip="退出中.." spinning={this.state.spinning}>
                <Layout style={{ height: "100vh", overflow: 'hidden' }}>
                    <AppHeader />
                    <Layout style={{ height: '100%' }}>
                        <Sider width={200} className="site-layout-background">
                            <Menu
                                mode="inline"
                                selectedKeys={[currentChildrenPage]}
                                defaultOpenKeys={[currentMainPage]}
                                style={{ height: '100%', borderRight: 0 }}
                            >
                                <Menu.Item key="deliver"><Link key="deliver" to='/deliver'>项目管理</Link></Menu.Item>
                                <Menu.Item key="workorder"><Link key="workorder" to="/workorder">工单管理</Link></Menu.Item>
                                <SubMenu key="cost" title="费用管理">
                                    <Menu.Item key="bill"><Link key="bill" to="/cost/bill">费用账单</Link></Menu.Item>
                                    <Menu.Item key="invoice"><Link key="invoice" to="/cost/invoice">发票中心</Link></Menu.Item>
                                </SubMenu>
                                <Menu.Item key="devrecord"><Link key="devrecord" to="/devrecord">项目记录管理</Link></Menu.Item>
                                <SubMenu key="customer" title="客户管理">
                                    <Menu.Item key="manage"><Link key="manage" to="/customer/manage">客户管理</Link></Menu.Item>
                                    <Menu.Item key="visit"><Link key="visit" to="/customer/visit">客户拜访</Link></Menu.Item>
                                </SubMenu>
                                <Menu.Item key="users"><Link key="users" to="/users">用户管理</Link></Menu.Item>
                                <Menu.Item key="charts"><Link key="charts" to="/charts">数据统计</Link></Menu.Item>
                                <Menu.Item key="design"><Link key="design" to="/design">个性化设计</Link></Menu.Item>
                                <Menu.Item key="logout" onClick={this.logout}>退出</Menu.Item>
                            </Menu>
                        </Sider>
                        <Layout >
                            <Content style={{ padding: '24px', overflowY: 'auto' }}>
                                <Switch>
                                    <Redirect exact from="/" to="/deliver" />
                                    {
                                        routes.map((item,index)=> {
                                            return (
                                                <Route key={index} path={item.path} component={item.component} />
                                            )
                                        })
                                    }
                                </Switch>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </Spin>
        )
    }
}

export default withRouter(DefaultLayout);