import React from "react";
import { Empty, Row, Col, Typography, Upload, Button, message, Space, Tabs, Descriptions, Breadcrumb, Modal, Form, Input, DatePicker, Radio, Menu, Dropdown, Checkbox } from 'antd';
import { Link } from 'react-router-dom'
import { EllipsisOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import StepContent from '../Components/StepContent/'
import { upJiben, getBwlist, addBw, delBw, pro, delPro } from '@/request/deliverapi'
import moment from 'moment';
import { checkedAndUpload, checkedAndGetProjectUrl } from "@/utils/minio";
const { confirm } = Modal
const { TabPane } = Tabs;
const { TextArea } = Input
const { Text, Title } = Typography

// 合同子项的css组件
const ItemDiv = styled.div`
  width: 300px;
  background-color: F5F5F5;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  box-sizing: border-box;
  margin-right: 30px;
  margin-bottom: 25px;
`
const P1: any = styled.p`
  height: 30px;
  padding: 0 15px;
  background-color: ${(props: any) => props.green ? '#2EB496' : '#1890FF'};
  line-height: 30px;
  color: #FFFFFF;
`
const P2: any = styled.p`
  padding: 0 15px;
  margin-top: ${(props: any) => props.mt10 ? '10px' : '13px'};
  margin-bottom: ${(props: any) => props.mb17 ? '17px' : '0'};
  font-weight: 600;
  .span {
    font-weight: normal;
    color: #818181;
  }
`


// 合同子项
const ContractItem = (props: any) => {
  return (
    <ItemDiv>
      <P1 green={props.green}>文件编号：{props.fid}</P1>
      <P2>文件名称：<span>{props.fname}</span></P2>
      <P2 mt10>文件类型：<span>{props.ftype}</span></P2>
      <P2 mt10>文件生效日期：<span>{props.fbtime ? props.fbtime : '永久生效'}</span></P2>
      <P2 mt10>文件失效日期：<span>{props.fetime ? props.fetime : '无'}</span></P2>
      <P2 mt10 mb17>
        <Row justify='space-between' align='middle'>
          <div>页数：<span className='span'>{props.pages}</span></div>
          {props.btns}
        </Row>
      </P2>
    </ItemDiv>
  )
}

class DeliveDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      showEditModal: false,
      radioStatus: null,
      item: this.props.location.state,
      visible: false,
      contractRadio: 0,
      contractType: '正式合同',
      isUpload: false,
      fileName: '',
      details: {},
      editInitialValues: {},
      status: null,
      starttime: '',
      deliver: '',
      projectfield: '',
      isPermanent: false,
      etag: '',
      startTime: '',
      endTime: '',
      formalContractList: [],
      contractTemplateList: [],
      fileList: []
    }
  }
  contractRef: any = React.createRef()
  editFormRef: any = React.createRef()

  // 生命周期函数 未挂载DOM
  componentWillMount() {
    const { location } = this.props
    let details: any
    if (location.state && location.state.item) {  //判断当前有参数
      details = location.state.item;
      sessionStorage.setItem('data', JSON.stringify(details))  // 存入到sessionStorage中
    } else {
      details = sessionStorage.getItem('data')  // 当state没有参数时，取sessionStorage中的参数
      details = JSON.parse(details)
    }
    this.setState({
      details
    }, () => {
      console.log(details)
    })
  }

  // 获取详情信息的方法
  fnGetPro = () => {
    pro({ projectid: this.state.details.projectid }).then((res: any) => {
      const details = res.project
      sessionStorage.setItem('data', JSON.stringify(details))
      const newDetails: any = sessionStorage.getItem('data')
      this.setState({
        details: JSON.parse(newDetails)
      })
    })
  }

  // 生命周期函数 已挂载DOM
  componentDidMount() {
    // 获取项目备忘材料列表
    this.fnGetBwlist(0, 10, '正式合同')
    this.fnGetBwlist(0, 10, '合同模板')
  }


  // 请求项目备忘材料列表的方法
  fnGetBwlist = (skip: number, limit: number, htype: string) => {
    getBwlist({ skip, limit, htype, projectid: this.state.details.projectid }).then((res: any) => {
      console.log(res)
      if (htype === '正式合同') {
        this.setState({
          formalContractList: res.datas ? res.datas : []
        })
      } else if (htype === '合同模板') {
        this.setState({
          contractTemplateList: res.datas ? res.datas : []
        })
      }
    })
  }

  // 关闭
  closeClick = () => {
    this.props.history.goBack()
  }

  // 编辑按钮
  onEditClick = async () => {
    // 获取状态
    switch (this.state.details.projectstate) {
      case '正在研发':
        await this.setState({ status: 0 })
        break
      case '待交付':
        await this.setState({ status: 1 })
        break
      case '已交付':
        await this.setState({ status: 2 })
        break
    }
    this.setState({
      starttime: '',
      deliver: '',
      editInitialValues: {
        baohan: this.state.details.client,
        content: this.state.details.delivercontent,
        hou: this.state.details.back,
        jianjie: this.state.details.synopsis,
        jiaofu: this.state.details.deliver ? moment(this.state.details.deliver, 'YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD'),
        lingyu: this.state.details.projectfield,
        qian: this.state.details.front,
        startTime: this.state.details.starttime ? moment(this.state.details.starttime, 'YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD'),
        status: this.state.status,
        type: this.state.details.projecttype,
        zhouqi: this.state.details.rdtime
      },
      showEditModal: true,
      projectfield: this.state.details.projectfield
    })
  }

  // 弹框关闭
  onCancelEdit = () => {
    this.setState({
      showEditModal: false
    })
  }
  // 编辑弹框打开
  onOkEdit = () => {
    const item = this.state.details
    this.editFormRef.current.validateFields().then((res: any) => {
      // 通过验证
      // 请求的数据
      const data = {
        projectid: item.projectid,
        starttime: this.state.starttime ? this.state.starttime : this.state.editInitialValues.startTime.format().substring(0, 10),
        deliver: this.state.deliver ? this.state.deliver : this.state.editInitialValues.jiaofu.format().substring(0, 10),
        projectstate: this.state.radioStatus ? this.state.radioStatus : item.projectstate,
        projectfield: this.state.projectfield,
        front: res.qian,
        back: res.hou,
        rdtime: res.zhouqi,
        client: res.baohan,
        projecttype: res.type,
        delivercontent: res.content,
        synopsis: res.jianjie
      }
      // 发送请求
      upJiben(data).then(res => {
        this.setState({
          showEditModal: false
        })
        // 刷新基本信息
        this.fnGetPro()
        // 显示成功提示
        message.success('修改成功!')
      })
    })
  }
  /* -------------------编辑表单方法 Start--------------------- */
  // 项目启动日期更改
  onChangeStartTime = (time: any, timeString: string) => {
    this.setState({
      starttime: timeString
    })
  }
  // 交付时间更改
  onChangeEndTime = (time: any, timeString: string) => {
    this.setState({
      deliver: timeString
    })
  }
  // 项目状态更改
  onChangeStatus = (info: any) => {
    switch (info.target.value) {
      case 0:
        this.setState({
          radioStatus: '正在研发'
        })
        break;
      case 1:
        this.setState({
          radioStatus: '待交付'
        })
        break;
      case 2:
        this.setState({
          radioStatus: '已交付'
        })
        break;
    }
  }
  // 项目领域改变
  onChangeLingyu = (info: any) => {
    this.setState({
      projectfield: info.target.value
    })
  }
  /* -------------------编辑表单方法 End--------------------- */

  /* 上传合同按钮 */
  uploadBtnClick = () => {
    this.setState({
      visible: true,
      contractRadio: 0,
      contractType: '正式合同',
      isPermanent: false,
      isUpload: false,
      fileName: ''
    })
  }
  /* 合同弹窗 确认 */
  onOk = () => {
    const { fileList } = this.state
    // 验证通过
    this.contractRef.current.validateFields().then((res: any) => {

      console.log(res)
      console.log(this.state.fileList)
      // 是否上传了文件
      if (this.state.fileList.length === 0) {
        message.error('请上传文件!')
      } else {
        // 上传到minio
        checkedAndUpload('file', fileList[0], (etag: any) => {
          console.log(etag)
          this.setState({
            etag
          }, () => {
            console.log(this.state.etag)
            // 请求添加接口
            const data = {
              pages: res.pages,
              projectid: this.state.details.projectid,
              htype: this.state.contractType,
              fid: res.no,  // 默认值
              fname: res.name,
              ftype: res.type,
              fbtime: this.state.startTime,
              fetime: this.state.endTime,
              furl: this.state.etag,
              username: 'deyue',  // 默认值
              userid: 'asdfjassdasd'  // 默认值
            }
            console.log(this.state.etag)
            addBw(data).then(res => {
              console.log(res)
              // 刷新备忘材料列表
              this.fnGetBwlist(0, 10, '正式合同')
              this.fnGetBwlist(0, 10, '合同模板')
              message.success('上传成功!')
              this.setState({
                visible: false,
                fileList: []
              })
            })
          })
        })
      }
    })
  }
  /* 合同弹窗 取消 */
  onCancel = () => {
    this.setState({
      visible: false,
      fileList: []
    })
  }
  /* 弹窗 表单 Radio 回调 */
  onChangeContract = (e: any) => {
    this.setState({
      contractRadio: e.target.value
    })
    if (e.target.value === 0) {
      this.setState({ contractType: '正式合同' })
    } else if (e.target.value === 1) {
      this.setState({ contractType: '合同模板' })
    }
  }
  /* 弹窗 表单 文件生效日期 回调 */
  onStartTimeChange = (e: any, str: string) => {
    this.setState({
      isPermanent: false,
      startTime: str
    })
  }
  /* 弹窗 表单 文件生效日期 Checkbox回调 */
  onCheckboxChange = (e: any) => {
    this.setState({
      isPermanent: e.target.checked,
      // 清楚state里的时间
      startTime: '',
      endTime: ''
    })
    // 选择永久清空表单里的时间选择
    this.contractRef.current.setFieldsValue({
      startTime: '',
      endTime: ''
    })
  }
  /* 弹窗 表单 文件失效日期 回调 */
  onEndTimeChange = (e: any, str: string) => {
    this.setState({
      isPermanent: false,
      endTime: str
    })
  }
  // 自定义上传回调
  customRequest = (info: any) => {
    // 如果有文件 上传成功
    if (info.file) {
      // 上传到minio
      checkedAndUpload('file', info, (etag: any) => {
        console.log(etag)
        info.onSuccess()
        message.success(`${info.file.name}文件上传成功`)
        this.setState({
          isUpload: true,
          fileName: info.file.name,
          etag
        })
      });
    } else {
      // 上传失败
      info.onError()
      message.error(`${info.file.name}文件上传失败`);
    }
  }
  // 删除备忘材料按钮
  delBtn = (bwid: string) => {
    let that = this
    confirm({
      title: '是否删除?',
      okText: '确认',
      cancelText: '取消',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delBw({ bwid }).then(res => {
          // 刷新备忘材料列表
          that.fnGetBwlist(0, 10, '正式合同')
          that.fnGetBwlist(0, 10, '合同模板')
        })
      }
    });
  }
  // 删除项目
  delProjectBtn = () => {
    let that = this
    confirm({
      title: '是否删除?',
      okText: '确认',
      cancelText: '取消',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delPro({ projectid: that.state.details.projectid }).then(res => {
          message.success('删除成功!')
          that.props.history.goBack()
        })
      }
    })
  }
  // 提交工单
  submitWorkorder = () => {
    this.props.history.push('/workorder')
  }

  // 上传备忘材料前回调
  beforeUpload = (file: any) => {
    console.log(file)
    this.setState({
      fileList: [file]
    }, () => {
      console.log(this.state.fileList)
    })
    return false
  }
  // 删除上传的备忘材料
  removeFile = (file: any) => {
    this.setState((state: any) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList
      }
    }, () => {
      console.log(this.state.fileList)
    })
  }
  // 备忘材料 下载文件
  download = (fileName: string) => {
    // 获取url
    checkedAndGetProjectUrl(fileName, (url: string) => {
      window.open(url, '_blank')
    })
  }


  render() {
    const menu = (
      <Menu>
        <Menu.Item onClick={this.delProjectBtn} key="0" style={{ color: '#ff0000' }}>
          删除
        </Menu.Item>
        <Menu.Item onClick={this.onEditClick} key="1" style={{ color: '#0099ff' }}>
          编辑
        </Menu.Item>
      </Menu>
    );
    // const props = {
    //   name: 'file',
    //   maxCount: 1,
    //   customRequest: this.customRequest
    // }
    const { details } = this.state
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>项目管理</Breadcrumb.Item>
          <Breadcrumb.Item>项目详情</Breadcrumb.Item>
        </Breadcrumb>
        <Row justify='space-between' style={{ margin: '14px 0' }}>
          <Col>
            <Title level={5} style={{ marginBottom: 0, height: '100%', lineHeight: '32px' }}>{details.projectname}</Title>
          </Col>
          <Col>
            <Space>
              <Button type='primary' onClick={this.closeClick}>关闭</Button>
              <Button onClick={this.submitWorkorder}>提交工单</Button>
              <Button type="primary"><Link to="/cost/bill">费用账单</Link></Button>
              <Dropdown overlay={menu} trigger={['click']} placement='bottomCenter'>
                <Button icon={<EllipsisOutlined />} />
              </Dropdown>
            </Space>
          </Col>
        </Row>

        <Row style={{ fontSize: 14 }}>
          <Col span={6}>
            <Space direction="vertical">
              <div>客户经理：<Text type='secondary'>{details.manager}</Text></div>
              <div>创建时间：<Text type='secondary'>{details.createtime}</Text></div>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical">
              <div>客户公司：<Text type='secondary'>{details.kehucompany}</Text></div>
              <div>初始合同：<Text type='secondary'>{details.initcontract}</Text></div>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical">
              <div>项目编号：<Text style={{ color: '#2e9afe' }}>{details.projectnumber}</Text></div>
              <div>项目备注：<Text type='secondary'>{details.projectremarks}</Text></div>
            </Space>
          </Col>
        </Row>

        <Tabs style={{ marginTop: '10px' }}>
          <TabPane tab="项目详情" key='0'>
            <Descriptions extra={<Button type="primary" onClick={this.onEditClick}>编辑</Button>} style={{ backgroundColor: '#ffffff', padding: '20px 24px 24px', borderRadius: '2px' }} bordered={true} title='基本情况' labelStyle={{ width: '145px' }}>
              <Descriptions.Item label="项目启动日期">{details.starttime}</Descriptions.Item>
              <Descriptions.Item label="项目状态">
                <Space>
                  <span style={{ backgroundColor: details.projectstate === '正在研发' ? '#fa6400' : details.projectstate === '待交付' ? '#f7b500' : '#52c41a', position: 'relative', top: '-2px', width: '7px', height: '7px', borderRadius: '50%', display: 'inline-block' }} />
                  <span>{details.projectstate}</span>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="交付日期">{details.deliver}</Descriptions.Item>
              <Descriptions.Item label="项目领域">{details.projectfield}</Descriptions.Item>
              <Descriptions.Item label="前端技术" span={2}>{details.front}</Descriptions.Item>
              <Descriptions.Item label="项目类型">{details.projecttype}</Descriptions.Item>
              <Descriptions.Item label="后端技术" span={2}>{details.back}</Descriptions.Item>
              <Descriptions.Item label="研发周期">{details.rdtime}</Descriptions.Item>
              <Descriptions.Item label="客户端包含" span={2}>{details.client}</Descriptions.Item>
              <Descriptions.Item label="交付内容" span={3}>{details.delivercontent}</Descriptions.Item>
              <Descriptions.Item label="项目简介" span={3}>{details.synopsis}</Descriptions.Item>
            </Descriptions>
            {/* 编辑弹框 */}
            <Modal
              destroyOnClose={true}
              width='1000px'
              visible={this.state.showEditModal}
              onCancel={this.onCancelEdit}
              onOk={this.onOkEdit}
              title='编辑基本信息'
              cancelText='取消'
              okText='确认'>

              <Form layout='vertical' initialValues={this.state.editInitialValues} ref={this.editFormRef}>
                <Row gutter={60}>
                  <Col span={12}>
                    <Form.Item name="startTime" label='项目启动日期' rules={[{ required: true, message: '请选择项目启动时间!' }]}>
                      <DatePicker style={{ width: '100%' }} placeholder="请选择项目启动时间" onChange={this.onChangeStartTime} />
                    </Form.Item>
                    <Form.Item name="status" label='项目状态' rules={[{ required: true, message: '请选择项目状态!' }]}>
                      <Radio.Group onChange={this.onChangeStatus}>
                        <Radio value={0}>正在工作</Radio>
                        <Radio value={1}>待交付</Radio>
                        <Radio value={2}>已交付</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name="qian" label='前端技术'>
                      <Input placeholder="请输入前端技术" />
                    </Form.Item>
                    <Form.Item name="type" label='项目类型' rules={[{ required: true, message: '请输入项目类型!' }]}>
                      <Input placeholder="请输入项目类型" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="jiaofu" label='交付时间' rules={[{ required: true, message: '请选择交付时间!' }]}>
                      <DatePicker style={{ width: '100%' }} placeholder='请选择交付时间' onChange={this.onChangeEndTime} />
                    </Form.Item>
                    <Form.Item name="lingyu" label='项目领域' rules={[{ required: true, message: '请输入项目领域!' }]}>
                      <Radio.Group onChange={this.onChangeLingyu}>
                        <Radio value={'教育&校园'}>教育&校园</Radio>
                        <Radio value={'交通&物流'}>交通&物流</Radio>
                        <Radio value={'政务&企业'}>政务&企业</Radio>
                        <Radio value={'农医&其他'}>农医&其他</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name="hou" label='后端技术'>
                      <Input placeholder="请输入后端技术" />
                    </Form.Item>
                    <Form.Item name="zhouqi" label='研发周期' rules={[{ required: true, message: '请输入研发周期!' }]}>
                      <Input placeholder="请输入研发周期" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="baohan" label='客户端包含' rules={[{ required: true, message: '请输入内容!' }]}>
                  <Input placeholder="请输入内容" />
                </Form.Item>
                <Row gutter={60}>
                  <Col span={12}>
                    <Form.Item className="textArea" name="content" label='交付内容' rules={[{ required: true, message: '请输入交付内容!' }]}>
                      <TextArea autoSize={{ minRows: 3, maxRows: 6 }} placeholder="请输入交付内容" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="jianjie" label='项目简介'>
                      <TextArea autoSize={{ minRows: 3, maxRows: 6 }} placeholder="请输入项目简介" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

            </Modal>
          </TabPane>
          <TabPane tab="项目备忘材料" key='1'>
            {
              this.state.formalContractList.length === 0 && this.state.contractTemplateList.length === 0 ?
                <div style={{ backgroundColor: '#fff', borderRadius: '2px', padding: '20px 24px 24px' }}>
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 303,
                    }}
                    description={
                      <span>
                        暂无材料，点击按钮上传
                      </span>
                    }
                  >
                    <Button type='primary' onClick={this.uploadBtnClick}>上传</Button>
                  </Empty>
                </div>
                :
                <div style={{ backgroundColor: '#fff', borderRadius: '2px', padding: '20px 24px 0 24px' }}>
                  <Row justify='space-between'><span style={{
                    fontWeight: 900,
                    color: '000000',
                    lineHeight: '24px',
                    marginBottom: '15px',
                    fontSize: '16px'
                  }}>{this.state.formalContractList.length === 0 ? '' : '正式合同'}</span><Button type='primary' onClick={this.uploadBtnClick}>上传</Button>
                  </Row>
                  <Row>
                    {
                      this.state.formalContractList.map((item: any, index: number) => {
                        return (
                          <ContractItem key={index} btns={
                            <Space>
                              <Button size='small' onClick={() => this.download(item.furl)} style={{ backgroundColor: '#1890ff', color: '#fff' }}>下载文件</Button>
                              <Button onClick={() => this.delBtn(item.bwid)} size='small' style={{ border: '1px solid #ff0000', color: '#ff0000', boxSizing: 'border-box' }}>删除文件</Button>
                            </Space>
                          } pages={item.pages} fname={item.fname} ftype={item.ftype} fbtime={item.fbtime} fetime={item.fetime} fid={item.fid} />
                        )
                      })
                    }
                  </Row>
                  {
                    this.state.contractTemplateList.length === 0 ? null :
                      <div style={{
                        fontWeight: 900,
                        color: '000000',
                        lineHeight: '24px',
                        marginBottom: '15px',
                        fontSize: '16px'
                      }}>合同模板</div>
                  }
                  <Row>
                    {
                      this.state.contractTemplateList.map((item: any, index: number) => {
                        return (
                          <ContractItem key={index} green btns={
                            <Space>
                              <Button size='small' onClick={() => this.download(item.furl)} style={{ backgroundColor: '#1890ff', color: '#fff' }}>下载文件</Button>
                              <Button onClick={() => this.delBtn(item.bwid)} size='small' style={{ border: '1px solid #ff0000', color: '#ff0000', boxSizing: 'border-box' }}>删除文件</Button>
                            </Space>
                          } pages={item.pages} fname={item.fname} ftype={item.ftype} fbtime={item.fbtime} fetime={item.fetime} fid={item.fid} />
                        )
                      })
                    }
                  </Row>
                </div>
            }
            {/* 合同上传弹窗 */}
            <Modal destroyOnClose={true} title="合同上传" visible={this.state.visible} onOk={this.onOk} onCancel={this.onCancel} okText="确认" cancelText="取消">
              <Form ref={this.contractRef} layout='vertical'>
                <Form.Item label="合同类型" required>
                  <Radio.Group value={this.state.contractRadio} onChange={this.onChangeContract}>
                    <Radio value={0}>正式合同</Radio>
                    <Radio value={1}>合同模板</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="文件编号" name="no" required rules={[{ required: true, message: '请输入文件编号!' }]}>
                  <Input placeholder="请输入文件编号" />
                </Form.Item>
                <Form.Item label="文件名称" name="name" required rules={[{ required: true, message: '请输入文件名称!' }]}>
                  <Input placeholder="请输入文件名称" />
                </Form.Item>
                <Form.Item label="文件类型" name="type" required rules={[{ required: true, message: '请输入文件类型!' }]}>
                  <Input placeholder="请输入文件类型" />
                </Form.Item>
                <Form.Item label="文件页数" name="pages" required rules={[{ required: true, message: '请输入文件页数!' }]}>
                  <Input placeholder="请输入文件页数" />
                </Form.Item>
                <Form.Item label="文件生效日期" required>
                  <Input.Group compact>
                    <Form.Item style={{ marginRight: 20 }} name={['startTime', 'startTimeDatePicker']} rules={[{ required: !this.state.isPermanent, message: '请输入文件生效日期!' }]}>
                      <DatePicker onChange={this.onStartTimeChange} />
                    </Form.Item>
                    <Form.Item name={['startTime', 'startTimeCheckbox']}>
                      <Checkbox checked={this.state.isPermanent} onChange={this.onCheckboxChange}>永久</Checkbox>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item label="文件失效日期" name='endTime' required rules={[{ required: !this.state.isPermanent, message: '请输入文件失效日期!' }]}>
                  <DatePicker onChange={this.onEndTimeChange} />
                </Form.Item>
                <Form.Item label='上传材料' required>
                  <Upload.Dragger beforeUpload={this.beforeUpload} onRemove={this.removeFile} fileList={this.state.fileList} multiple={false}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">单击或拖动文件到此区域以上传</p>
                  </Upload.Dragger>
                </Form.Item>
              </Form>
            </Modal>
          </TabPane>
        </Tabs>
        <StepContent projectid={this.state.details.projectid} />
      </div>
    )
  }
}

export default DeliveDetails