import React, { Component } from 'react'
import { Route } from 'react-router'

import WorkOrderList from './WorkOrderList'
import WorkOrderDetail from './WorkOrderDetail'

export default class WorkOrder extends Component {
  render() {
    return (
      <div>
        <Route exact path='/workorder' component={WorkOrderList}></Route>
        <Route path='/workorder/detail' component={WorkOrderDetail} ></Route>
      </div>
    )
  }
}
