import React, { Component } from 'react'
import { Form, Input,Radio, InputNumber,Space,Typography, Button, Select, Table, Modal, ConfigProvider, Pagination, FormInstance,Tooltip, message } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'; //国际化 中文
import './index.css';
import { addBill, batchPrint, delBill, getPayList,updatePay } from '@/request/costapi';
import { projectList } from '@/request/deliverapi';
const { Search,TextArea } = Input;
const { Title} = Typography;
const { Option } = Select;
// const userId = '160675131818659840';
interface bill{
  "payid": string,
  "payname": string,
  "projectid": string,
  "pay": string,
  "projectname": string,
  "description": string,
  "createtime": string,
  "status": string,
  "paystatus": string,
  "types": string
}
interface isState {
  dataIsLoading:boolean,
  data:any,
  total:number,
  selectProject:any,
  //项目列表
  projects:any,
  projectsPage:number,
  projectsPageSize:number,
  projectsIsLoadig:boolean ,
  projectsTotal:number,
  searchContent:string,
  currentPage:number,
  pageSize:number,
  isCanPrintBill:boolean,
  printBills:any,
  addBillModalVisiable: boolean,
  delBillModalVisiable: boolean,
  modBillModalVisiable: boolean,
  currentBill:bill,
}

export default class Bill extends Component<any,isState> {
  //#region 
  constructor(props:any){
    super(props);
    this.state = {
      dataIsLoading:false,
      data: [],
      total: 0,
      selectProject:'',
      projects:[],
      projectsPage:1,
      projectsPageSize:5,
      projectsTotal:0,
      projectsIsLoadig:false,
      searchContent:'',
      currentPage:1,
      pageSize:10,
      isCanPrintBill:false,
      printBills:[],
      addBillModalVisiable: false,
      delBillModalVisiable: false,
      modBillModalVisiable: false,
      currentBill:{"payid": "",
      "payname": "",
      "projectid": "",
      "pay": "",
      "projectname": "",
      "description": "",
      "createtime": "",
      "status": "",
      "paystatus": "",
      "types": ""}
    }
  }
  // 获取费用账单信息
  getBillDatas=(currentPage:any,pageSize:any,selectProject:any,searchContent:any)=>{
    let data = {
      "payname": searchContent,
      "types": '0',
      "limit": pageSize,
      "page": currentPage,
      "projectid":selectProject
    }
    getPayList(data).then(
      response=>{
        if(response.data.code===200){
          this.setState({data:response.data.list,total:response.data.total,dataIsLoading:false})
        }else{
          message.error(response.data.code===400?'系统错误':'已经报名');
        }
      }
    )
    
    
  }
  // 刷新费用列表
  refreshDatas=()=>{
    const {currentPage,pageSize,selectProject,searchContent} = this.state;
    this.getBillDatas(currentPage,pageSize,selectProject,searchContent);
  }
  // 获取项目列表 
  getProjects= (currentPage:number,pageSize:number)=>{
    const data = {
      "pageNum":currentPage,
      "pageSize":pageSize,
    }
    projectList(data).then(
      (result:any)=>{
        if(result.code==='200'){
          this.setState(state=>({
            projects:[...state.projects,...result.data],
            projectsTotal:result.total
          }))
        }else{
          message.error('请求异常');
        }
      }
    )
  }
  // 组件挂载完成后的动作
  componentDidMount(){
    const {projects,projectsPage,projectsPageSize,currentPage,pageSize,selectProject,searchContent} = this.state;
    this.getBillDatas(currentPage,pageSize,selectProject,searchContent);
    this.setState({dataIsLoading:true, projects});
    this.getProjects(projectsPage,projectsPageSize);
  }
  // 分页加载项目列表
  loadingProjects = (e:any)=>{
    if(e.target.scrollTop + e.target.offsetHeight===e.target.scrollHeight){
      const{projects,projectsPage,projectsPageSize,projectsTotal} = this.state;
      if(projects.length<projectsTotal){
        this.setState({projectsPage:projectsPage+1});
        this.getProjects(projectsPage+1,projectsPageSize);
      }
    }
  }
  //项目选择改变
  selectProjectChange = (selectProject:any)=>{
    const {pageSize,searchContent} = this.state;
    this.getBillDatas(1,pageSize,selectProject,searchContent);
    this.setState({currentPage:1,selectProject})
  }
  // 表格选项改变行为
  rowSelectChange=(selectedRowKeys: React.Key[], selectedRows: any) => {
    if(selectedRows!==null && selectedRows.length!==0){
      const passDatas = selectedRows.filter((item:any)=>{
        return item.status === '1'
      })
      if(passDatas.length!==null&&passDatas.length===selectedRows.length){
        this.setState({isCanPrintBill:true,printBills:selectedRows})
      }else{
        this.setState({isCanPrintBill:false});
      }
    }else{
      this.setState({isCanPrintBill:false})
    }
  }
  // 分页器行为
  pageChange = (page: any, pageSize: any) => {
    const {selectProject,searchContent} = this.state;
    this.setState({currentPage:page,pageSize});
    this.getBillDatas(page,pageSize,selectProject,searchContent);
  } 
  // 搜索账单信息
  onSearch = (value:any) => {
    const {pageSize,selectProject} = this.state;
    this.setState({searchContent:value,currentPage:1});
    this.getBillDatas(1,pageSize,selectProject,value);
  } 
  // 打印账单信息
  toPrintBill = ()=>{
    const {printBills} = this.state;
    const payids = [];
    for(let i=0;i<printBills.length;i++){
      payids.push(printBills[i].payid)
    }
    const data = {
      payids
    }
    batchPrint(data).then(
      response =>{
        if(response.data.code===200){
          message.success('打印成功');
          this.props.history.push('/cost/invoice')
        }else{
          message.error('打印失败')
        }
      }
    )
  }
  // 弹窗展示
  showModal = (option: string,currentBill:any=null) => {
    switch (option) {
      case 'addbill': {
        this.setState({ addBillModalVisiable: true });
        break;
      }
      case 'delbill':{
        this.setState({currentBill,delBillModalVisiable: true});
        break;
      }
      case 'modbill':{
        this.setState({currentBill,modBillModalVisiable: true});
        break;
      }
      default:
        break;
    }
  }
  // 添加账单引用
  addBillFormRef = React.createRef<FormInstance>();
  // 添加账单form验证后的提交动作
  addBillFormFinish = (values: any)=>{
    const data = {
      "payname": values.payname,
      "projectid": values.clientid.value,
      "projectname": values.clientid.label,
      "pay": ''+values.pay,
      "status": ''+values.status,
      "description": values.description
    }
    addBill(data).then(
      response=>{
        if(response.data.code===200){
          this.refreshDatas();
          message.success('添加费用账单成功');
          this.setState({ addBillModalVisiable: false });
          this.addBillFormRef.current?.resetFields();
        }else{
          message.error('添加费用账单失败！');
        }
      }
    )
  }
  // 添加账单窗口控制
  handleAddModal = (option: string) => {
    if (option === 'dosave') {
      this.addBillFormRef.current?.submit();
    } else {
      this.setState({ addBillModalVisiable: false })
    }
  }
  // 删除账单弹窗控制
  handleDelModal = (option: string) =>{
    if (option === 'dodel') {
      const {currentBill} = this.state;
      const data = {payids:[currentBill.payid]}
      delBill(data).then(
        response =>{
          if(response.data.code===200){
            this.refreshDatas();
            message.success('删除账单成功');
          }else{
            message.error('删除账单失败')
          }
        }
      )
      this.setState({ delBillModalVisiable: false })
    } else {
      this.setState({ delBillModalVisiable: false })
    }
  }
  // 编辑账单form表单引用
  modBillFormRef = React.createRef<FormInstance>();
  // 编辑账单验证后提交动作
  modBillFormFinish = (values: any)=>{
    const {currentBill} = this.state;
    const data = {
      "payid": currentBill.payid,
      "payname": values.payname,
      "projectid": values.client.value,
      "projectname": values.client.label,
      "pay": ''+values.pay,
      "status": ''+values.status,
      "description": values.description
    }
    updatePay(data).then(
      response=>{
        if(response.data.code===200){
          this.refreshDatas();
          message.success('修改费用账单成功');
        }else{
          message.error('添加费用账单失败！');
        }
      }
    )
    this.setState({ modBillModalVisiable: false });
    this.modBillFormRef.current?.resetFields();
  }
  // 编辑账单弹窗控制
  handleModModal = (option: string) => {
    if (option === 'dosave') {
      this.modBillFormRef.current?.submit();
    } else {
      this.setState({ modBillModalVisiable: false })
      this.modBillFormRef.current?.resetFields();
    }
  }
  //#endregion
  render(){
    const { data,total,dataIsLoading,projectsIsLoadig,projects,addBillModalVisiable ,delBillModalVisiable,modBillModalVisiable,
       isCanPrintBill,currentPage,currentBill} = this.state;
    const columns = [
      { title: <span style={{fontWeight:700}}>费用名</span>, dataIndex: 'payname', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>所属项目</span>, dataIndex: 'projectname', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>价格</span>, dataIndex: 'pay', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>结账状态</span>, dataIndex: 'status', align: 'center' as 'center' ,
        render:(text:any)=>{
          return(
            <span>{text==='1'?'是':'否'}</span>
          )
        }},
      { title: <span style={{fontWeight:700}}>说明</span>, dataIndex: 'description', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>操作</span>,
        align: 'center' as 'center', render: (text:any,record:any) => {
          return (<div>
            <Button type="link" onClick={()=>this.showModal('modbill',record)}>编辑</Button>
            <Button type="link" danger onClick={()=>this.showModal('delbill',record)}>删除</Button></div>)
        }
      },
    ]

    return (
      <div className="bill">
        <div style={{minWidth:910}}>
          <Space direction='vertical' size={15}>
            <Space direction='vertical' size={15} style={{paddingLeft:7}}>
              <Title level={5} style={{marginBottom:0,fontSize:20}} >费用账单</Title>
              <div style={{fontSize:14}}>这里展示所有的费用账单</div>
            </Space>
          </Space>
          <div className="bill-select-project" style={{marginTop:15}}>
          <Select placeholder="请选择项目" allowClear loading={projectsIsLoadig} onPopupScroll={this.loadingProjects} listHeight={150} onChange={this.selectProjectChange} style={{ width: 150}}>
             {
                projects.map((item:any)=>{
                  return <Option key={item.projectId} value={item.projectId}>{item.projectName}</Option>
                })
              }
            </Select></div>
          
          <div className="bill-main">
            <div className="bill-main-top">
              <div className="left">
                <span className="title">费用账单</span>
              </div>
              <div className="right">
                <span className="search">
                  <Search placeholder="搜索费用名" allowClear onSearch={this.onSearch} style={{ width: 192, height: 32, marginTop: 17 }} />
                  {/* <Search placeholder="搜索工单" style={{ paddingRight: 20 }} onSearch={this.onSearch} enterButton /> */}
                </span>
                <span>
                  <Button type="primary" onClick={() => this.showModal('addbill')} style={{ width: 88, height: 32, marginLeft: 24, borderRadius: 2 }} >新增账单</Button>
                </span>
                <span>
                  {isCanPrintBill?<Button type="primary" onClick={this.toPrintBill} style={{ width: 88, height: 32, marginRight: 24, marginLeft: 24, borderRadius: 2 }} >打印发票</Button>:
                  <Tooltip placement="top" title={'只有已结账的账单才可打印发票'}>
                    <Button type="primary" disabled style={{ width: 88, height: 32, marginRight: 24, marginLeft: 24, borderRadius: 2 }} >打印发票</Button>
                  </Tooltip>}
                </span>
              </div>
            </div>

            <div className="bill-main-content">

              <Table
                loading={dataIsLoading}
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                rowKey={record=>record.payid}
                rowSelection={{
                  onChange:this.rowSelectChange
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </div>

            <div className="bill-main-pagination">
              <ConfigProvider locale={zhCN}>
                <Pagination pageSizeOptions={['10', '20', '50']} showQuickJumper defaultCurrent={1} current={currentPage} total={total} onChange={this.pageChange} /></ConfigProvider>
            </div>
          </div>
          
          <div className="bill-modal">
            <Modal title="新增账单" visible={addBillModalVisiable} onOk={() => this.handleAddModal('dosave')} onCancel={() => this.handleAddModal('cancel')}>
              <Form name="addForm" onFinish={this.addBillFormFinish} ref={this.addBillFormRef} layout={'vertical'}>
                <Form.Item name="payname" label='费用名'  rules={[{ required: true, message: '请输入费用名!' }]}>
                  <Input placeholder="请输入" style={{}} />
                </Form.Item>
                <Form.Item name="clientid" label='所属项目'  rules={[{ required: true, message: '请选择所属项目!' }]}>
                  <Select labelInValue  placeholder="请选择" onPopupScroll={this.loadingProjects} style={{ width: 250 }} listHeight={150}>
                    {
                      projects.map((item:any)=>{
                        return(<Option key={item.projectId} value={item.projectId}>{item.projectName}</Option>)
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item name="pay" label='价格'  rules={[{ required: true, message: '请输入价格!' }]}>
                  <InputNumber min={0} style={{width:250}} />
                </Form.Item>
                <Form.Item name="status" initialValue={0} label='结账状态'  rules={[{ required: true, message: '请选择结账状态!' }]}>
                  <Radio.Group>
                    <Radio value={0}>否</Radio>
                    <Radio value={1}>是</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name="description" label='说明'  rules={[{ required: true, message: '请输入账单说明!' }]}>
                  <TextArea rows={3}></TextArea>
                </Form.Item>
              </Form>
            </Modal>
            
            <Modal title="删除费用账单" visible={delBillModalVisiable} centered onOk={() => this.handleDelModal('dodel')} onCancel={() => this.handleDelModal('cancel')}>
                确定要删除名为 {currentBill.payname} 费用账单吗？
            </Modal>
            {/* 重新渲染解决不同步初始化 */}
            {modBillModalVisiable&&<Modal title="编辑账单" visible={modBillModalVisiable} onOk={() => this.handleModModal('dosave')} onCancel={() => this.handleModModal('cancel')}>
              <Form name="modForm" onFinish={this.modBillFormFinish} ref={this.modBillFormRef} layout={'vertical'}>
                <Form.Item name="payname" initialValue={currentBill.payname} label='费用名' rules={[{ required: true, message: '请输入费用名!' }]}>
                  <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item name="client" initialValue={{value:currentBill.projectid,key:currentBill.projectid,label:currentBill.projectname}} label='所属项目' rules={[{ required: true, message: '请选择所属公司!' }]}>
                  <Select labelInValue placeholder="请选择" onPopupScroll={this.loadingProjects} style={{ width: 250 }} listHeight={150}>
                    {/* <Option key={currentBill.clientid} value={currentBill.clientid}>{currentBill.clientname}</Option> */}
                    { projects.map((item:any)=>{
                        return <Option key={item.projectId} value={item.projectId}>{item.projectName}</Option>
                    }) }
                  </Select>
                </Form.Item>
                <Form.Item name="pay" initialValue={currentBill.pay} label='价格'  rules={[{ required: true, message: '请输入价格!' }]}>
                  <InputNumber min={0} style={{width:250}} />
                </Form.Item>
                <Form.Item name="status" initialValue={currentBill.status}  label='结账状态'  rules={[{ required: true, message: '请选择结账状态!' }]}>
                  <Radio.Group >
                    <Radio value={'0'}>否</Radio>
                    <Radio value={'1'}>是</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name="description" initialValue={currentBill.description} label='说明' rules={[{ required: true, message: '请输入账单说明!' }]}>
                  <TextArea rows={3}></TextArea>
                </Form.Item>
              </Form>
            </Modal>}
          </div>
        </div>

      </div>
    )
  }
}
