import React, { Component } from 'react'
import { Tabs, Input, Button, Table, Modal,Space,Typography,ConfigProvider, Pagination, FormInstance, message } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'; //国际化 中文
import AddModal from './components/AddModal';
import ModModal from './components/ModModal';
import './index.css';
import moment from 'moment';
import { addCustVisit, delCustVisit, getCustVisitDet, getCustVisits, searchCustVisitList, updCustVisit } from '@/request/customerapi';
const { Search } = Input;
const { Title} = Typography;
const { TabPane} = Tabs;
const userId = '160637059336916992';
const visitId = "161698960921743360"
interface visitRecord{
  visitId:string,
  customerName:string,
  visitDate:string,
  contactPeople:string,
  contactWay:string,
  customerManager:string,
  contactReason:string
}
interface isState {
  data:any,
  total:number,
  searchContent:string,
  currentPage:number,
  pageSize:number,
  addVisitRecordModalVisiable: boolean,
  delVisitRecorModalVisiable: boolean,
  modVisitRecorModalVisiable: boolean,
  currentVisitRecord:visitRecord,
}

export default class Visit extends Component<any,isState> {
  //#region 
  constructor(props:any){
    super(props);
    this.state = {
      data: [],
      total:0,
      currentPage:1,
      searchContent:'',
      pageSize:10,
      addVisitRecordModalVisiable: false,
      delVisitRecorModalVisiable: false,
      modVisitRecorModalVisiable: false,
      currentVisitRecord:{visitId:'string',
        customerName:'string',
        visitDate:'string',
        contactPeople:'string',
        contactWay:'string',
        customerManager:'string',
        contactReason:'string'}
    }
  }
  //标签栏跳转
  tabHandle = (key:any)=> {
    switch (key){
      case 'manage':{
        this.props.history.push('/customer/manage');
        break;
      }
      case 'visit':{
        this.props.history.push('/customer/visit');
        break;
      }
      default:
        break;
    }
  }
  // 获取用户拜访记录
  getVisitRecorDatas=(currentPage:any,pageSize:any,searchContent:any)=>{
    let data = {
      "userId": userId,
      pageSize,
      "pageNum": currentPage
    }
    if(searchContent!==null && searchContent!==''){
      
    }
    getCustVisits(data).then(
      response=>{
        if(response.data.code==='200'){
          this.setState({data:response.data.data,total:response.data.total});
        }else{
          message.error(response.data.message);
        }
      }
    )
  }
  //刷新列表
  refershData=()=>{
    const{currentPage,pageSize,searchContent} = this.state;
    this.getVisitRecorDatas(currentPage,pageSize,searchContent);
  }
  // 组件挂载完成后的动作
  componentDidMount(){
    const {currentPage,pageSize,searchContent} = this.state;
    this.getVisitRecorDatas(currentPage,pageSize,searchContent);
  }

  // 分页器行为
  pageChange = (page: any, pageSize: any) => {
    const {searchContent} = this.state;
    this.setState({currentPage:page,pageSize});
    this.getVisitRecorDatas(page,pageSize,searchContent);
  } 
  // 搜索用户信息
  onSearch = (value:any) => {
    const {pageSize} = this.state;
    if(value!==null&&value!==''){
      let data = {
        "str": value,
        "pageSize": pageSize,
        "pageNum": 1,
        "userId": userId
      }
      searchCustVisitList(data).then(
        response=>{
          if(response.data.code==='200'){
            this.setState({data:response.data.data,searchContent:value,currentPage:1});
          }else{
            message.error(response.data.message);
          }
        }
      )
    }else{
      this.getVisitRecorDatas(1,pageSize,value)
    }
    
  } 
  // 弹窗展示
  showModal = (option: string,currentVisitRecord:any=null) => {
    switch (option) {
      case 'addvisitrecord': {
        this.setState({ addVisitRecordModalVisiable: true });
        break;
      }
      case 'delvisitrecord':{
        this.setState({currentVisitRecord,delVisitRecorModalVisiable: true});
        break;
      }
      case 'modvisitrecord':{
        getCustVisitDet(currentVisitRecord.visitId,userId).then(
          response=>{
            if(response.data.code==='200'){
              console.log(response.data)
              this.setState({currentVisitRecord:response.data.data,modVisitRecorModalVisiable: true});
            }else{
              message.error(response.data.message);
            }
          }
        )
        break;
      }
      default:
        break;
    }
  }
  // 添加表单引用
  addVisitRecordFormRef = React.createRef<FormInstance>();
  // 添加form验证后的提交动作
  addVisitRecordFormFinish = (values: any)=>{
    const data = {
      "userId": userId,
      "customerName": values.customerName,
      "visitDate": values.visitDate.format('yyyy-MM-DD'),
      "contactPeople": values.contactPeople,
      "contactWay": values.contactWay,
      "customerManager": values.customerManager,
      "contactReason": values.contactReason
    }
    console.log(data);
    addCustVisit(data).then(
      response=>{
        if(response.data.code==='200'){
          this.setState({ addVisitRecordModalVisiable: false });
          this.addVisitRecordFormRef.current?.resetFields();
          this.refershData();
          message.success(response.data.message);
        }else{
          message.error(response.data.message);
        }
      }
    )
  }
  
  // 添加窗口控制
  handleAddModal = (option: string) => {
    console.log('add handle')
    if (option === 'dosave') {
      this.addVisitRecordFormRef.current?.submit();
    } else {
      this.setState({ addVisitRecordModalVisiable: false })
      this.addVisitRecordFormRef.current?.resetFields();
    }
  }
  // 删除客户拜访记录弹窗控制
  handleDelModal = (option: string) =>{
    if (option === 'dodel') {
      const {currentVisitRecord} = this.state;
      delCustVisit(currentVisitRecord.visitId,userId).then(
        response=>{
          if(response.data.code==='200'){
            this.setState({ delVisitRecorModalVisiable: false })
            this.refershData();
            message.success(response.data.message);
          }else{
            message.error(response.data.message);
          }
        }
      )
    } else {
      this.setState({ delVisitRecorModalVisiable: false })
    }
  }
  // 编辑客户拜访记录form表单引用
  modVisitRecorFormRef = React.createRef<FormInstance>();
  // 编辑客户拜访记录验证后提交动作
  modVisitRecorFormFinish = (values: any)=>{
    const data = {
      "userId": userId,
      "visitId": visitId,
      "customerName": values.customerName,
      "visitDate": values.visitDate.format('yyyy-MM-DD'),
      "contactPeople": values.contactPeople,
      "contactWay": values.contactWay,
      "customerManager": values.customerManager,
      "contactReason": values.contactReason
    }
    updCustVisit(data).then(
      response=>{
        if(response.data.code==='200'){
          this.setState({ modVisitRecorModalVisiable: false });
          this.modVisitRecorFormRef.current?.resetFields();
          this.refershData();
          message.success(response.data.message);
        }else{
          message.error(response.data.message);
        }
      }
    )
    
  }
  // 编辑客户拜访记录弹窗控制
  handleModModal = (option: string) => {
    if (option === 'dosave') {
      this.modVisitRecorFormRef.current?.submit();
    } else {
      this.setState({ modVisitRecorModalVisiable: false })
      this.modVisitRecorFormRef.current?.resetFields();
    }
  }
  //#endregion
  render(){
    const { data,total, addVisitRecordModalVisiable ,delVisitRecorModalVisiable,modVisitRecorModalVisiable,
       currentPage,currentVisitRecord} = this.state;
    const columns = [
      { title: <span style={{fontWeight:700}}>客户名称</span>, dataIndex: 'customerName', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>客户联系人</span>, dataIndex: 'contactPeople', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>客户经理</span>, dataIndex: 'customerManager', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>联系方式</span>, dataIndex: 'contactWay', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>联系事由</span>, dataIndex: 'contactReason', align: 'center' as 'center' },
      { title: <span style={{fontWeight:700}}>联系日期</span>, dataIndex: 'visitDate', align: 'center' as 'center' ,render:(text:any)=>{
        return(<span>{moment(new Date(text)).format('yyyy年MM月DD日')}</span>)
      }
      },
      {
        title:<span style={{fontWeight:700}}>操作</span>,align: 'center' as 'center', render: (text:any,record:any) => {
          return (<div style={{display:'flex',justifyContent:"center"}}>
            <Button type="link" onClick={()=>this.showModal('modvisitrecord',record)} >编辑</Button>
            <Button type="link" danger onClick={()=>this.showModal('delvisitrecord',record)} >删除</Button>
            </div>)
        }
      },
    ]
    const activeKey = this.props.history.location.pathname.split('/')[2];
    return (
      <div className="visit">
         <Space direction='vertical' size={15}>
          <Space direction='vertical' size={15} style={{paddingLeft:7}}>
            <Title level={5} style={{marginBottom:0,fontSize:20}} >客户拜访记录</Title>
            <div style={{fontSize:14}}>这里展示所有的客户拜访记录</div>
          </Space>
        </Space>
        <div style={{minWidth:910}}>
          <div className="visit-main">
            <div className="visit-main-top">
              <div className="left" style={{width:'100%'}}>
              <Tabs defaultActiveKey={activeKey} onChange={this.tabHandle} tabBarExtraContent={
                <div>
                  <span className="search">
                    <Search placeholder="搜索客户名称" allowClear onSearch={this.onSearch} style={{ width: 192,}} />
                  </span>
                  <span>
                    <Button type="primary" onClick={() => this.showModal('addvisitrecord')} style={{ width: 88, height: 32, marginLeft: 24, borderRadius: 2 }} >新增拜访</Button>
                  </span>
                </div>
              }>
                <TabPane tab={<span style={{fontWeight:700}}>客户管理</span> }  key="manage" >
                </TabPane>
                <TabPane tab={<span style={{fontWeight:700}}>客户拜访</span> }  key="visit" >
                </TabPane>
              </Tabs>
              </div>
            </div>

            <div className="visit-main-content">

              <Table
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                rowKey={record=>record.visitId}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            </div>

            <div className="visit-main-pagination">
              <ConfigProvider locale={zhCN}>
                <Pagination pageSizeOptions={['10', '20', '50']} showQuickJumper defaultCurrent={1} current={currentPage} total={total} onChange={this.pageChange} /></ConfigProvider>
            </div>
          </div>
          
          <div className="visit-modal">
            
            <AddModal
              addVisitRecordModalVisiable={addVisitRecordModalVisiable}
              addModalHandle={this.handleAddModal}
              addVisitRecordFormRef = {this.addVisitRecordFormRef}
              addVisitRecordFormFinish = {this.addVisitRecordFormFinish}
              ></AddModal>

            <Modal title="删除客户拜访记录" visible={delVisitRecorModalVisiable} centered onOk={() => this.handleDelModal('dodel')} onCancel={() => this.handleDelModal('cancel')}>
                确定删除客户 {currentVisitRecord.customerName} 的拜访记录吗？
            </Modal>

            {modVisitRecorModalVisiable&&<ModModal
              modVisitRecorModalVisiable ={modVisitRecorModalVisiable}
              handleModModal={this.handleModModal}
              modVisitRecorFormRef={this.modVisitRecorFormRef}
              modVisitRecorFormFinish={this.modVisitRecorFormFinish}
              currentVisitRecord = {currentVisitRecord}>
              </ModModal>}
          </div>
        </div>

      </div>
    )
  }
}
