import React from "react"
import { Space, Row, Col, Card, Typography, Select } from 'antd'
import { Line, Pie } from '@ant-design/charts'
import { projectStatistica, projectList } from '@/request/deliverapi'
import { getstatistics } from '@/request/costapi'
import './Charts.less'
const { Title, Text } = Typography
const { Option } = Select

class Charts extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      lineData: [],
      pieData: [],
      yearList: [new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2, new Date().getFullYear() - 3],
      year: '',
      pageSize: 10,
      currentPage: 1,
      projectList: []
    }
  }
  async componentDidMount() {
    // 获取项目列表
    await this.getProjectList(this.state.currentPage, this.state.pageSize)
    console.log(this.state.projectList)
    this.fnProjectStatistica()
    // 初始化费用统计
    this.fnGetstatistics(this.state.projectList[0].projectId)
  }

  // 选择项目框回调
  onSelectProChange = (value: string) => {
    this.fnGetstatistics(value)
  }
  // 选择年份框回调
  onSelectYearsChange = (value: string) => {
    this.setState({
      year: value
    }, () => {
      this.fnProjectStatistica()
    })
  }
  // 查询费用统计接口的方法
  fnGetstatistics = (projectid: string) => {
    getstatistics({
      projectid
    }).then(res => {
      this.setState({
        pieData: [
          {
            type: '结账',
            value: res.data.pay.checkout || 0,
          },
          {
            type: '未结账',
            value: res.data.pay.nocheckout || 0,
          }
        ]
      })

    })
  }

  // 查询项目列表的方法
  getProjectList = async (pageNum: number, pageSize: number) => {
    return await projectList({ pageNum, pageSize }).then((res: any) => {
      this.setState({
        total: res.total,
        projectList: this.state.currentPage === 1 ? res.data : this.state.projectList.concat(res.data)
      })
      console.log(this.state.projectList)
    })
  }

  // 项目统计接口方法
  fnProjectStatistica = () => {
    projectStatistica({
      year: this.state.year ? this.state.year : this.state.yearList[0]
    }).then((res: any) => {
      let lineData: object[] = []
      res.month?.forEach((i: any, n: number) => {
        lineData.push({ month: n + 1 + '月', value: i })
      })
      this.setState({
        lineData
      })
    })
  }
  // 费用统计下拉滚动回调
  onSelectPopupScroll = (e: any) => {
    const { target } = e
    // 滚动到了底部加载数据
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const totalPage = Math.ceil(this.state.total / this.state.pageSize)
      this.setState({
        currentPage: this.state.currentPage + 1
      }, () => {
        if (this.state.currentPage <= totalPage) {
          // 请求数据
          this.getProjectList(this.state.currentPage, this.state.pageSize)
        }
      })
    }
  }
  render() {
    const lineConfig = {
      data: this.state.lineData,
      height: 400,
      xField: 'month',
      yField: 'value',
      point: {
        size: 5,
        shape: 'diamond',
      }
    }
    const pieConfig = {
      appendPadding: 10,
      data: this.state.pieData,
      angleField: 'value',
      colorField: 'type',
      radius: 0.8,
      label: {
        type: 'outer',
        content: '{name} {percentage}',
      },
      interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
    }
    return (
      <>
        <Space direction='vertical' size={15} style={{ width: '100%' }}>
          <Space direction='vertical' size={15} style={{ paddingLeft: 7 }}>
            <Title level={5} style={{ marginBottom: 0, fontSize: 20 }}>数据统计</Title>
            <div style={{ fontSize: 14 }}>这里展示数据统计</div>
          </Space>
          {/* 内容 */}
          <div>
            <Row gutter={20}>
              <Col span={12}>
                <Space direction='vertical' style={{ width: '100%' }} size={20}>
                  <Select placeholder="请选择项目" style={{ width: 185 }} onChange={this.onSelectProChange} onPopupScroll={this.onSelectPopupScroll}>
                    {
                      this.state.projectList?.forEach((item: any) => {
                        return <Option key={item.projectId} value={item.projectId}>{item.projectName}</Option>
                      })
                    }
                  </Select>

                  <Card>
                    <Space direction='vertical' size={50} style={{ width: '100%' }}>
                      <div>
                        <Title level={4}>费用统计</Title>
                        <Text type="secondary">单位：元</Text>
                      </div>
                      <Pie {...pieConfig} />
                    </Space>
                  </Card>
                </Space>
              </Col>

              <Col span={12}>
                <Space direction='vertical' style={{ width: '100%' }} size={20}>
                  <Select placeholder="请选择年份" defaultValue={this.state.yearList[0]} style={{ width: 185 }} onChange={this.onSelectYearsChange}>
                    {
                      this.state.yearList?.forEach((item: any) => {
                        return <Option value={item}>{item} 年</Option>
                      })
                    }
                  </Select>

                  <Card>
                    <Space direction='vertical' size={50} style={{ width: '100%' }}>
                      <div>
                        <Title level={4}>项目统计</Title>
                        <Text type="secondary">单位：个</Text>
                      </div>
                      <Line {...lineConfig} />
                    </Space>
                  </Card>
                </Space>
              </Col>
            </Row>
          </div>
        </Space>
      </>
    )
  }
}

export default Charts