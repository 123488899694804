import React from "react";
import { Modal, Form, Space, Button, Input, Upload } from 'antd'

class UploadDocModal extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {}
  }
  render() {
    const { beforeUpload, removeFile, fileList, formRef, visible, onOk, onCancel, type} = this.props
    const message = type === 1 ? '文档名不能为空!' : '资料名不能为空!'
    return (
      <>
        <Modal destroyOnClose={true} title={type === 1 ? '文档上传' : '资料共享上传'} okText='确认' cancelText='取消' visible={visible} onOk={onOk} onCancel={onCancel}>
          <Form ref={formRef} name='form' layout='vertical'>
            <Form.Item label={type === 1 ? '文档名' : '资料名'} name='name' required rules={[
              {
                required: true,
                message: message
              }
            ]}>
              <Input placeholder={type === 1 ? '请输入文档名' : '请输入资料名'} />
            </Form.Item>
            {
              type === 1 ? null : <>
                <Form.Item label="资料类型" name='type' required rules={[
                  {
                    required: true,
                    message: '资料类型不能为空!'
                  }
                ]}>
                  <Input placeholder='请输入资料类型' />
                </Form.Item>
              </>
            }
            <Form.Item>
              <Upload beforeUpload={beforeUpload} onRemove={removeFile} fileList={fileList} multiple={false}>
                <Space size='middle'>
                  <Button type='primary'>上传资料</Button>
                  {/* <Text>您上传的文件名为：<Text>{isUpload ? fileName : '未上传任何文件'}</Text></Text> */}
                </Space>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}

export default UploadDocModal