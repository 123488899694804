import axios from "axios";
import {
  getAccessToken
} from "@/utils/utils";

let service = axios.create({
  baseURL: 'http://10.10.10.200:30010'
})


// 请求拦截器
service.interceptors.request.use(config => {
  // 每次发送请求之前本地存储中是否存在token，也可以通过Redux这里只演示通过本地拿到token
  // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
  // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
  // const token = window.localStorage.getItem('userToken') || window.sessionStorage.getItem('userToken');
  //在每次的请求中添加token
  // config.data = Object.assign({}, config.data, {
  //   token: token,
  // })
  // config.transformRequest = [
  //   function(data) {
  //     return data
  //   }
  // ],
  // config.transformRequest = [
  //     function (data) {
  //       return data
  //     }
  //   ],
  //设置请求头
  config.headers = {
    "Content-Type": "application/json",
    'Token': getAccessToken()
  }
  return config
}, error => {
  return error;
})

/**
 * 研发记录管理api
 */

//获取研发列表
export const getDevRecordList = data => service.post('/research/list', data);
//查询研发记录列表
export const searchDevRecordList = data => service.post('/research/search', data);
//添加研发记录
export const addDevRecord = data => service.post('/research/add', data);
//删除研发记录
export const delDevRecord = researchId => service.delete(`/research/delete/${researchId}`);
//获取研发详情列表
export const getDetDevRecordList = data => service.post('/research/details/list', data);
//新增研发详情
export const addDetDevRecord = data => service.post('/research/details/add', data);