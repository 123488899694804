// 项目交付
import Deliver from '../views/Deliver'
// 工单管理
import WorkOrder from '../views/WorkOrder'
// 费用账单
import Bill from '../views/Cost/Bill'
// 发票中心
import Invoice from '../views/Cost/Invoice'
// 项目记录管理
import DevRecord from '../views/DevRecord/index'
// 客户管理
import Customer from '@/views/Customer'
// 用户管理
import Users from '@/views/Users'
// 数据统计
import Charts from '@/views/Charts'
// 个性化设计
import Design from '@/views/Design'

const routes = [
  { path: '/deliver', component: Deliver },
  { path: '/workorder', component: WorkOrder },
  { path: '/cost/bill', component: Bill },
  { path: '/cost/invoice', component: Invoice },
  { path: '/devrecord', component: DevRecord },
  { path: '/customer/manage', component: Customer},
  { path: '/customer/visit', component: Customer},
  { path: '/users', component: Users },
  { path: '/charts', component: Charts },
  { parh: '/design', component: Design }
]

export default routes