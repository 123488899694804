import { Route, Switch, HashRouter } from "react-router-dom";
import DefaultLayout from '@/containers'
import Login from "@/views/Login";

const Enter = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/Login" component={Login} />
        <Route path="/" component={DefaultLayout} />
      </Switch>
    </HashRouter>
  )
}

export default Enter