import axios from "axios";

const service = axios.create({
  baseURL:'http://10.10.10.200:30010/order'
})

/**
 * 工单管理api
 */
//获取工单列表
export const getOrderList =data => service.post('/getorderlist',data);
//新增工单
export const addOrder = data => service.post('/addorder',data);
//查看工单详情
export const detOrder = data => service.post('/catorder',data);
//删除工单
export const delOrder = data => service.post('/delorder',data);
//关闭工单
export const shutOrder = data => service.post('/shutdownorder',data);
//回复工单
export const replyOrder = data => service.post('/resporder',data);