import Enter from '@/routes/Enter';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { GlobalStyled } from '@/styles/reset-css'
import '@/styles/common.less'


const App = () => (
  <ConfigProvider locale={zhCN}>
      <GlobalStyled />
      <Enter />
  </ConfigProvider>
)

export default App;