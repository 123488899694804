import React, { Component } from 'react'
import { Modal, Row, Col, Input, Form, DatePicker, Select } from 'antd';
import Plate01 from '@/assets/images/plate01.png'
import Plate02 from '@/assets/images/plate02.png'
import Plate03 from '@/assets/images/plate03.png'
import Plate04 from '@/assets/images/plate04.png'
import styled from 'styled-components'
import locale from 'antd/es/date-picker/locale/zh_CN';

const { TextArea } = Input
const { Option } = Select

const PlateItem: any = styled.div`
  padding-left: 16px;
  cursor: pointer;
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
  width: 212px;
  border: ${(props: any) => props.show ? '1px #1890FF solid' : '1px rgba(0, 0, 0, 0.15) solid'};
  .show {
    display: ${(props: any) => props.show ? 'block' : 'none'};
  }
`

// 项目所属板块
const plate = [
  {
    name: '教育 & 校园',
    image: Plate01
  },
  {
    name: '交通 & 物流',
    image: Plate02
  },
  {
    name: '政务 & 企业',
    image: Plate03
  },
  {
    name: '农医 & 其他',
    image: Plate04
  }
]

class AddCooperation extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {}
  }

  render() {
    const { onSelectPopupScroll, custsList, type, visible, initialValues, onCancel, onOk, addFormRef, plateIndex, onPlateItemChange, onChangeStartTime, onChangeInitialTime, onChangeCompany } = this.props;
    // 客户经理自定义验证
    const managerValidator = (rule: any, value: string, callback: any) => {
      let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/  //中文，数字，字母，下划线
      if (!value) {
        callback('请输入客户经理名！')
      } else if (!reg.test(value)) {
        callback('请输入正确的客户经理名！')
      } else if (value.length > 18) {
        callback('请输入正确的客户经理名！')
      } else {
        callback()
      }
    }
    return (
      <>
        <Modal
          destroyOnClose={true}
          title={type === 'add' ? '新增合作' : '编辑合作'}
          centered
          visible={visible}
          onOk={onOk}
          onCancel={onCancel}
          okText={type === 'add' ? '创建' : '确认'}
          cancelText='取消'
          width={1000}
          style={{ padding: '32px 0 32px 48px' }}
        >
          <Form ref={addFormRef} layout='vertical' initialValues={initialValues}>
            <Form.Item label="项目所属板块" name="plate" required>
              <Row justify='space-between'>
                {
                  plate.map((item, index) => {
                    return (
                      <PlateItem show={index === plateIndex ? true : false} key={index} onClick={() => onPlateItemChange(index)}>
                        <img style={{ width: 48, height: 48 }} src={item.image} alt="img" />
                        <div style={{ fontSize: '16px', marginLeft: '17px' }}>{item.name}</div>
                        <Input type="checkbox" className="show" checked style={{ position: 'absolute', right: 7, top: 7, width: '16px', height: '16px', cursor: 'pointer' }} />
                      </PlateItem>
                    )
                  })
                }
              </Row>
            </Form.Item>
            <Row gutter={150}>
              <Col span={12}>
                <Form.Item className="input-h80" name="name" required label="项目名称" extra="名称由中文、英文、下划线、+、-组成。" rules={[{
                  required: true,
                  message: '项目名称不能为空!'
                }]}>
                  <Input value="编辑内容" placeholder="请输入项目名称" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="manager" label="客户经理" rules={[{ validator: managerValidator, required: true }]}>
                  <Input placeholder="请输入客户经理" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={150}>
              <Col span={12}>
                <Form.Item name="startTime" required label="创建时间" rules={[{
                  required: true,
                  message: '创建时间不能为空!'
                }]}>
                  <DatePicker onChange={onChangeStartTime} locale={locale} style={{ width: '100%' }} placeholder="请选择项目创建时间" />
                </Form.Item>
                <Form.Item name="company" required label="客户公司" rules={[{
                  required: true,
                  message: '客户公司不能为空!'
                }]}>
                  <Select placeholder="请选择客户公司" onChange={onChangeCompany} onPopupScroll={onSelectPopupScroll}>
                    {
                      custsList.map((item: any) => {
                        return <Option key={item.customerName} value={item.customerName}>{item.customerName}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item className="textArea" name="brief" label='项目简介' required rules={[{
                  required: true,
                  message: '项目简介不能为空!'
                }]}>
                  <TextArea autoSize={{ minRows: 3, maxRows: 6 }} placeholder="请输入项目简介" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="initial" required label="初始合同" rules={[{
                  required: true,
                  message: '初始合同不能为空!'
                }]}>
                  <DatePicker onChange={onChangeInitialTime} locale={locale} style={{ width: '100%' }} placeholder="请选择时间" />
                </Form.Item>
                <Form.Item name="no" required label="项目编号" rules={[{
                  required: true,
                  message: '项目编号不能为空!'
                }]}>
                  <Input placeholder="请输入项目编号" />
                </Form.Item>
                <Form.Item name="remarks" label='项目备注'>
                  <TextArea autoSize={{ minRows: 3, maxRows: 6 }} placeholder="请输入项目备注" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    )
  }
}

export default AddCooperation