import './AppHeader.less'
import { Layout } from 'antd'

const { Header } = Layout

const AppHeader = () => {
  return (
    <Header className="header" >
      <div className="logo">数信智能客户跟踪服务平台</div>
      <div className="user">
        <div className="user_name">南阳理工软件学院</div>
        <div className="user_why">?</div>
        <div className="user_language">中文</div>
      </div>
    </Header>
  )
}

export default AppHeader